import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";

import VendorComponent from '../component/VendorComponent';
import { getVendorList, getCategoryList } from '../../core/actions/action';
import { vendorListAction } from '../../core/actions';

const mapStateToProps = state => {

    return{
      locationData:JSON.parse(localStorage.getItem('locationData')),
      vendorList:state.Home.vendorList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
      getVendorList:(limit,lat,lng,radius,ggCoin,category,filter) => {
        // dispatch(vendorListAction(null));
          return getVendorList(limit,lat,lng,radius,ggCoin,category,filter)
          .then((res)=>{
              const sources = res.data;
              if(sources.status===true){
                  dispatch(vendorListAction(sources.data));
                }else{
                  dispatch(vendorListAction(null));
                  Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                }
          }).catch((error)=>{
              const sources = error.response;
              if(sources===undefined){
                Toaster.create({position: Position.TOP}).show({message:'Something went wrong!',intent: Intent.DANGER});
              }
              else if(sources.status!==401){
                sources.data.error.map((res)=>{
                  Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                  return res;
                })
              }else{
                Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
              }
          })
      },

      getCategoryList:(name) => {
          return getCategoryList(name)
      }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorComponent);
