import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";

import BillHistoryComponent from '../component/BillHistoryComponent';
import { getBillList, getTrashBillList, getTrashBill, getRestoreBill, getDeleteBill } from '../../core/actions/action';
import { uploadBillListAction,trashBillListAction } from '../../core/actions';

const mapStateToProps = state => {
    return{
        uploadedBills:state.BillHistory.uploadedBills,
        trashBillList:state.BillHistory.trashBillList
    }
}

const mapDispatchToProps = dispatch => {

    return {

        getBillList:(limit)=>{
          // dispatch(uploadBillListAction(null))
              getBillList(limit)
              .then((res)=>{
                  const sources = res.data;
                  if(sources.status===true){
                      dispatch(uploadBillListAction(sources.data))
                  }else{
                    dispatch(uploadBillListAction(null))
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                  }
                }).catch((error)=>{
                    const sources = error.response;
                    if(sources===undefined){
                      Toaster.create({position: Position.TOP}).show({message:'Something went wrong!',intent: Intent.DANGER});
                    }
                    else if(sources.status!==401){
                      sources.data.error.map((res)=>{
                        Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                        return res;
                      })
                    }else{
                      Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                    }
                });
        },
        getTrashBillList:(limit)=>{
          // dispatch(trashBillListAction(null))
              getTrashBillList(limit)
              .then((res)=>{
                  const sources = res.data;
                  if(sources.status===true){
                      dispatch(trashBillListAction(sources.data))
                  }else{
                    dispatch(trashBillListAction(null))
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                  }
                }).catch((error)=>{
                    const sources = error.response;
                    if(sources===undefined){
                      Toaster.create({position: Position.TOP}).show({message:'Something went wrong!',intent: Intent.DANGER});
                    }
                    else if(sources.status!==401){
                      sources.data.error.map((res)=>{
                        Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                        return res;
                      })
                    }else{
                      Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                    }
                });
        },
        getTrashBill:(bill_id) => {
            return getTrashBill(bill_id)
            .then((res)=>{
                const sources = res.data;

                if(sources.status===true){
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                    setTimeout(()=>{
                      window.location.reload();
                    },1000)
                  }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                  }
            }).catch((error)=>{
                const sources = error.response;
                if(sources===undefined){
                  Toaster.create({position: Position.TOP}).show({message:'Something went wrong!',intent: Intent.DANGER});
                }else if(sources.status!==401){
                  sources.data.error.map((res)=>{
                    Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                    return res;
                  })
                }else{
                  Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                }
            })
        },

        getRestoreBill:(bill_id) => {
            return getRestoreBill(bill_id)
            .then((res)=>{
                const sources = res.data;

                if(sources.status===true){
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                    setTimeout(()=>{
                      window.location.reload();
                    },1000)
                  }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                  }
            }).catch((error)=>{
                const sources = error.response;
                if(sources===undefined){
                  Toaster.create({position: Position.TOP}).show({message:'Something went wrong!',intent: Intent.DANGER});
                }
                else if(sources.status!==401){
                  sources.data.error.map((res)=>{
                    Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                    return res;
                  })
                }else{
                  Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                }
            })
        },

        getDeleteBill:(bill_id) => {
            return getDeleteBill(bill_id)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                    setTimeout(()=>{
                      window.location.reload();
                    },1000)
                  }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                  }
            }).catch((error)=>{
                const sources = error.response;

                if(sources===undefined){
                  Toaster.create({position: Position.TOP}).show({message:'Something went wrong!',intent: Intent.DANGER});
                }
                else if(sources.status!==401){
                  sources.data.error.map((res)=>{
                    Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                    return res;
                  })
                }else{
                  Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                }
            })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillHistoryComponent);
