let initialState = {
    wishlist:[]

};

const wishlist = (state = initialState, action) => {

    switch (action.type) {

        case 'WISH_LIST':
            return {...state, wishlist: action.sources}
        default:
            return state
    }
}

export default wishlist;
