let initialState = {
    vendorList:null,
    productList:null,
    serviceList:null,
    categoryList:[],
    locationData:JSON.parse(localStorage.getItem('locationData')),

};

const home = (state = initialState, action) => {

    switch (action.type) {

        case 'VENDOR_LIST':
            return {...state, vendorList: action.sources}
        case 'PRODUCT_LIST':
            return {...state, productList: action.sources}
        case 'SERVICE_LIST':
            return {...state, serviceList: action.sources}
        case 'LOCATION_DATA':
            return {...state, locationData: action.sources}
        case 'CATEGORY_DATA':
            return {...state, categoryList: action.sources}
        default:
            return state
    }
}

export default home;
