import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import {Auth,Helper} from 'gg-react-utilities';
import Favorite from "../../core/containers/productWishlistContainer";
import NoProduct from "../../core/components/emptyStates/noProduct";

export default class ProductList extends Component{

    constructor(props){
        super(props);

        this.state={

        }

    }

    render(){

        return(
          <div className='product-list row'>
            {this.props.productList.length===0?
              <div className='col-xl-12 col-12 mt-4'>
                <NoProduct/>
              </div>
              :
              this.props.productList.map((product)=>{
              let spclPriceFrom = new Date(product.special_price_from).getTime();
              let currentTime= new Date().getTime();
              let spclPriceTo= new Date(product.special_price_to).getTime();
              return(
                <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2' key={product.product_id} >
                  <div className="product-card">
                    <div className="product-img">
                      <Link to={'/product/'+product.slug}>
                        {product.images.length===0 ?
                          <img src={process.env.REACT_APP_DEFAULT_IMAGE} alt="5 Terre" className="img-fluid"/>
                          :
                          <img src={product.images[0].image} alt="5 Terre" className="img-fluid"
                            onError={(ev)=>{
                              ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                            }}/>
                        }
                      </Link>
                      </div>
                      <div className="product-sticker">
                        {product.gg_coin_accept === 1 ?
                          <img src={process.env.REACT_APP_GG_COIN_IMAGE} className='gg-coin-img' alt='gg coin'/>
                        :null}
                        <div className='icon-area'>
                        {!Auth.isLoggedIn() ?
                          <Favorite product_id={product.product_id} show={false}/>
                          :
                          product.wishlist!==undefined?
                          product.wishlist.length!==0 ?
                          <Favorite product_id={product.product_id} show={product.wishlist[0].in_wishlist===1?true:false}/>
                          :
                          <Favorite product_id={product.product_id} show={false}/>
                          :
                          <Favorite product_id={product.product_id} show={false}/>
                        }
                        </div>
                      </div>
                      <div className="products">
                        <div className='row'>
                          <div className="col-xl-12 col-12">
                            <Link to={'/product/'+product.slug}>
                              <h3 className="product-name">{product.name}</h3>
                            </Link>
                          </div>
                          <div className="col-xl-12 col-lg-12 col-xl-12 col-12 d-flex">
                            <span className="review mr-2">{product.avg_rating}</span>
                            <StarRatings
                              rating={product.avg_rating===null?0:product.avg_rating}
                              starRatedColor="#F5A623"
                              starDimension='10px'
                              numberOfStars={5}
                              name='rating'
                            />
                          </div>
                          </div>
                          <div className="row no-gutters tex-left">
                            <div className='col-xl-6 col-lg-6 col-sm-6 col-md-6 col-6 mt-2'>
                              {product.special_price!==null && Number(product.special_price)!==0 &&product.special_price_from!==null&&product.special_price_to!==null?
                                (spclPriceFrom <= currentTime)&&  (currentTime <= spclPriceTo)?
                                <p className="product-price">{Helper.formatMoney(product.special_price)}</p>
                                :
                                <p className="product-price">{Helper.formatMoney(product.price)}</p>
                                :
                                <p className="product-price">{Helper.formatMoney(product.price)}</p>
                              }
                            </div>
                            <div className='col-xl-6 col-lg-6 col-sm-6 col-md-6 col-6 mt-2'>
                              {product.special_price!==null && Number(product.special_price)!==0 &&product.special_price_from!==null&&product.special_price_to!==null?
                                (spclPriceFrom <= currentTime)&&  (currentTime <= spclPriceTo)?
                                <p className="product-price text-right text-muted"><strike>{Helper.formatMoney(product.price)}</strike></p>
                                :null
                                :null}
                              </div>

                        </div>
                      </div>
                    </div>
                </div>
              );
            })}
          </div>
        );

    }

}
