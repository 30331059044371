import React,{ Component } from 'react';
import FoodContainer from './container/FoodContainer';
import {Error} from 'gg-react-utilities';

export default class Food extends Component{

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1000)
    }

    render(){
      try {
        return(
          <FoodContainer
            back={this.props.history}
          />
        )
      } catch (e) {
        return(
          <Error />
        )
      }
    }
}
