import React, {Component, Fragment} from 'react';
import { Button, Card,Dialog, Classes} from "@blueprintjs/core";
import {Link} from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import _ from 'lodash';
import { PageTitle, Loader, Helper } from 'gg-react-utilities';
import { Async } from 'react-select';
import InputRange from 'react-input-range';
import NoService from "../../core/components/emptyStates/noService";
import NoLocation from '../../core/components/emptyStates/noLocation';
import TopBar from "../../core/components/topBar";
import Loading from "./loading";

export default class ServiceComponent extends Component {

    constructor(props){
        super(props);
        this.state={
          limit:9,
          value:{
            min: 0,
            max: 20,
          },
          selectedOption:null,
          isEnabled:false,
          category:[],
          filterCategory:[],
          filter:'',
          isOpen:false,
          terms:''
        }
        this.fetchMoreData = this.fetchMoreData.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount(){
      if(this.props.locationData!==null){
        this.props.getServiceList(this.state.limit,this.props.locationData.lat,this.props.locationData.lng,this.state.value.min+'-'+this.state.value.max,this.state.filterCategory,this.state.filter);
      }
    }

    componentWillReceiveProps(nextProps){

    }

    handleClose=()=>{
      this.setState({
        isOpen: false
      })
    }

    getCategory = (input, callback) => {
        if (input.length <= 2) {
            callback(null, {
                options: [],
                complete: true
            });
            return Promise.resolve({ options: [] });
        }

        this.props.getCategoryList(input).then((res) => {

          const sources = res.data;
          if (sources.status === true) {
            return sources.data;
          }else{
            alert(sources.message)
          }

          callback(null, {
              options: [],
              complete: true
          });
          return Promise.resolve({ options: [] });

        }).then((json) => {
          callback(null, {
              options: json,
              complete: true
          })
        }).catch((error) => {
            callback(null, {
                options: [],
                complete: true
            });
            return Promise.resolve({ options: [] });
        });
    }

    filterData(){
      let category = [];
      this.state.category.map((res)=>{
        category.push(res.category_id);
        return res;
      })

      this.setState({filterCategory:category},()=>{

        this.props.getServiceList(this.state.limit,this.props.locationData.lat,this.props.locationData.lng,this.state.value.min+'-'+this.state.value.max,this.state.filterCategory,this.state.filter);
      })

    }

    resetFilter(){
      let obj = {
        min:0,
        max:20
      }
      this.setState({filterCategory:[],category:[],value:obj},()=>{
        this.props.getServiceList(this.state.limit,this.props.locationData.lat,this.props.locationData.lng,this.state.value.min+'-'+this.state.value.max,this.state.filterCategory,this.state.filter);
      })
    }

    fetchMoreData(){
      if(this.props.serviceList!==null){
        if(this.props.serviceList.totalRecords>=this.props.serviceList.data.length+1){
          this.setState({limit:this.state.limit+9},()=>{
            this.props.getServiceList(this.state.limit,this.props.locationData.lat,this.props.locationData.lng,this.state.value.min+'-'+this.state.value.max,this.state.filterCategory,this.state.filter);
          });
        }
      }
    }

    discount(spclPrice,price){
      let discount = 0;
      discount = (100-(spclPrice*100)/price);
      return parseInt(discount)
    }


    render() {
      let metaData = document.getElementsByTagName("meta");
      metaData['description'].content = 'Global Garner -Search out near by service of your area at global garner & avail these service via global garner & get great cashback deals';
      metaData['keywords'].content = 'service, Local service, online services cashback';
      let category = _.debounce((input, callback) => { this.getCategory(input, callback) }, 100);
      const {serviceList} = this.props;
        return (
            <Fragment>
              <div className="container">
                  <TopBar title={'Nearby Services'} backClick={this.props.back}
                    breadcrumbs={[
                      {label:'Services',to:'/service'},
                    ]}/>
                  <PageTitle title={'Service | Search near by service | cashback | Global Garner'} />

                  {this.props.locationData===null?
                    <div className='mb-5 mt-3'>
                      <NoLocation/>
                    </div>

                    :
                    <div className="row mb-5">
        						<div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
        							<div className="service-sidebar">
        								<div className="rounded-0 border p-3">

        									<div className="row">
        										<div className="col-xl-12">
        											<h5 className="text-title">Radius</h5>
        										</div>
        										<div className="col-xl-12 px-4">
        											<br/>
        											<InputRange
        											minValue={0}
        											maxValue={100}
        											formatLabel={value => `${value} km`}
        											value={this.state.value}
        											onChange={value => this.setState({ value: value })}
        												/>
        											<br/>
        											<br/>
        										</div>
        									</div>
        									<hr/>
        									<div className="row">
        										<div className="col-xl-9">
        											<h5 className="text-title">Category</h5>
        										</div>
        										<div className="col-xl-12">
        											<Async
        												clearable={false}
        												multi={true}
        												value={this.state.category}
        												isLoading={true}
        												onChange={(value) => {
                                  this.setState({
                                    category: value
                                  })


        												}}
                                valueKey="category_id"
                                labelKey="name"
                                Key="category_id"
        												loadOptions={(input, callback) => category(input, callback)}
        												placeholder={'Select category'}
        												searchPromptText={"Please enter 3 or More characters"}
        												backspaceRemoves={true}
        											/>
        											<br/>
        											<br/>
                              <Button className='bp3-button bp3-secondary bp3-small pull-left' text='Reset Filter'
                                  onClick={()=>{
                                    let obj = {
                                      min:0,
                                      max:10
                                    }
                                    this.setState({isEnabled:'',filterCategory:[],value:obj})
                                  }}/>
        											<Button className='bp3-button bp3-intent-primary bp3-small pull-right' text='Apply Filter' onClick={()=>{
        											this.filterData()
        											}}/>
        											<br/>
        											<br/>
        											<br/>
        											<br/>
        											<br/>
        											<br/>
        											<br/>
        											<br/>
        										</div>
        									</div>
        								</div>
        							</div>
        						</div>
        						<div className="col-xl-9 col-lg-9 col-md-8 col-sm-8 col-12 mt-s-4 scroll-container">
        							<div className="bp3-input-group">
                        <input type="text" className="bp3-input" placeholder="Search service" value={this.state.filter}
                          onChange={(e)=>{
                            this.setState({filter:e.target.value},()=>{
                                if(this.state.filter.trim()!==''){
                                  this.props.getServiceList(this.state.limit,this.props.locationData.lat,this.props.locationData.lng,this.state.value.min+'-'+this.state.value.max,this.state.filterCategory,this.state.filter);
                                }
                            })
                          }}
                        />

        							</div>
        							{serviceList===null?
                        <Loading />
                        :serviceList.data.length===0?
          							<Card className='bp3-elevation-1 col-xl-12 col-12 mt-4'>
          								<NoService/>
          							</Card>
          							:
                      <InfiniteScroll
                        dataLength={serviceList.data.length}
                        next={this.fetchMoreData}
                        hasMore={true}
                        loader={serviceList.totalRecords>=serviceList.data.length+1 && serviceList.filteredRecords>=serviceList.data.length+1?<div className='mt-1'><Loader/></div>:null}
                        >
                          <Dialog isOpen={this.state.isOpen}
                             className="bp3-dialog" title={'Terms and conditions'}
                             onClose={this.handleClose}
                             >
                              <div className={Classes.DIALOG_BODY}>
                                 <div className="col-12 mb-5">
                                    {this.state.terms}
                                 </div>
                                 <div className="row">
                                    <div className="col-6"/>
                                     <div className="col-md-6 col-12 pull-right p-s-0 text-right px-0">
                                       <Button
                                           onClick={this.handleClose}
                                           className="bp3-button bp3-intent-danger bp3-button bp3-small px-4 rounded"
                                           text="Close"
                                       />
                                   </div>
                               </div>
                           </div>
                          </Dialog>

                        <div className="service-list row">
        								{serviceList.data.map((service) => {
                          let spclPriceFrom = new Date(service.special_price_from).getTime();
                          let currentTime= new Date().getTime();
                          let spclPriceTo= new Date(service.special_price_to).getTime();
          								return(
          									<div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 pt-1' key={service.id}>
          										<div className="polaroid">
          											<Link to={'/service/'+service.slug}>
          												<img src={process.env.REACT_APP_SERVICE_IMAGE} alt="5 Terre" className="img-fluid" />
          											</Link>
          											<div className="topleft">
          												<div className='' onClick={()=>{this.setState({isOpen:true,terms:service.terms_and_conditions})}}>
          													<img src={process.env.REACT_APP_INFO_IMAGE} className='gg-coin-img' alt='gg-coin'/>
          												</div>
          											</div>
          											<div className="container text-left">
          												<Link to={'/service/'+service.slug}>
          													<p className="text-title">{service.title}</p>
          												</Link>
          												<p className="service-desc">{service.description}</p>

                                <div className='row no-gutters d-flex align-items-center'>
    															<div className='col-xl-6 col-lg-6 col-sm-6 col-md-6 col-6 mt-2'>
    															  {service.special_price!==null && Number(service.special_price)!==0 &&service.special_price_from!==null&&service.special_price_to!==null?
    																(spclPriceFrom <= currentTime)&&  (currentTime <= spclPriceTo)?
    																<p className="text-primary mb-0 text-nowrap bold">{Helper.formatMoney(service.special_price)}</p>
    																:
    																<p className="text-primary mb-0 text-nowrap bold"> {Helper.formatMoney(service.price)}</p>
    																:
    																<p className="text-primary mb-0 text-nowrap bold"> {Helper.formatMoney(service.price)}</p>
    															  }
    															</div>
    															<div className='col-xl-6 col-lg-6 col-sm-6 col-md-6 col-6 mt-2'>
    															  {service.special_price!==null && Number(service.special_price)!==0 &&service.special_price_from!==null&&service.special_price_to!==null?
    																(spclPriceFrom <= currentTime)&&  (currentTime <= spclPriceTo)?
    																<p className="text-muted mb-0 bp3-text-small text-nowrap text-right"><strike> {Helper.formatMoney(service.price)}</strike></p>
    																:null
    															  :null}
    															</div>

    														  </div>
          											</div>
          										</div>
          									</div>
          								);
          								})}
            							</div>
                        </InfiniteScroll>
          							}
          						</div>
                    </div>
                  }
                  <br/>
                  <br/>
                  <br/>
                  <br/>
              </div>
            </Fragment>
          )
        }
    }
