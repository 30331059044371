import React from 'react';
import {PageTitle} from 'gg-react-utilities';
import {Dialog} from "@blueprintjs/core";
import TopBar from "../core/components/topBar";
import {Link} from "react-router-dom";
import Config from '../Config';

export default class index extends React.Component {



    componentDidMount(){
      setTimeout(()=>{
        window.location.href = Config.DASHBOARD_URL+'orders';
      },1000)
    }

    render() {
        let status = this.props.match.params.status;

        let query = new URLSearchParams(this.props.location.search ? this.props.location.search : "");
        let orderId = query.get('msg');


        let title = "";
        let message = "";
        let link = "";
        let image = "";

        if (status === "success") {
            title = "Your "+orderId;
            message = "Order has been placed successfully and an invoice has been emailed to the customer.";
            link = <Link to="/order-history" className="pb-4"><b>VIEW ORDERS</b></Link>;
            image = require('./images/success.svg');
        } else if (status === "failure") {
            title = "Your "+orderId;
            message = "You might receive a message from your bank that the payment could not be processed.";
            link = <Link to="/=" className="pb-4"><b>GO TO HOME</b></Link>;
            image = require('./images/cancel.svg');
        }

        return (
            <div className='container'>
              <TopBar title={"Payment"} backClick={this.props.back}
                breadcrumbs={[
                  {label:'Payment',to:''},
                ]}/>
              <PageTitle title={'Payment | Global Garner'} />
                    <div className={"mh-100"}>
                        <Dialog
                            isOpen={true}
                            lazy={false}
                            className="bp3-dialog-large payment-status"
                            canOutsideClickClose={false}
                            canEscapeKeyClose={true}
                        >
                            <div className="mg-brand-payments">
                                <div className="">
                                    <div className="">
                                        <div className="">
                                            <div className="paymentWrapper">
                                                <div className="upperPaymentWrapper"></div>
                                                <div className="middlePaymentWrapper">
                                                    <div className="circleWrapper">
                                                        <div className="iconWrapper">
                                                            <img src={image} alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-8 lowerPaymentWrapper m-auto">
                                                    <h3>{title}</h3>
                                                    <p>{message}</p>
                                                    {link}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </div>
            </div>
        );
    }
}
