import React, {Component} from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import configureStore from './core/store/configureStore';
import { BrowserRouter as Router } from 'react-router-dom';
import Root from './core';

let { store, persistor } = configureStore();

export default class App extends Component{

    render(){

        return(
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Router>
                        <Root />
                    </Router>
                </PersistGate>
            </Provider>
        )

    }

}
