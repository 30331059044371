import React, {Component} from 'react';
import {Classes, Dialog, Button} from "@blueprintjs/core";

export default class ServiceTerms extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isOpen: props.isOpen,
        }
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {

    }

    handleClose=()=>{
      this.setState({
        isOpen: false
      })
    }

    componentWillReceiveProps(nextprops){
      if(nextprops.isOpen!==undefined){
          this.setState({
              isOpen: nextprops.isOpen
          });
      }
    }


    render() {
        return (
          <Dialog isOpen={this.state.isOpen}
             className="bp3-dialog" title={this.props.title}
             onClose={this.handleClose}
             canOutsideClickClose={false}
             canEscapeKeyClose={true}
             >
              <div className={Classes.DIALOG_BODY}>
                 <div className="col-12 mb-5">
                    {this.props.terms}
                 </div>
                 <div className="row">
                    <div className="col-6"/>
                     <div className="col-md-6 col-12 pull-right p-s-0 text-right px-0">
                       <Button
                           onClick={this.handleClose}
                           className="bp3-button bp3-intent-danger bp3-button bp3-small px-4 rounded"
                           text="Close"
                       />
                   </div>
               </div>
           </div>
          </Dialog>
        )
      }
}
