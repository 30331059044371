import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";

import CheckoutComponent from '../component/CheckoutComponent';
import {
    getCartList,
    getUserAddress,
    getPincodeList,
    postAddressDetails,
    getShippingAddress, getPlaceOrder, getPaymentMethod
} from '../../core/actions/action';
import {cartListAction, userAddressDetails, postAddressDetailsAction, shippingAddressAction} from '../../core/actions';

const mapStateToProps = state => {
    return{
        cartList:state.Cart.cartList,
        userAddress:state.userInfo.userAddress,
        address:state.Cart.address,
        shippingAddress:state.Cart.shippingAddress,
        paymentMethodAction:state.Cart.paymentMethodAction,
    }
}

const mapDispatchToProps = dispatch => {
    return {
      getCartList:() => {
        dispatch(cartListAction(null));
          return getCartList()
          .then((res)=>{
              const sources = res.data;
              if(sources.status===true){
                    dispatch(cartListAction(sources.data));
                    // dispatch(cartListAction(null));
                }else{
                  dispatch(cartListAction(null));
                  Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                }
          }).catch((error)=>{
              const sources = error.response;
              if(sources===undefined){
                Toaster.create({position: Position.TOP}).show({message:'Something went wrong!',intent: Intent.DANGER});
              } else if(sources.status!==401){
                sources.data.error.map((res)=>{
                  Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                  return res;
                })
              }else{
                Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
              }
          })
      },

      getUserAddress:() => {
        dispatch(userAddressDetails([]));
          return getUserAddress()
          .then((res)=>{
              const sources = res.data;
              if(sources.status===true){
                  dispatch(userAddressDetails(sources.data));
                  // dispatch(userAddressDetails(null));
                }else{
                  dispatch(userAddressDetails([]));
                  Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                }
          }).catch((error)=>{
              const sources = error.response;
              if(sources===undefined){
                Toaster.create({position: Position.TOP}).show({message:'Something went wrong!',intent: Intent.DANGER});
              }
              else if(sources.status!==401){
                sources.data.error.map((res)=>{
                  Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                  return res;
                })
              }else{
                Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
              }
          })
      },

      getPincodeList:(input)=>{
          return getPincodeList(input)
      },

      postAddressDetails:(obj) => {
        dispatch(postAddressDetailsAction(null));
          return postAddressDetails(obj)
              .then((res)=>{
                  const sources = res.data;
                  if(sources.status===true){
                      dispatch(postAddressDetailsAction(sources.data));
                      Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                      setTimeout(()=>{
                        window.location.reload();
                      },200)
                  }else{
                      dispatch(postAddressDetailsAction(null));
                  }
              }).catch((error)=>{
                  const sources = error.response;
                  if(sources===undefined){
                    Toaster.create({position: Position.TOP}).show({message:'Something went wrong!',intent: Intent.DANGER});
                  }
                  else{
                    Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                  }
              })
      },
        getShippingAddress:(obj) => {
            dispatch(shippingAddressAction(null));
            return getShippingAddress(obj)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(shippingAddressAction(sources.data));
                        // Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                    }else{
                        dispatch(shippingAddressAction(null));
                        Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                    }
                }).catch((error)=>{
                    const sources = error.response;
                    Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});

                })
        },

        getPlaceOrder:() => {
            return getPlaceOrder()
        },

        getPaymentMethod:(obj) => {
            return getPaymentMethod(obj)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        // Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                    }
                }).catch((error)=>{
                    const sources = error.response;
                    if(sources.status!==401){
                        sources.data.error.map((res)=>{
                            Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                            return res;
                        })
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                    }
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutComponent);
