import React,{Fragment} from 'react';
import ReactDOM from 'react-dom';
import {PackageConfigure} from 'gg-react-utilities';
import {packageConfigure} from './Config';
import App from './App';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';

ReactDOM.render(
  <Fragment>
    <App/>
   <PackageConfigure {...packageConfigure} />
  </Fragment>
  , document.getElementById('root'));
unregisterServiceWorker();
