let initialState = {
    userDetails:null,
    verifiedStatus:null,
    userData:null,
    userAddress:[]
};
const userInfo = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_SESSION':
            return {...state, userDetails: action.sources}
        case 'USER_DATA':
            return {...state, userData: action.sources}
        case 'VERIFY_STATUS':
            return {...state, verifiedStatus: action.sources}
        case 'USER_ADDRESS_LIST':
            return {...state, userAddress: action.sources}
        default:
            return state
    }
}

export default userInfo
