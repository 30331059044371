import React,{ Component } from 'react';
import ServiceDetailContainer from './container/ServiceDetailContainer';
import {Error} from 'gg-react-utilities';

export default class ServiceDetail extends Component{

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1000)
    }

    render(){
      try {
        return(
          <ServiceDetailContainer
            serviceSlug={this.props.match.params.service_slug}
            back={this.props.history}
          />
        )
      } catch (e) {
        return(
          <Error />
        )
      }
    }
}
