import React, {Component} from 'react';
// import { Card,  } from "@blueprintjs/core";


export default class NoProduct extends Component{

    render(){

        return(
          <div className='border p-4'>
            <center className='mb-5 mt-5 '>
              <img src={require("./images/No_products.svg")} alt=''/>
              {this.props.content?
                this.props.content
                :
                <p className='mt-3 mb-2'>There is no product found!</p>
              }
            </center>
          </div>
        );

    }

}
