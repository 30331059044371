import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";

import ProductComponent from '../component/ProductComponent';
import { getProductList, getCategoryList } from '../../core/actions/action';
import { productListAction } from '../../core/actions';

const mapStateToProps = state => {

    return{
        locationData:JSON.parse(localStorage.getItem('locationData')),
        productList:state.Home.productList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getProductList:(limit,lat,lng,radius,ggCoin,category,filter) => {
            // dispatch(productListAction(null));
            return getProductList(limit,lat,lng,radius,ggCoin,category,filter)
            .then((res)=>{
                const sources = res.data;

                if(sources.status===true){
                    dispatch(productListAction(sources.data));
                  }else{
                    dispatch(productListAction(null));
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                  }
            }).catch((error)=>{
                const sources = error.response;
                if(sources.status!==401){
                  sources.data.error.map((res)=>{
                    Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                    return res;
                  })
                }else{
                  Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                }
            })
        },
        getCategoryList:(name) => {
            return getCategoryList(name)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductComponent);
