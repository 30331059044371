import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";

import ServiceDetailComponent from '../component/ServiceDetailComponent';
import { getServiceDetail, getBookService } from '../../core/actions/action';
import { serviceDetailAction, serviceBookAction } from '../../core/actions';

const mapStateToProps = state => {
    return{
        locationData:state.Home.locationData,
        serviceDetails:state.ServiceDetail.serviceDetails,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getServiceDetail:(slug) => {
          dispatch(serviceDetailAction(null));
            return getServiceDetail(slug)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    dispatch(serviceDetailAction(sources.data));
                  }else{
                    dispatch(serviceDetailAction(null));
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                  }
            }).catch((error)=>{
                const sources = error.response;

                if(sources.status!==401){
                  sources.data.error.map((res)=>{
                    Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                    return res;
                  })
                }else{

                  dispatch(serviceDetailAction(sources.data.data))
                  Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                }
            })
        },

        getBookService:(slug, obj) => {
          dispatch(serviceBookAction(null));
            return getBookService(slug, obj)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    dispatch(serviceBookAction(sources.data));
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                    setTimeout(()=>{
                      window.location.reload();
                    },200)
                  }else{
                    dispatch(serviceBookAction(null));
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                  }
            }).catch((error)=>{
                const sources = error.response;
                if(sources.status!==401){
                  sources.data.error.map((res)=>{
                    Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                    return res;
                  })
                }else{
                  Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                }
            })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDetailComponent);
