
const config = {

  ACCOUNT_API_URL: process.env.REACT_APP_BASE_URL+'accounts.globalgarner'+process.env.REACT_APP_Extension+'/',
  API_URL:process.env.REACT_APP_BASE_URL+'store-api.globalgarner'+process.env.REACT_APP_Extension+'/v1.0.3/',
  UPOS_API_URL:process.env.REACT_APP_BASE_URL+'upos-api.globalgarner'+process.env.REACT_APP_Extension+'/v3/',
  MEGABRAND_API_URL:process.env.REACT_APP_BASE_URL+'mega-brands-api.globalgarner'+process.env.REACT_APP_Extension+'/user/v1.0.3/',
  MEGABRAND_URL:process.env.REACT_APP_BASE_URL+'mega-brands.globalgarner'+process.env.REACT_APP_Extension+'/',
  PARTNER_URL:process.env.REACT_APP_BASE_URL+"beta-partner.globalgarner"+process.env.REACT_APP_Extension+"/api/",
  BSW_URL:process.env.REACT_APP_BASE_URL+'best-shopping-websites.globalgarner'+process.env.REACT_APP_Extension+'/',
  Extension:process.env.REACT_APP_Extension
};


export default config;

export const packageConfigure={

 }
