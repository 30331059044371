import React, {Component, Fragment} from 'react';
import { Switch } from 'react-router-dom';
import 'normalize.css/normalize.css';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "gg-react-utilities/src/css/style.css";
import "gg-react-utilities/src/css/gg-styles.css";
import 'react-select/dist/react-select.css';
import 'react-input-range/lib/css/index.css';
import HomeRoute from "./HomeRoute";
import {Notfound, Maintance, GgUtils} from "gg-react-utilities";

import Home from "../Home";
import Vendor from "../Vendor";
import VendorDetail from "../VendorDetail";
import Product from "../Product";
import ProductDetail from "../ProductDetail";
import Service from "../Service";
import ServiceDetail from "../ServiceDetail";
import BillHistory from "../BillHistory";
import Cart from "../Cart";
import Checkout from "../Checkout";
import Payment from "../Payment";
import NearbyUpos from "../NearbyUpos";
import Food from "../Food";

export default class Root extends Component{

    componentDidMount(){

      if(process.env.REACT_APP_ENV==='production'){
        GgUtils.sentryScripts(process.env.REACT_APP_SENTRY_CODE);
        GgUtils.googleAnalyticalScripts(process.env.REACT_APP_GOOGLE_AUTHENTICATE_CODE);
      }
    }

    render(){
        return(
          <Fragment>
              {process.env.REACT_APP_IS_MAINTENANCE_MODE ==='true'?
                <Switch>
                  <HomeRoute path="*" component={Maintance} />
                </Switch>
                :
                <Switch>
                  <HomeRoute exact path="/" component={Home} />
                  <HomeRoute exact path="/vendor" component={Vendor} />
                  <HomeRoute exact path='/vendor/:vendor_slug' component={VendorDetail}/>
                  <HomeRoute exact path='/product' component={Product}/>
                  <HomeRoute exact path='/product/:product_slug' component={ProductDetail}/>
                  <HomeRoute exact path='/service' component={Service}/>
                  <HomeRoute exact path='/service/:service_slug' component={ServiceDetail}/>
                  <HomeRoute exact path='/payment/:status' component={Payment}/>
                  <HomeRoute exact path='/cart' component={Cart}/>
                  <HomeRoute exact path='/bill-history' component={BillHistory}/>
                  <HomeRoute exact path='/checkout' component={Checkout}/>
                  <HomeRoute exact path='/food' component={Food}/>
                  <HomeRoute exact path='/nearByUpos' component={NearbyUpos}/>
                  <HomeRoute path="*"  component={Notfound}/>
                </Switch>
              }
          </Fragment>
        );
    }
}
