import React,{ Component } from 'react';
import BillHistoryContainer from './container/BillHistoryContainer';
import {Error} from 'gg-react-utilities';

export default class BillHistory extends Component{

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1000)
    }

    render(){
      try {
        return(
          <BillHistoryContainer

            back={this.props.history}
          />
        )
      } catch (e) {
        return(
          <Error />
        )
      }
    }
}
