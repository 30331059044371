let initialState = {
    uploadedBills:null,
    trashBillList:null,
    trashBill:null,
    restoreBill:null

};

const home = (state = initialState, action) => {

    switch (action.type) {

        case 'UPLOAD_BILL_LIST':
            return {...state, uploadedBills: action.sources}
        case 'TRASH_BILL_LIST':
            return {...state, trashBillList: action.sources}
        case 'TRASH_BILL':
            return {...state, uploadedBills: action.sources}
        case 'RESTORE_BILL':
            return {...state, trashBills: action.sources}
        default:
            return state
    }
}

export default home;
