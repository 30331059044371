import React, {Component} from 'react';
import { Card } from "@blueprintjs/core";

export default class LoadingComponent extends Component{

    render(){

        return(
          <div className="row no-gutters mt-5">
            <div className="col-md-8 col-xl-8 col-lg-8 col-12">
              <div className="col-md-12 col-xl-12 col-lg-12 col-sm-12 col-12 mb-4">
                <Card className='bp3-elevation-1'>

                  <div className="bp3-skeleton" style={{height: "20px",width:'50%'}} />
                  <br/>
                  <div className="bp3-skeleton" style={{height: "20px",width:'80%'}} />
                  <br/>
                <div className="bp3-skeleton" style={{height: "20px",width:'35%'}} />

                </Card>
              </div>
              <div className="col-md-12 col-xl-12 col-lg-12 col-sm-12 col-12 mb-4">
                <Card className='bp3-elevation-1'>

                  <div className="bp3-skeleton" style={{height: "20px",width:'50%'}} />
                  <br/>
                  <div className="bp3-skeleton" style={{height: "20px",width:'80%'}} />
                  <br/>
                <div className="bp3-skeleton" style={{height: "20px",width:'35%'}} />

                </Card>
              </div>
              <div className="col-md-12 col-xl-12 col-lg-12 col-sm-12 col-12 mb-4">
                <Card className='bp3-elevation-1'>

                  <div className="bp3-skeleton" style={{height: "20px",width:'50%'}} />
                  <br/>
                  <div className="bp3-skeleton" style={{height: "20px",width:'80%'}} />
                  <br/>
                <div className="bp3-skeleton" style={{height: "20px",width:'35%'}} />

                </Card>
              </div>
              <div className="col-md-12 col-xl-12 col-lg-12 col-sm-12 col-12 mb-4">
                <Card className='bp3-elevation-1'>

                  <div className="bp3-skeleton" style={{height: "20px",width:'50%'}} />
                  <br/>
                  <div className="bp3-skeleton" style={{height: "20px",width:'80%'}} />
                  <br/>
                <div className="bp3-skeleton" style={{height: "20px",width:'35%'}} />

                </Card>
              </div>  
            </div>

            <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                <br/>
              <br/>
                <div className="bp3-skeleton" style={{height: "20px",width:'60%'}} />
                <br/>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-s-2">
                    <div className="bp3-skeleton" style={{height: "25px"}} />
                    <br/>
                  <div className="bp3-skeleton" style={{height: "25px"}} />
                    <br/>
                  <div className="bp3-skeleton" style={{height: "25px"}} />
                    <br/>
                  <div className="bp3-skeleton" style={{height: "25px"}} />
                    <br/>
                  <div className="bp3-skeleton" style={{height: "25px"}} />
                    <br/>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="bp3-skeleton" style={{height: "25px",width:'70%'}} />
                    <br/>
                  <div className="bp3-skeleton" style={{height: "25px",width:'80%'}} />
                    <br/>
                  <div className="bp3-skeleton" style={{height: "25px",width:'45%'}} />
                    <br/>
                  <div className="bp3-skeleton" style={{height: "25px",width:'30%'}} />
                    <br/>
                  <div className="bp3-skeleton" style={{height: "25px",width:'40%'}} />
                  </div>
                </div>
                <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
              </Card>
            </div>
        </div>
        );

    }

}
