import React, {Component} from 'react';
// import { Card,  } from "@blueprintjs/core";


export default class NoBillHistory extends Component{

    render(){

        return(
          <div className='border p-4'>
            <center className='mb-5 mt-5 '>
              <img src={require("./images/billhistory.svg")} alt=''/>
              {this.props.content?
                this.props.content
                :
                <p className='mt-3 mb-2'>No bill history found!</p>
              }
            </center>
          </div>
        );

    }

}
