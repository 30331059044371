import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import { PageTitle } from 'gg-react-utilities';
import Loading from "./loading";
// import SelectLocation from './selectLocation';
import VendorList from "./vendorList";
import ServiceList from "./serviceList";
import ProductList from "./productList";
import NoLocation from '../../core/components/emptyStates/noLocation';
import TopBar from "../../core/components/topBar";

export default class HomeComponent extends Component {

    constructor(props){
        super(props);
        this.state={
          limit:0,
          locationStatus:false
        }
    }

    componentDidMount(){

      if(this.props.locationData === null){

        this.setState({locationStatus:true})
      }else {
        this.props.getVendorList(8,this.props.locationData.lat,this.props.locationData.lng,'0-10','',[],'');
        this.props.getProductList(8,this.props.locationData.lat,this.props.locationData.lng,'0-10','',[],'');
        this.props.getServiceList(8,this.props.locationData.lat,this.props.locationData.lng,'0-10',[],'');
      }

    }

    componentWillReceiveProps(nextProps){

    }


    render() {
      let metaData = document.getElementsByTagName("meta");
      metaData['description'].content = 'Now via Global Garner Local Shopping is also possible with big cashback deals , Just one search for your near by services, products or vendor';
      metaData['keywords'].content = 'Nearby stores, Local Nearby Stores, Regional vendor, Local Services, Regional Product';
        return (
            <Fragment>
              <PageTitle title='Nearby Stores | Global Garner' />
      				<div className="container mb-5">
                <TopBar title={"GG Store"} backIcon={false}/>
                <div className="store-part">
                  <div className='row'>
                    <div className="col-md-7">
                      <img src={require("../images/Vendor.svg")} alt="store" className='img-fluid' style={{'marginTop':'2em' }}/>
                    </div>

        						<div className="col-md-5 pull-right d-flex text-center">
                      <div className="col-xl-4 col-4 py-5">
                        <Link to='/vendor'>
                          <img src={require("../images/vendor.png")} alt="vendor" className='img-fluid'/>
                          <p className='py-3'>Vendors</p>
                        </Link>
                      </div>
        							<div className="col-xl-4 col-4 py-5">
                        <Link to='/product'>
                          <img src={require("../images/product.png")} alt="product" className='img-fluid'/>
                          <p className='py-3'>Products</p>
                      </Link>
        							</div>
        							<div className="col-xl-4 col-4 py-5">
                        <Link to='/service'>
                          <img src={require("../images/service.png")} alt="service" className='img-fluid'/>
                          <p className='py-3'>Services</p>
                        </Link>
        							</div>
        						</div>
                  </div>

      					</div>
      					<div className="clearfix"></div>
      					<section className="pt-5">
      						<div className="row">
      							<div className="col-xl-7 col-xl-7 col-sm-7 col-8">
      								<h1 className="product-title">Nearby GG Vendors</h1>
      							</div>
      							<div className="col-xl-5 col-xl-5 col-sm-5 col-4">
      								<Link to='/vendor' className='mt-2 pull-right link'>View All</Link>
      							</div>
      						</div>
                  {this.props.locationData===null?
                    <div className='mt-4'>
                      <NoLocation/>
                    </div>
                    :this.props.vendorList===null ?
                    <Loading />
                    :
                    <VendorList vendorList={this.props.vendorList.data}/>
                  }
      					</section>
      					<div className="clearfix"></div>
                <section className="pt-5">
      						<div className="row">
      							<div className="col-xl-7 col-xl-7 col-sm-7 col-8">
      								<h1 className="product-title">Nearby Products</h1>
      							</div>
      							<div className="col-xl-5 col-xl-5 col-sm-5 col-4">
      								<Link to='/product' className='mt-2 pull-right link'>View All</Link>
      							</div>
      						</div>
                  {this.props.locationData===null?
                    <div className='mt-4'>
                      <NoLocation/>
                    </div>
                    :this.props.productList===null ?
                    <Loading />
                    :
                    <ProductList productList={this.props.productList.data}/>
                  }
      					</section>
      					<div className="clearfix"></div>
                <section className="pt-5">
      						<div className="row">
      							<div className="col-xl-7 col-xl-7 col-sm-7 col-8">
      								<h1 className="product-title">Nearby Services</h1>
      							</div>
      							<div className="col-xl-5 col-xl-5 col-sm-5 col-4">
      								<Link to='/service' className='mt-2 pull-right link'>View All</Link>
      							</div>
      						</div>
                  {this.props.locationData===null?
                    <div className='mt-4'>
                      <NoLocation/>
                    </div>
                    :this.props.serviceList===null ?
                    <Loading />
                    :
                    <ServiceList serviceList={this.props.serviceList.data}/>
                  }
      					</section>
      					<div className="clearfix"></div>
      				</div>
            </Fragment>
          )
        }
    }
