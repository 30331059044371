import React, {Component, Fragment} from 'react';
import { Button, Dialog, Classes, FormGroup, Position, Popover, InputGroup} from "@blueprintjs/core";
import {DateInput, TimePicker} from '@blueprintjs/datetime';
import {PageTitle, Auth, Helper} from 'gg-react-utilities';
import TopBar from "../../core/components/topBar";
import Loading from "./loading";
// import BookService from './bookServiceModal';
// import ServiceTerms from '../../core/components/serviceTermsModal';
import NoService from "../../core/components/emptyStates/noService";
import moment from 'moment';

const reg ={
    EMAIL_REGEX : /[^@]+@[^@]+\.[^@]+/
};

export default class ServiceDetailComponent extends Component {

    constructor(props){
        super(props);
        this.state={
          bookSeviceModal:false,
          isVisible:false,
          terms:'',
          spclPriceFrom:'',
          currentTime:new Date().getTime(),
          spclPriceTo:'',
          autoFocus: true,
          canEscapeKeyClose: true,
          canOutsideClickClose: true,
          enforceFocus: true,
          isOpen: props.isOpen,
          date:null,
          hasDateError:false,
          hasDateErrorMsg:"",
          hasDateBorder:"",
          time:new Date(),
          hasTimeError:false,
          hasTimeErrorMsg:"",
          hasTimeBorder:true,
          minTimeValue:new Date(),
          fname:Auth.user().fname,
          hasFirstNameError:false,
          hasFirstNameErrorMsg:"",
          hasFirstNameBorder:true,
          lname:Auth.user().lname,
          hasLastNameError:false,
          hasLastNameErrorMsg:"",
          hasLastNameBorder:true,
          mobile:Auth.user().mobile,
          hasMobileError:false,
          hasMobileErrorMsg:"",
          hasMobileBorder:true,
          email:Auth.user().email,
          hasEmailError:false,
          hasEmailErrorMsg:"",
          hasEmailBorder:true
        }
          this.handleClose = this.handleClose.bind(this);
          this.handleBookingClose = this.handleBookingClose.bind(this);
          this.handleDateChange = this.handleDateChange.bind(this);
    }

    componentDidMount(){
      this.props.getServiceDetail(this.props.serviceSlug);
    }

    componentWillReceiveProps(nextProps){
      if(nextProps.serviceDetails!==null){
        this.setState({
          spclPriceFrom:new Date(nextProps.serviceDetails.special_price_from).getTime(),
          spclPriceTo:new Date(nextProps.serviceDetails.special_price_to).getTime()
        })
      }
    }

    handleBookingClose=()=>{
      this.setState({
        bookSeviceModal: false
      })
    }

    handleClose=()=>{
      this.setState({
        isVisible: false
      })
    }

    handleDateChange(date){
      this.setState({date:date})
    }

    discount(spclPrice,price){
      let discount = 0;
      discount = (100-(spclPrice*100)/price);
      return discount.toFixed(2)
    }

    doBookService(slug){
        let currentTime = new Date(moment().format('YYYY-MM-DD hh:mm:ss')).getTime();
        let bookTime = new Date(moment(this.state.date).format('YYYY-MM-DD') + " " + moment(this.state.time).format('hh:mm:ss')).getTime();

        if(!this.state.hasDateError || this.state.date===null){
            this.setState({
                hasDateError:false,
                hasDateErrorMsg:"Date field cannot be empty",
                hasDateBorder:true
            });
        }
        if(currentTime > bookTime){
            this.setState({
              hasTimeError:true,
              hasTimeErrorMsg:"Time should be greater than today",
              hasTimeBorder:false
            });
            return false;
        }else{
          this.setState({
            hasTimeError:false,
            hasTimeErrorMsg:"",
            hasTimeBorder:true
          });
        }

        if(this.state.fname===""){
            this.setState({
                hasFirstNameError:true,
                hasFirstNameErrorMsg:"First Name field cannot be empty",
                hasFirstNameBorder:false
            });
        }else{
            this.setState({
                hasFirstNameError:false,
                hasFirstNameErrorMsg:"",
                hasFirstNameBorder:true
            });
        }
        if(this.state.lname===""){
            this.setState({
                hasLastNameError:true,
                hasLastNameErrorMsg:"Last Name field cannot be empty",
                hasLastNameBorder:false
            });
        }else{
            this.setState({
                hasLastNameError:false,
                hasLastNameErrorMsg:"",
                hasLastNameBorder:true
            });
        }
        if(this.state.email===""){
            this.setState({
                hasEmailError:true,
                hasEmailErrorMsg:"Email ID field cannot be empty",
                hasEmailBorder:false
            })
        }else if (!reg.EMAIL_REGEX.test(String(this.state.email).toLowerCase())) {
            this.setState({
                hasEmailError:true,
                hasEmailErrorMsg:"Email ID is not valid",
                hasEmailBorder:false
            })
        }else{
            this.setState({
                hasEmailError:false,
                hasEmailErrorMsg:"",
                hasEmailBorder:true
            })
        }

        if(this.state.mobile===""){
            this.setState({
                hasMobileError:true,
                hasMobileErrorMsg:"Mobile field cannot be empty",
                hasMobileBorder:false
            })
        }else{
            this.setState({
                hasMobileError:false,
                hasMobileErrorMsg:"",
                hasMobileBorder:true
            })
        }

        if(this.state.date!==null &&
            this.state.fname!=="" &&
            this.state.lname!=="" &&
            this.state.mobile!==""&&
            this.state.email!=="" ) {
            let obj = {
                "service_start": moment(this.state.date).format('YYYY-MM-DD') + " " + moment(this.state.time).format('hh:mm:ss'),
                "firstname": this.state.fname,
                "lastname": this.state.lname,
                "email": this.state.email,
                "mobile": this.state.mobile
            };
            this.props.getBookService(slug, obj)
            setTimeout(()=>{
              this.setState({isOpen:false});
            },200)
        }else{
            // console.error("Please resolve all errors before submit the form");
        }
    }

    render() {
      const {serviceDetails} = this.props;
      if(serviceDetails!==null){
        let metaData = document.getElementsByTagName("meta");
        metaData['description'].content = serviceDetails.meta_description;
        metaData['keywords'].content = serviceDetails.meta_keyword;
      };
        return (
              <div className="container">
                  {serviceDetails===null?
                    <Loading/>
                    :
                    !serviceDetails.status
                    ?
                    <div className='mt-5 mb-5'>
                      <NoService />
                    </div>
                    :
                    <Fragment>
                      <PageTitle title={serviceDetails.meta_title} />
                      <TopBar title={serviceDetails.title} backClick={this.props.back}
                        breadcrumbs={[
                          {label:'Service',to:'/service'},
                          {label:serviceDetails.title,to:'/service/'+serviceDetails.slug},
                        ]}
                        />

                      <Dialog
                			    isOpen={this.state.bookSeviceModal}
                				title={'Booking Details'}
                				className={'bp3-dialog p-0 rounded bookNowDialog'}
                        canOutsideClickClose={false}
                        canEscapeKeyClose={true}
                          onClose={this.handleBookingClose}>
                          <div className={Classes.DIALOG_BODY+' p-1 m-0'} >
                						<div className='container row p-s-0'>
                                          <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4'>
                                            <FormGroup
                                              helperText={this.state.hasDateErrorMsg}>
                                              <DateInput
                                                  closeOnSelection={true}
                                                  className={this.state.hasDateBorder!=="" ? 'dateWrapper' : ''}
                                                  formatDate={date => date.toLocaleDateString()}
                                                  minDate={new Date()}
                                                  parseDate={str => new Date(str)}
                                                  onChange={(res) => {
                                                      this.setState({
                                                          date: res,
                                                          hasDateError: true,
                                                          hasDateErrorMsg: "",
                                                          hasDateBorder: ""
                                                      })
                                                  }}
                                                  placeholder={"DD/MM/YYYY"}
                                                  popoverProps={{ position: Position.BOTTOM }}
                                                  />
                                            </FormGroup>
                                          </div>
                							<div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4'>
                                              <FormGroup
                                                  helperText={this.state.hasTimeErrorMsg}>
                                                  <Popover className={!this.state.hasTimeBorder ? 'timeWrapper':''} content={
                                                      <TimePicker
                                                          closeOnSelection={true}
                                                          value={this.state.time}
                                                          onChange={(res) => {
                                                              this.setState({
                                                                  time:res,
                                                                  hasTimeError:true,
                                                                  hasTimeErrorMsg:"",
                                                                  hasTimeBorder:true
                                                              })
                                                          }}
                                                          popoverProps={{ position: Position.BOTTOM }}
                                                          showArrowButtons={true}
                                                          useAmPm={true}
                                                      />
                                                      } position={Position.BOTTOM}>
                                                      <input type="text" autoComplete="off" className="bp3-input" placeholder="00:00" value={moment(this.state.time).format('hh:mm A')}
                                                          onChange={(e) => {

                                                          }}/>
                                                  </Popover>
                                              </FormGroup>
                							</div>
                							<div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4'>
                                              <FormGroup
                                                  helperText={this.state.hasFirstNameErrorMsg}>
                                                  <InputGroup id="text-input" placeholder="Enter First Name" value={this.state.fname}
                                                      className={!this.state.hasFirstNameBorder ? 'border-danger':''}
                                                      onChange={(e)=>{
                                                          this.setState({
                                                              fname:e.target.value,
                                                              hasFirstNameError:true,
                                                              hasFirstNameErrorMsg:"",
                                                              hasFirstNameBorder:true
                                                          })
                                                      }}/>
                                              </FormGroup>
                							</div>
                							<div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4'>
                                              <FormGroup
                                                  helperText={this.state.hasLastNameErrorMsg}>
                                                  <InputGroup id="text-input" placeholder="Enter Last Name" value={this.state.lname}
                                                      className={!this.state.hasLastNameBorder ? 'border-danger':''}
                                                      onChange={(e)=>{
                                                        this.setState({
                                                            lname:e.target.value,
                                                            hasLastNameError:true,
                                                            hasLastNameErrorMsg:"",
                                                            hasLastNameBorder:true
                                                        });
                                                      }}/>
                                              </FormGroup>
                							</div>
                                          <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4'>
                                              <FormGroup
                                                  helperText={this.state.hasMobileErrorMsg}>
                                                  <InputGroup type='number' id="text-input" placeholder="Enter Contact Number" value={this.state.mobile}
                                                      className={!this.state.hasMobileBorder ? 'border-danger':''}
                                                      onChange={(e)=>{
                                                        this.setState({
                                                            mobile:e.target.value,
                                                            hasMobileError:true,
                                                            hasMobileErrorMsg:"",
                                                            hasMobileBorder:true
                                                        })
                                                      }}/>
                                              </FormGroup>
                							</div>
                							<div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                                              <FormGroup
                                                  helperText={this.state.hasEmailErrorMsg}>
                                                  <InputGroup type='email' id="text-input" placeholder="Enter Email Address" value={this.state.email}
                                                      className={!this.state.hasEmailBorder ? 'border-danger':''}
                                                      onChange={(e)=>{
                                                        this.setState({
                                                            email:e.target.value,
                                                            hasEmailError:true,
                                                            hasEmailErrorMsg:"",
                                                            hasEmailBorder:true
                                                        })
                                                      }}/>
                                              </FormGroup>
                							</div>
                						</div>
                          </div>
                          <div className={Classes.DIALOG_FOOTER+' mb-3 mt-3 mb-4'}>
                              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                  <Button text='Cancel' className='bp3-button bp3-button bp3-secondary' onClick={this.handleBookingClose}/> &nbsp; &nbsp;
                                  <Button text='Book Now' className='bp3-button bp3-intent-primary bp3-button bp3-intent-primary'
                                    onClick={()=>{
                                      this.doBookService(serviceDetails.slug)
                                    }}/>
                              </div>
                          </div>
                      </Dialog>
                    <Dialog isOpen={this.state.isVisible}
                         className="bp3-dialog rounded" title={'Terms & Conditions'}
                         onClose={this.handleClose}
                         canOutsideClickClose={false}
                         canEscapeKeyClose={true}
                         >
                          <div className={Classes.DIALOG_BODY}>
                             <div className="col-12 mb-5">
                                {this.state.terms}
                             </div>
                             <div className="row">
                                <div className="col-6"/>
                                 <div className="col-md-6 col-12 pull-right p-s-0 text-right px-0">
                                   <Button
                                       onClick={this.handleClose}
                                       className="bp3-button bp3-intent-danger bp3-button bp3-small px-4 rounded"
                                       text="Close"
                                   />
                               </div>
                           </div>
                       </div>
                      </Dialog>

                    <div className="row serviceDetail mb-5 mt-3">
                        <div className='col-xl-6 col-lg-6 col-md-5 col-sm-4 col-12'>
                          <img src={process.env.REACT_APP_SERVICE_IMAGE} alt="place" className="image-fluid image"/>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-7 col-sm-8 col-12 mt-s-3'>
                          <p className='title'>{serviceDetails.company_name+' - '+serviceDetails.title}</p>
                          <div className='row'>

                            <div className='col-xl-12 col-sm-12 col-12'>
                              <Button text='Terms & Conditions' className='bp3-button btn-link'
                              onClick={()=>{this.setState({isVisible:true,terms:serviceDetails.terms_and_conditions})}}/>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-sm-6 col-md-6 col-6 mt-2'>
                              {serviceDetails.special_price!==null && Number(serviceDetails.special_price)!==0 &&serviceDetails.special_price_from!==null&&serviceDetails.special_price_to!==null?
                                (this.state.spclPriceFrom <= this.state.currentTime)&&  (this.state.currentTime <= this.state.spclPriceTo)?
                                <p className="service-price bp3-text-medium">{Helper.formatMoney(serviceDetails.special_price)}</p>
                                :
                                <p className="service-price bp3-text-medium">{Helper.formatMoney(serviceDetails.price)}</p>
                                :
                                <p className="service-price bp3-text-medium">{Helper.formatMoney(serviceDetails.price)}</p>
                              }
                            </div>
                            <div className='col-xl-6 col-lg-6 col-sm-6 col-md-6 col-6 mt-2'>
                              {serviceDetails.special_price!==null && Number(serviceDetails.special_price)!==0 &&serviceDetails.special_price_from!==null&&serviceDetails.special_price_to!==null?
                                (this.state.spclPriceFrom <= this.state.currentTime)&&  (this.state.currentTime <= this.state.spclPriceTo)?
                                <p className="service-price-strike bp3-text-small text-nowrap text-right"><strike>{Helper.formatMoney(serviceDetails.price)}</strike></p>
                                :null
                              :null}
                            </div>

                          </div>
                          <p className='mb-4'>{serviceDetails.description}</p>
                          <Button text='Book now' className='bp3-button bp3-intent-primary bp3-button bp3-intent-primary'
                            onClick={()=>{
                                if(!Auth.isLoggedIn()){
                                    Auth.login(true)
                                }else{
                                    this.setState({bookSeviceModal:true})
                                }

                            }}/>
                        </div>
                      </div>
                    </Fragment>
                      }

              </div>
          )
        }
    }
