import React, {Component, Fragment} from 'react';
import { Button, Checkbox } from "@blueprintjs/core";
import StarRatings from 'react-star-ratings';
import {Link} from 'react-router-dom';
import {Auth, Loader} from 'gg-react-utilities';
import InfiniteScroll from "react-infinite-scroll-component";
import InputRange from 'react-input-range';
import {getString} from "../../../core/utils/helper";
import { LocationOnRounded, PhoneAndroidRounded } from '@material-ui/icons';
import Loading from "../loading";
import NoLocation from '../../../core/components/emptyStates/noLocation';
import NoVendor from '../../../core/components/emptyStates/noVendor';
import Favorite from "../../../core/containers/vendorFavouriteContainer";


export default class FoodVendor extends Component{
    constructor(props){
        super(props);
        this.state={
          name:'',
          isEnabled: false,
          value: {
              min: 0,
              max: 10,
          },
          voucher_gg_coin: '',
          tat: [],
          type: [],
          brand_name: "",
          count: 1,
          food_voucher_data: [],
          limit:10
        }
        this.fetchMoreData = this.fetchMoreData.bind(this);
    }

    componentDidMount(){
      if (this.props.locationData !== null) {
          this.props.getVendorFoodList(this.state.limit, this.props.locationData.lat, this.props.locationData.lng,this.state.value.min+'-'+this.state.value.max,this.state.isEnabled,this.state.name);
      }
    }

    componentWillReceiveProps(nextProps){

    }

    resetFilter(){
      let obj = {
        min:0,
        max:10
      }
      this.setState({isEnabled:false,value:obj},()=>{
        this.props.getVendorFoodList(this.state.limit,this.props.locationData.lat,this.props.locationData.lng,this.state.value.min+'-'+this.state.value.max,this.state.isEnabled,this.state.name);
      })
    }

    filterData(){

      this.props.getVendorFoodList(this.state.limit, this.props.locationData.lat, this.props.locationData.lng,this.state.value.min+'-'+this.state.value.max,this.state.isEnabled,this.state.name);
    }

    fetchMoreData(){
      if(this.props.food_list!==null){
        if(this.props.food_list.totalRecords >= this.props.food_list.data.length+1){
          this.setState({limit:this.state.limit+10},()=>{
            this.props.getVendorFoodList(this.state.limit, this.props.locationData.lat, this.props.locationData.lng,this.state.value.min+'-'+this.state.value.max,this.state.isEnabled,this.state.name);

          })
        }
      }
    }

    render(){
      const {food_list} = this.props;
        return(
        <Fragment>
            <div className='row'>
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-12">
                  <div className='food-sidebar'>
                    <div className="rounded-0 border p-3">
                        <div className="row">
                            <div className="col-xl-12">
                                <h5 className="text-title">By GG-Coin </h5>
                            </div>
                            <div className="col-xl-12">
                                <div className="form-group form-check">
                                    <label className="form-check-label">
                                        <Checkbox checked={this.state.isEnabled}
                                                  label="GG-Coin Applicable" onChange={(e) => {
                                            this.setState({isEnabled: !this.state.isEnabled})
                                        }}/>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-xl-12">
                                <h5 className="text-title">Radius</h5>
                            </div>
                            <div className="col-xl-12 px-4">
                                <br/>
                                <InputRange
                                    minValue={0}
                                    maxValue={20}
                                    formatLabel={value => `${value} km`}
                                    value={this.state.value}
                                    onChange={value => this.setState({value: value})}
                                />
                                <br/>
                                <br/>
                                  <br/>
                                  <br/>
                                <Button className='bp3-button bp3-secondary bp3-small pull-left' text='Reset Filter'
                                  onClick={()=>{
                                    this.resetFilter()
                                  }}/>
                                  <Button className='bp3-button bp3-intent-primary bp3-small pull-right' text='Apply Filter'
                                    onClick={()=>{
                                      this.filterData()
                                    }}/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
              <div className="col-xl-9 col-lg-9 col-md-8 col-sm-7 col-12 mt-s-3 pl-sm-0 pl-md-3 pl-lg-3 pl-xl-3 scroll-container">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 px-0 mb-4">
                    <div className="bp3-input-group ">
          					<input type="text" className="bp3-input" placeholder="Search your favorite vendor" value={this.state.name}
          					onChange={(e)=>{
          						this.setState({name:e.target.value},()=>{
                        this.props.getVendorFoodList(this.state.limit, this.props.locationData.lat, this.props.locationData.lng,this.state.value.min+'-'+this.state.value.max,this.state.isEnabled,this.state.name);
                      })
          					}}/>

          					</div>
          				</div>
                  {this.props.locationData===null?
                    <div className='col-xl-12 col-12'>
                      <NoLocation/>
                  </div>
                  :
          				<div className="food-vendor-list mt-4">
                      {food_list===null?
                      <Loading/>
                      :

                      food_list.data.length===0?
                        <div className='col-xl-12 col-12'>
                          <NoVendor/>
                        </div>
                      :
                      <InfiniteScroll
                        dataLength={food_list.data.length}
                        next={this.fetchMoreData}
                        hasMore={true}
                        loader={food_list.totalRecords >= food_list.data.length+1?<div className='mt-1'><Loader/></div>:null}
                        >
                          <div className='row no-gutters'>
                            {food_list.data.map((vendor) => {
                    						return(
                    							<div key={vendor.vendor_id} className='col-xl-12 col-12'>
                    								<div className='border mb-4 px-2 py-3'>
                    									<div className="row">
                    										<div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 text-center-xs">
                    											<Link to={'/vendor/'+vendor.vendor_slug}>
                    												<img src={vendor.display_pic===null||vendor.display_pic===''?process.env.REACT_APP_DEFAULT_IMAGE:vendor.display_pic}
                    												alt="place"
                    												className="image img-fluid"
                    												onError={(ev)=>{
                    													ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                    												}}/>
                    											</Link>
                    										</div>
                    										<div className="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12 mt-2 px-s-4 px-sm-4 px-md-3 px-lg-3 px-xl-3">
                    											<div className='pull-right pr-1 pt-1'>
                                            {!Auth.isLoggedIn()?
                                              <Favorite show={false} vendor_slug={{"vendor_slug":vendor.vendor_slug}}/>
                                              :
                    												  <Favorite show={vendor.is_favorite===0?false:true} vendor_slug={{"vendor_slug":vendor.vendor_slug}}/>
                                            }
                    											</div>
                    											<Link to={'/vendor/'+vendor.vendor_slug}>
                    												<p className='title'>{vendor.company_name}</p>
                    											</Link>
                    											<div className='row align-items-center'>
                    												<div className='col-md-4 col-sm-5 col-5 mb-s-1 pr-s-0'>
                    													<span className="review mr-2">{vendor.avg_rating===null?0:vendor.avg_rating}</span>
                    													<StarRatings
                    														rating={vendor.avg_rating===null?0:vendor.avg_rating}
                    														starRatedColor="#F5A623"
                    														starDimension='10px'
                    														numberOfStars={5}
                    														name='rating'
                    													/>
                    												</div>
                    												{vendor.gg_coin_accept === 1 ?
                    													<div className='col-md-8 col-sm-7 col-7 pr-s-0 text-nowrap'>
                    														<p className="text-secondary align-items-center d-flex bp3-text-small">
                    															<img src={process.env.REACT_APP_GG_COIN_IMAGE} alt="gg_coins" className='gg-coin-img mr-2'/>
                    															GG Coins Applicable
                    														</p>
                    													</div>
                    												:null}
                    											</div>
                    											<div className="vendor-text mb-2">
                    												{vendor.vendor_description}
                    											</div>
                    											<div className="vendor-text mt-2 mb-2">
                    												{vendor.vendor_contact_person.length>0 ?
                    												<p className="align-items-center d-flex vendor-text"><PhoneAndroidRounded/>&nbsp;&nbsp;{vendor.vendor_contact_person[0].mobile}</p>
                    												: <p><PhoneAndroidRounded/></p>}
                    											</div>
                    											<div className="vendor-text align-items-center d-flex mb-s-2">
                    												<LocationOnRounded />&nbsp;&nbsp;
                                            {getString([vendor.street1,vendor.street2,vendor.landmark,
                                              vendor.area, vendor.state],vendor.pincode)}
                    											</div>
                    										</div>
                    									</div>
                    								</div>
                    							</div>
                    						);
                    					})
                            }
                          </div>
                    </InfiniteScroll>
                  }
                </div>
              }
              </div>
            </div>
        </Fragment>

        );

    }

}
