import React, {Component, Fragment} from 'react';
import { Button, Tooltip, Position, Alert, Intent,Dialog, Classes } from "@blueprintjs/core";
import InfiniteScroll from "react-infinite-scroll-component";
import { ThumbUpAltRounded, ThumbDownAltRounded, TagFacesRounded, LoopRounded, VerifiedUserRounded } from '@material-ui/icons';
import {Loader, Helper} from 'gg-react-utilities';
// import MultiStep from 'react-multistep';
// import ViewBill from "./modal/viewBillModal";
// import CancelModal from "./modal/cancelModal";
import NoBillHistory from "../../core/components/emptyStates/noBillHistory";
import Loading from "./loading";

// const steps = [
//               {name: 'StepOne', component: '<StepOne/>'},
//               {name: 'StepTwo', component: '<StepTwo/>'},
//               {name: 'StepThree', component: '<StepThree/>'},
//               {name: 'StepFour', component: '<StepFour/>'}
//             ];

export default class UploadedBills extends Component{

    constructor(props){
        super(props);
        this.state={
          viewBillModal:false,
          isTrashVisible:false,
          bill_number:'',
          data:null,
          limit:10
        }
        this.fetchMoreData = this.fetchMoreData.bind(this);
        this.handleCloseView = this.handleCloseView.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount(){
      this.props.getBillList(this.state.limit);
    }

    componentWillReceiveProps(nextProps){
    }

    fetchMoreData(){
      if(this.props.uploadedBills!==null){
        if(this.props.uploadedBills.totalRecords>this.props.uploadedBills.data.length+1){
          this.setState({limit:this.state.limit+10}, () => {
            this.props.getBillList(this.state.limit);
          })

        };
      }
    }

    handleClose=()=>{
      this.setState({
        isTrashVisible: false
      })
    }

    handleCloseView=()=>{
      this.setState({
        viewBillModal: false
      })
    }

    renderMilestoneIcon(status){
      // console.log(status);
      switch (status) {
          case 'Processed':
              return <LoopRounded className='bp3-icon'/>
          case 'In Progress':
              return <LoopRounded className='bp3-icon'/>
          case 'Verified':
              return <VerifiedUserRounded className='bp3-icon'/>
          case 'Verification':
              return <VerifiedUserRounded className='bp3-icon'/>
          case 'Approved':
              return <ThumbUpAltRounded className='bp3-icon'/>
          case 'Adding in UPV':
              return <TagFacesRounded className='bp3-icon'/>
          case 'Deleted':
              return <img src={require('../images/Reject.svg')} alt='cancel' className='bp3-icon'/>
          case 'Rejected':
              return <img src={require('../images/Reject.svg')} alt='cancel' className='bp3-icon'/>
          case 'Approval':
              return <ThumbDownAltRounded className='bp3-icon'/>
          default:
              return ''
      }
    }

    render(){
      const {uploadedBills} = this.props;
      const {data} = this.state;
        return(
          <Fragment>

              <Alert
                icon={"trash"}
                intent={Intent.DANGER}
                isOpen={this.state.isTrashVisible}
                cancelButtonText="No"
                onCancel={this.handleClose}
                confirmButtonText="Yes"
                onConfirm={()=>{
                  this.props.getTrashBill(this.state.bill_number)
                }}
                >
              <strong>Are you sure you want to trash this bill? </strong>
            </Alert>
            <Dialog
              isOpen={this.state.viewBillModal}
              title={'View Bill'}
              className={'bp3-dialog bp3-dialog-large p-0 rounded'}
              canOutsideClickClose={false}
              canEscapeKeyClose={true}
              onClose={this.handleCloseView}>
               <div className={Classes.DIALOG_BODY+' p-1 mx-s-2 mx-sm-5 mx-md-5 mx-lg-5 mx-xl-5'} >
               {data!==null?
                 <div className='row container viewBillModal'>
                   <div className='col-xl-12 mb-4 text-center-xs'>
                     <center>
                       <img src={data.bill_image}
                       className='img-fluid product-img'
                       alt=""
                       onError={(ev)=>{
                       ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                       }}/>
                     </center>
                   </div>


                  <div className="container slider-one-active col-xl-12 col-12 mt-4">
                      <div className="line">
                          <div className="dot-move"></div>
                            {data.milestone.map((res,key)=>{
                              let status = "dot zero";

                              if(key===1) status = "dot center";
                              if(key===2) status = "dot full";
                              if(key===3) status = "dot end";
                              return (
                                <div className={status+' bg-'+res.label.toLowerCase()} key={key}>
                                  {this.renderMilestoneIcon(res.title)}
                                </div>
                              )
                            })}
                          </div>
                          <div className="steps">
                            {data.milestone.map((res,key)=>{
                              return(
                                <div className={"step step-"+(key+1)} key={key}>
                                    <div className="liner"></div>
                                    <p>{res.title}</p>
                                    <span>{res.message}</span>
                                    <p className="date">{res.date}</p>
                                </div>
                              )
                            })}
                          </div>
                       </div>
                       <div className='row container mt-5 text-left p-s-0'>
                         <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 mb-3 p-s-0'>
                           <p className='text-muted'>Bill Number</p>
                           <p className='title'>{data.formatted_bill_number}</p>
                         </div>
                         <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 mb-3 p-s-0'>
                           <p className='text-muted'>Used GG Coin</p>
                           <p className='title'>{data.gg_coins}</p>
                         </div>
                         <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 mb-3 p-s-0'>
                           <p className='text-muted'>Bill Amount</p>
                           <p className='title'>{Helper.formatMoney(data.purchase_amount)}</p>
                         </div>
                         <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 mb-3 p-s-0'>
                           <p className='text-muted'>Category</p>
                           <p className='title'>{data.category_name}</p>
                         </div>
                         <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 mb-3 p-s-0'>
                           <p className='text-muted'>Vendor Name</p>
                           <p className='title'>{data.company_name}</p>
                         </div>
                         <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 mb-3 p-s-0'>
                           <p className='text-muted'>Date Of Purchase</p>
                           <p className='title'>{data.upload_date}</p>
                         </div>
                       </div>
                     </div>
                   :
                   null
                 }
               </div>
           </Dialog>

            {uploadedBills===null
              ?
              <Loading/>
              :
              uploadedBills.length===0?
              <div className='col-12 col-xl-12 py-2'>
                <NoBillHistory/>
              </div>
                :
              <InfiniteScroll
                dataLength={uploadedBills.data.length}
                next={this.fetchMoreData}
                hasMore={true}
                loader={uploadedBills.data.length+1<uploadedBills.totalRecords
                  ?
                  <div className='mt-1'><Loader/></div>
                  :null
                }
                >
                <table className="bp3-html-table table-borderless mt-4 scroll-container">
                  <thead className="thead-light">
                    <tr>
                      <th colSpan={2}>Product Detail</th>
                      <th width="100">Order Id</th>
                      <th width="120">Order Date</th>
                      <th width="100">Price</th>
                      <th width="100">Status</th>
                      <th width="100">Action</th>
                    </tr>
                  </thead>

                  <tbody className=''>
                    {
                      uploadedBills.data.map((bill)=>{

                      return(
                        <tr key={bill.bill_number}>
                          <td>
                            <div className="border-rounded">
                              <img
                                src={bill.bill_image}
                                alt=""
                                onError={(ev)=>{
                                  ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                                }}
                              />
                            </div>
                          </td>
                          <td className="mr-1">
                            <p className="m-0">
                            {bill.category_name}
                            </p>
                            <p className="m-0">
                            {bill.company_name}
                            </p>
                          </td>
                          <td>
                            <div className="mr-1">{bill.bill_number}</div>
                          </td>
                          <td>
                            <div className="mr-1">{bill.upload_date}</div>
                          </td>
                          <td className='text-nowrap'>
                            <div className="mr-1">
                              <b>{Helper.formatMoney(bill.purchase_amount)}</b>
                            </div>
                          </td>
                          <td>
                            <div className={"mr-1 text-"+bill.status_label.toLowerCase()}>
                            {bill.status}
                            </div>
                          </td>
                          <td>
            							<Tooltip content="View Bill" position={Position.LEFT}>
            								<Button
            								icon='eye-open'
            								className="bp3-button bp3-intent-primary bp3-button bp3-small mr-2"
            								onClick={() =>{
            								  this.setState({viewBillModal:true,data:bill})
            								}}
            								/>
            							</Tooltip>
                                      {bill.is_deletable ?
            							<Tooltip content="Delete Bill" position={Position.RIGHT}>
            								<Button
            								icon='trash'
            								className="bp3-button bp3-intent-danger bp3-button bp3-small "
            								onClick={() => {this.setState({isTrashVisible:true,bill_number:bill.bill_number})}}
            								/>
            							</Tooltip>
                                        :

            								<Button
            								icon='trash'
            								className="bp3-button bp3-intent-danger bp3-button bp3-small "
            								disabled
            								/>

                          }
                          </td>
                        </tr>
                        );
                    })}
                  </tbody>
            </table>
          </InfiniteScroll>
        }

          </Fragment>
        );

    }

}
