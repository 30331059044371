import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";

import FoodComponent from '../component/FoodComponent';
import { getVendorFoodList, getFoodVoucherList, getFavouritVoucher } from '../../core/actions/action';
import { foodListAction, foodVoucherListAction } from '../../core/actions';

const mapStateToProps = state => {

    return{
        food_voucher_list:state.Food.food_voucher_list,
        locationData:JSON.parse(localStorage.getItem('locationData')),
        food_list:state.Food.food_list,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getVendorFoodList:(limit, lat, lng, radius, ggCoin, filter) => {
          dispatch(foodListAction(null));
            return getVendorFoodList(limit, lat, lng, radius, ggCoin, filter)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    dispatch(foodListAction(sources.data));
                  }else{
                    dispatch(foodListAction(null));
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                  }
            }).catch((error)=>{
                const sources = error.response;

                 if(sources.status!==401){
                  sources.data.error.map((res)=>{
                    Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                    return res;
                  })
                }else{
                  Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                }
            })
        },

        getFoodVoucherList:(count, brand_name, type, tat, coin_applicable) => {

            return getFoodVoucherList(count, brand_name, type, tat, coin_applicable)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    sources.data.data.map((res)=>{
                      res.color_code = res.color_code.split(',');
                    })
                    dispatch(foodVoucherListAction(sources.data))
                  }else{
                    dispatch(foodVoucherListAction(null))
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                  }
            }).catch((error)=>{
                const sources = error.response;

                 if(sources.status!==401){
                //   sources.data.error.map((res)=>{
                //     Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                //     return res;
                //   })
                // }else{
                  Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                }
            })
        },

        getFavouritVoucher:(id)=>{
          return getFavouritVoucher (id)
        }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FoodComponent);
