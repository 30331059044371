import React, {Component} from 'react';
// import { Card,  } from "@blueprintjs/core";


export default class NoGallary extends Component{

    render(){

        return(
  			<div className='border p-4'>
  				<center className='mb-5 mt-5 '>
    				<img src={require("./images/Image_not_available.svg")} alt=''/>
    				{this.props.content?
    					this.props.content
    					:
    					<p className='mt-3 mb-2'>There is no photo available for this vendor.</p>
    				}
  				</center>
  			</div>
        );

    }

}
