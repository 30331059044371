import React, {Component, Fragment} from 'react';
import { Checkbox, Card, Button } from "@blueprintjs/core";
import {Link} from 'react-router-dom';
import _ from 'lodash';
import InfiniteScroll from "react-infinite-scroll-component";
import StarRatings from 'react-star-ratings';
import { Async } from 'react-select';
import InputRange from 'react-input-range';
import { LocationOnRounded, PhoneAndroidRounded } from '@material-ui/icons';
import {Intent, Position, Toaster} from "@blueprintjs/core";
import { PageTitle, Loader, Auth } from 'gg-react-utilities';
import {getString} from "../../core/utils/helper";
import Favorite from "../../core/containers/vendorFavouriteContainer";
import TopBar from "../../core/components/topBar";
import NoVendor from "../../core/components/emptyStates/noVendor";
import NoLocation from '../../core/components/emptyStates/noLocation';
import Loading from "./loading";

export default class VendorComponent extends Component {

    constructor(props){
        super(props);
        this.state={
          value:{
            min: 0,
            max: 20,
          },
          name:'',
          limit:10,
          category:[],
          filterCategory:[],
          filter:'',
          isEnabled:false,
          searchValue:''
        }
        this.handleStopsChange = this.handleStopsChange.bind(this);
        this.fetchMoreData = this.fetchMoreData.bind(this);
    }

    componentDidMount(){
      if(this.props.locationData!==null){
        this.props.getVendorList(this.state.limit,this.props.locationData.lat,this.props.locationData.lng,this.state.value.min+'-'+this.state.value.max,this.state.isEnabled,this.state.filterCategory,this.state.filter);
      }
    }

    componentWillReceiveProps(nextProps){

    }

    getCategory = (input, callback) => {
        if (input.length <= 2) {
            callback(null, {
                options: [],
                complete: true
            });
            return Promise.resolve({ options: [] });
        }

        this.props.getCategoryList(input).then((res) => {

          const sources = res.data;

          if (sources.status === true) {
            return sources.data;
          }else{
            alert(sources.message)
          }

          callback(null, {
              options: [],
              complete: true
          });
          return Promise.resolve({ options: [] });

        }).then((json) => {
          callback(null, {
              options: json,
              complete: true
          })
        }).catch((error) => {
          Toaster.create({position: Position.TOP}).show({message:error.response.data.error[0],intent: Intent.DANGER});
            callback(null, {
                options: [],
                complete: true
            });
            return Promise.resolve({ options: [] });
        });
    }

    filterData(){

      let category = [];

      this.state.category.map((res)=>{
        category.push(res.category_id);
        return res;
      })

      this.setState({filterCategory:category},()=>{
        this.props.getVendorList(this.state.limit,this.props.locationData.lat,this.props.locationData.lng,this.state.value.min+'-'+this.state.value.max,this.state.isEnabled,this.state.filterCategory,this.state.filter);
      })

    }

    resetFilter(){
      let obj = {
        min:0,
        max:20
      }
      this.setState({isEnabled:'',filterCategory:[],category:[],value:obj},()=>{
        this.props.getVendorList(this.state.limit,this.props.locationData.lat,this.props.locationData.lng,this.state.value.min+'-'+this.state.value.max,this.state.isEnabled,this.state.filterCategory,this.state.filter);
      })
    }

    handleStopsChange=(category)=>{
       this.setState({
           // filterStatus:true,
           category
       });
   }

   fetchMoreData(){
     if(this.props.vendorList!==null){
       if(this.props.vendorList.totalRecords>=this.props.vendorList.data.length+1){
         this.setState({limit:this.state.limit+10}, () => {
           this.props.getVendorList(this.state.limit,this.props.locationData.lat,this.props.locationData.lng,this.state.value.min+'-'+this.state.value.max,this.state.isEnabled,this.state.filterCategory,this.state.filter);
         })
       }
     }
   }

    render() {
      let metaData = document.getElementsByTagName("meta");
      metaData['description'].content = 'Global Garner -Search out local stores of your near by area at global garner and get good cashback deals';
      metaData['keywords'].content = 'vendor, Local Vendor, Local vending services, business vendor, vendor nearby , local stores, local stores cashback';
      let category = _.debounce((input, callback) => { this.getCategory(input, callback) }, 100);
      const {vendorList} = this.props;
        return (
            <Fragment>
              <div className="container">
                  <TopBar title={'Nearby vendors'} backClick={this.props.back}
                    breadcrumbs={[
                    {label:'Vendors',to:'/vendor'},
                  ]}/>
                <PageTitle title={'Local Vendor | search near by vendor | cashback | Global Garner '} />
                  {this.props.locationData===null?
                    <div className='mb-5 mt-3'>
                      <NoLocation/>
                    </div>
                  :

                    <div className="row mt-4 mt-s-1">
        						<div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
        							<div className="vendor-sidebar">
        								<div className='rounded-0 border p-3'>
        									<div className="">
        										<div className="row">
        											<div className="col-xl-12">
        												<h3 className="text-title">By GG-Coin </h3>
        											</div>
        											<div className="col-xl-12">
        												<div className="form-group form-check">
        													<label className="form-check-label">
        														<Checkbox checked={this.state.isEnabled} label="GG-Coin Applicable" onChange={(e)=>{
        														   this.setState({isEnabled:!this.state.isEnabled})
        														}} />
        													</label>
        												</div>
        										</div>
        										</div>
        										<hr/>
        										<div className="row">
        											<div className="col-xl-12">
        												<h5 className="text-title">Radius</h5>
        											</div>
        												<div className="col-xl-12 px-4">
        													<br/>
        													<InputRange
        													minValue={0}
        													maxValue={100}
        													formatLabel={value => `${value} km` }
        													value={this.state.value}
        													onChange={value => this.setState({ value: value })} />
        													<br/>
        													<br/>
        												</div>
        											</div>
        										<hr/>
        										<div className="row">
        											<div className="col-xl-12">
        												<h5 className="text-title">Category</h5>
        											</div>
        											<div className="col-xl-12">
        												<Async
        													clearable={false}
        													multi={true}
        													value={this.state.category}
        													isLoading={true}
        													onChange={(value) => {
                                    this.setState({
                                      category: value
                                    })

        													}}
        													valueKey="category_id"
        													labelKey="name"
        													Key="category_id"
        													loadOptions={(input, callback) => category(input, callback)}
        													placeholder={'Select category'}
        													searchPromptText={"Please enter 3 or More characters"}
        													backspaceRemoves={true}
        												/>
        												<br/>
        												<br/>
                              <Button className='bp3-button bp3-secondary bp3-small pull-left' text='Reset Filter'
                                  onClick={()=>{
                                    this.resetFilter()
                                  }}/>
        												<Button className='bp3-button bp3-intent-primary bp3-small pull-right' text='Apply Filter'
          												onClick={()=>{
          													this.filterData()
          												}}/>
        												<br/>
        												<br/>
        												<br/>
        												<br/>
        												<br/>
        												<br/>
        												<br/>
        												<br/>
        												<br/>
        												<br/>
        											</div>
        										</div>
        									</div>
        								</div>
        							</div>
        						</div>
        						<div className="col-xl-9 col-lg-9 col-md-8 col-sm-8 col-12 mt-s-4 scroll-container">
        							<div className="bp3-input-group">
        								<input type="text" className="bp3-input" placeholder="Search vendor" value={this.state.filter}
                        onChange={(e)=>{
                          this.setState({filter:e.target.value},()=>{
                            if(this.state.filter.trim()!==''){
                              this.props.getVendorList(this.state.limit,this.props.locationData.lat,this.props.locationData.lng,this.state.value.min+'-'+this.state.value.max,this.state.isEnabled,this.state.filterCategory,this.state.filter);
                            };
                          })
                        }}/>

        								</div>
        								{vendorList===null ?
                          <Loading />
                        :vendorList.data.length === 0 ?
        								<div className='mt-4'>
        									<NoVendor/>
        								</div>
        								:
        								<InfiniteScroll
        									dataLength={vendorList.data.length}
        									next={this.fetchMoreData}
        									hasMore={true}
        									loader={vendorList.totalRecords>=vendorList.data.length+1 && vendorList.filteredRecords>=vendorList.data.length+1?<div className='mt-1'><Loader/></div>:null}
        								>
        								<ul className="vendor-list mr-0">
        									{vendorList.data.map((vendor) => {
        										return(
        											<li key={vendor.vendor_id}>
                                <Card className='bp3-elevation-1 border rounded-0 vendor-box'>
          													<div className="row">
          														<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 mt-s-2">
          															<center>
          																<Link to={'/vendor/'+vendor.vendor_slug}>
          																<img src={vendor.display_pic===null||vendor.display_pic===''?process.env.REACT_APP_DEFAULT_IMAGE:vendor.display_pic}
          																	alt="place"
          																	className="image border"
          																	onError={(ev)=>{
          																	ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
          																	}}/>
          																</Link>
          															</center>
          														</div>
          														<div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12 pl-xl-4 pl-lg-4 pl-md-3 pl-sm-2 pl-3 mt-s-3">
          															<div className='pull-right'>
                                          {!Auth.isLoggedIn()?
                                            <Favorite show={false} vendor_slug={{"vendor_slug":vendor.vendor_slug}}/>
                                            :
                                            <Favorite show={vendor.is_favorite===0?false:true} vendor_slug={{"vendor_slug":vendor.vendor_slug}}/>
                                          }
          																</div>
          																<Link to={'/vendor/'+vendor.vendor_slug}>
          																	<p className='title'>{vendor.company_name}</p>
          																</Link>
          																<div className='row'>
          																<div className='col-xl-3 col-lg-3 col-md-5 col-sm-5 col-5 d-flex justify-content-start align-items-center pr-s-0 text-nowrap'>
          																	<span className="review mr-2">{vendor.avg_rating===null?0:vendor.avg_rating.toFixed(2)}</span>
          																	<StarRatings
          																	rating={vendor.avg_rating===null?0:vendor.avg_rating}
          																	starRatedColor="#F5A623"
          																	starDimension='10px'
          																	numberOfStars={5}
          																	name='rating'
          																	/>
          																</div>
          																{vendor.gg_coin_accept === 1 ?
          																	<div className='col-xl-9 col-lg-9 col-md-7 col-sm-7 col-7 d-flex justify-content-start align-items-center pl-s-0'>
          																		<img src={process.env.REACT_APP_GG_COIN_IMAGE} alt="gg_coins" className='gg-coin-img'/>
          																		<p className="gg-coin">GG Coins Applicable</p>
          																	</div>
          																:null}
          															</div>
                                        <div className="vendor-text mb-2 mt-2">
                                          {vendor.vendor_description}
                                        </div>
          															<div className="vendor-text mb-3 mt-2">
                                          Categories: {vendor.vendor_categories.map(function (key) {
                                            return key.category_name
                                          }).join(', ')}
          															</div>
          															<div className="vendor-text">
          																{vendor.vendor_contact_person.length>0 ?
          																<p className="d-flex mb-1"><PhoneAndroidRounded/>&nbsp;&nbsp;&nbsp;{vendor.vendor_contact_person[0].mobile}</p>
          																: <p className="d-flex"><PhoneAndroidRounded/>&nbsp;&nbsp;&nbsp; -</p>}
          																</div>
          																<div className="vendor-address mb-s-2">
          																	<LocationOnRounded />&nbsp;&nbsp;
                                            {getString([vendor.street1,vendor.street2,vendor.landmark,
                                              vendor.area, vendor.state],vendor.pincode)}
          															</div>
          														</div>
          													</div>
                                </Card>
        											</li>
        										);
        										})
        									}
        								</ul>
        							</InfiniteScroll>
        							}
        						</div>
                    </div>
                  }
                  <br/>
                  <br/>
                  <br/>
                  <br/>
              </div>
            </Fragment>
          )
        }
    }
