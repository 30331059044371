import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";

import VendorDetailComponent from '../component/VendorDetailComponent';
import { getVendorDetail, getvendorProductList, getVendorServiceList, getMagentoStoreUrl} from '../../core/actions/action';
import { vendorDetailAction, vendorProductListAction, vendorServiceListAction } from '../../core/actions';


const mapStateToProps = state => {

    return{
      vendorDetails:state.VendorDetail.vendorDetails,
      vendorProductList:state.VendorDetail.vendorProductList,
      vendorServiceList:state.VendorDetail.vendorServiceList
    }
}

const mapDispatchToProps = dispatch => {

    return {
      getVendorDetail:(slug) => {
        dispatch(vendorDetailAction(null));
          return getVendorDetail(slug)
          .then((res)=>{
              const sources = res.data;
              if(sources.status===true){
                  dispatch(vendorDetailAction(sources.data));
                  // dispatch(vendorDetailAction(null));
                }else{
                  dispatch(vendorDetailAction(null));
                  Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                }
          }).catch((error)=>{
              const sources = error.response;
              if(sources.status!==401){
                dispatch(vendorDetailAction(sources.data));
                sources.data.error.map((res)=>{
                  Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                  return res;
                })
              }else{
                Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
              }
          })
      },

      getvendorProductList:(slug,limit) => {
        // dispatch(vendorProductListAction(null));
          return getvendorProductList(slug,limit)
          .then((res)=>{
              const sources = res.data;
              if(sources.status===true){
                  dispatch(vendorProductListAction(sources.data));
                  // dispatch(vendorProductListAction(null));
                }else{
                  dispatch(vendorProductListAction(null));
                  Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                }
          }).catch((error)=>{
              const sources = error.response;
              if(sources.status!==401){
                sources.data.error.map((res)=>{
                  Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                  return res;
                })
              }else{
                Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
              }
          })
      },

      getVendorServiceList:(slug,limit) => {
        // dispatch(vendorServiceListAction(null));
          return getVendorServiceList(slug,limit)
          .then((res)=>{
              const sources = res.data;
              if(sources.status===true){
                  dispatch(vendorServiceListAction(sources.data));
                  // dispatch(vendorServiceListAction(null));
                }else{
                  dispatch(vendorServiceListAction(null));
                  Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                }
          }).catch((error)=>{
              const sources = error.response;
              if(sources.status!==401){
                sources.data.error.map((res)=>{
                  Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                  return res;
                })
              }else{
                Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
              }
          })
      },

        getMagentoStoreUrl:(vendorSlug) => {
            return getMagentoStoreUrl()
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        window.location.href = sources.data.magentoStoreUrl + '&vendorurl=' + vendorSlug;
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                    }
                }).catch((error)=>{
                    const sources = error.response;
                    if(sources.status!==401){
                        sources.data.error.map((res)=>{
                            Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                        })
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                    }
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorDetailComponent);
