import React, {Component} from 'react';

export default class LoadingComponent extends Component{

    render(){

        return(
            <table className="bp3-html-table table-borderless mt-4">
              <tbody>
                <tr>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "100%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                </tr>
                <tr>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "100%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                </tr>
                <tr>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "100%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                </tr>
                <tr>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "100%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                </tr>
                <tr>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "100%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                </tr>
                <tr>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "100%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                </tr>
                <tr>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "100%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                </tr>
                <tr>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "100%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                </tr>
                <tr>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "100%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                </tr>
              </tbody>
            </table>
        );

    }

}
