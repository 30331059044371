export const getAllUrlParams = (url) => {
    let queryString = url ? url.split('?')[1] : window.location.search.slice(1);
    let obj = {};

    if (queryString) {
        queryString = queryString.split('#')[0];

        let arr = queryString.split('&');

        for (let i = 0; i < arr.length; i++) {
            let a = arr[i].split('=');
            let paramNum = undefined;
            let paramName = a[0].replace(/\[\d*\]/, function (v) {
                paramNum = v.slice(1, -1);
                return '';
            });
            let paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

            paramName = paramName.toLowerCase();
            paramValue = paramValue.toLowerCase();

            if (obj[paramName]) {
                if (typeof obj[paramName] === 'string') {
                    obj[paramName] = [obj[paramName]];
                }
                if (typeof paramNum === 'undefined') {

                    obj[paramName].push(paramValue);
                } else {
                    obj[paramName][paramNum] = paramValue;
                }
            } else {
                obj[paramName] = paramValue;
            }
        }
    }

    return obj;
};


export const getString = (string,pincode) => {

    pincode = pincode!==null && pincode.trim()!==''? ' - '+pincode:' '
    let address = '';
    if(string.length>0 || string!==undefined){
    string.map((res,i)=>{
      let join = string.length-1 === i ? ' ':', '
      if(res!==null && res.trim()!==''){
        address+= res + join
      }
      return true;
    })

  }
  return address+pincode;
}
