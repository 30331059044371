import React, {Component, Fragment} from 'react';
import { Checkbox, Button} from "@blueprintjs/core";
import _ from 'lodash';
import { PageTitle, Loader, Auth, Helper} from 'gg-react-utilities';
import InfiniteScroll from "react-infinite-scroll-component";
import InputRange from 'react-input-range';
import { Async } from 'react-select';
import {Link} from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import Loading from "./loading";
import Favorite from "../../core/containers/productWishlistContainer";
import NoProduct from "../../core/components/emptyStates/noProduct";
import NoLocation from '../../core/components/emptyStates/noLocation';
import TopBar from "../../core/components/topBar";

export default class ProductComponent extends Component {

    constructor(props){
        super(props);
        this.state={
          limit:9,
          value:{
            min: 0,
            max: 20,
          },
          selectedOption:null,
          category:[],
          filterCategory:[],
          filter:'',
          isEnabled:false,
          searchValue:''
        }
        this.fetchMoreData = this.fetchMoreData.bind(this);
    }

    componentDidMount(){
      if(this.props.locationData!==null){
        this.props.getProductList(this.state.limit,this.props.locationData.lat,this.props.locationData.lng,this.state.value.min+'-'+this.state.value.max,this.state.isEnabled,this.state.filterCategory,this.state.filter);
      }
    }

    componentWillReceiveProps(nextProps){

    }

    getCategory = (input, callback) => {
        if (input.length <= 2) {
            callback(null, {
                options: [],
                complete: true
            });
            return Promise.resolve({ options: [] });
        }

        this.props.getCategoryList(input).then((res) => {

          const sources = res.data;
          if (sources.status === true) {

            return sources.data;
          }else{
            alert(sources.message)
          }

          callback(null, {
              options: [],
              complete: true
          });
          return Promise.resolve({ options: [] });

        }).then((json) => {
          callback(null, {
              options: json,
              complete: true
          })
        }).catch((error) => {
            callback(null, {
                options: [],
                complete: true
            });
            return Promise.resolve({ options: [] });
        });
    }

    filterData(){

      let category = [];
      this.state.category.map((res)=>{
        category.push(res.category_id);
        return res;
      })
      this.setState({filterCategory:category},()=>{
        this.props.getProductList(this.state.limit,this.props.locationData.lat,this.props.locationData.lng,this.state.value.min+'-'+this.state.value.max,this.state.isEnabled,this.state.filterCategory,this.state.filter);
      })

    }

    handleStopsChange=(category)=>{
       this.setState({
           // filterStatus:true,
           category
       });
   }

   fetchMoreData(){
     if(this.props.productList!==null){
       if(this.props.productList.totalRecords>=this.props.productList.data.length+1){
         this.setState({limit:this.state.limit+9},()=>{
           this.props.getProductList(this.state.limit,this.props.locationData.lat,this.props.locationData.lng,this.state.value.min+'-'+this.state.value.max,this.state.isEnabled,this.state.filterCategory,this.state.filter);
         });
       }
     }
   }

   resetFilter(){
     let obj = {
       min:0,
       max:20
     }
     this.setState({isEnabled:'',filterCategory:[],category:[],value:obj},()=>{
       this.props.getProductList(this.state.limit,this.props.locationData.lat,this.props.locationData.lng,this.state.value.min+'-'+this.state.value.max,this.state.isEnabled,this.state.filterCategory,this.state.filter);
     })
   }

    render() {
      let metaData = document.getElementsByTagName("meta");
      metaData['description'].content = 'Global Garner -Search out near by product of your area at global garner & avail these service via global garner & get great cashback deals';
      metaData['keywords'].content = 'Product, Local Product, online Product cashback';
      let category = _.debounce((input, callback) => { this.getCategory(input, callback) }, 100);
      const {productList} = this.props;
        return (
            <Fragment>
              <div className="container">
                <TopBar title={"Nearby products"} backClick={this.props.back}
                    breadcrumbs={[
                    {label:'Products',to:'/product'},
                  ]}/>
                <PageTitle title={'Product |Search near by product | Cashback | Global Garner'} />

                {this.props.locationData===null?
                  <div className='mb-5 mt-3'>
                    <NoLocation/>
                  </div>
                  :
                    <div className="row mb-5">
                      <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
                        <div className='product-sidebar'>
                          <div className="rounded-0 border p-3">
                            <div className="row">
                								<div className="col-xl-12">
                									<h5 className="text-title">By GG-Coin </h5>
                								</div>
                								<div className="col-xl-12">
                									<div className="form-group form-check">
                										<label className="form-check-label">
                											<Checkbox checked={this.state.isEnabled} label="GG-Coin Applicable" onChange={(e)=>{
                											this.setState({isEnabled:!this.state.isEnabled})
                											}} />
                										</label>
                									</div>
                								</div>
                              </div>
                            <hr/>
                            <div className="row">
                              <div className="col-xl-12">
                                <h5 className="text-title">Radius</h5>
                              </div>
                              <div className="col-xl-12 px-4">
                                <br/>
                                <InputRange
                                  minValue={0}
                                  maxValue={100}
                                  formatLabel={value => `${value} km`}
                                  value={this.state.value}
                                  onChange={value => this.setState({ value: value })}
                                  />
                                  <br/>
                                  <br/>
                              </div>
                            </div>
                            <hr/>
                            <div className="row">
                              <div className="col-xl-9">
                                <h5 className="text-title">Category</h5>
                              </div>
                              <div className="col-xl-12">
                                <Async
                                    clearable={false}
                                    multi={true}
                                    value={this.state.category}
                                    isLoading={true}
                                    onChange={(value) => {
                                      this.setState({
                                        category: value
                                      })

                                    }}
                                    valueKey="category_id"
                                    labelKey="name"
                                    Key="category_id"
                                    loadOptions={(input, callback) => category(input, callback)}
                                    placeholder={'Select category'}
                                    searchPromptText={"Please enter 3 or More characters"}
                                    backspaceRemoves={true}
                                />
                                <br/>
                                <br/>
                                <Button className='bp3-button bp3-secondary bp3-small pull-left' text='Reset Filter'
                                    onClick={()=>{
                                      this.resetFilter()
                                    }}/>
                                <Button className='bp3-button bp3-intent-primary bp3-small pull-right' text='Apply Filter' onClick={()=>{
                                  this.filterData()
                                }}/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-9 col-lg-9 col-md-8 col-sm-8 col-12 mt-s-4 scroll-container">
                        <div className="bp3-input-group">
            							<input type="text" className="bp3-input" placeholder="Search product" value={this.state.filter}
                            onChange={(e)=>{
                              this.setState({filter:e.target.value},()=>{
                                if(this.state.filter.trim()!==''){
                                  this.props.getProductList(this.state.limit,this.props.locationData.lat,this.props.locationData.lng,this.state.value.min+'-'+this.state.value.max,this.state.isEnabled,this.state.filterCategory,this.state.filter);
                                }
                              })
                            }}

                          />
                        </div>
                        {productList===null?
                          <Loading/>
                        :
                        <InfiniteScroll
                          dataLength={productList.data.length}
                          next={this.fetchMoreData}
                          hasMore={true}
                          loader={productList.totalRecords>=productList.data.length+1 && productList.filteredRecords>=productList.data.length+1?<div className='mt-1 mb-1'><Loader/></div>:null}
                          >
            							<div className="product-list row mb-5 mr-0">
            								{productList.data.length === 0 ?
                              <div className='col-12 col-xl-12 mt-4'>
                                <NoProduct/>
                              </div>
                							:
                              productList.data.map((product)=>{

                              let spclPriceFrom = new Date(product.special_price_from).getTime();
                              let currentTime= new Date().getTime();
                              let spclPriceTo= new Date(product.special_price_to).getTime();
              								return(
              									<div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 pt-1' key={product.product_id}>
              										<div className="polaroid">
              											<Link to={'/product/'+product.slug}>
              											{product.images.length===0?
              												<img src={process.env.REACT_APP_DEFAULT_IMAGE} alt="5 Terre" className="img-fluid"/>
              											:
              												<img src={product.images[0].image} alt="5 Terre" className="img-fluid"
              												onError={(ev)=>{
              													ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
              												}}/>
              											}
              											</Link>
              											<div className="topleft">
              												{product.gg_coin_accept === 1 ?
              												<div className=''>
              													<img src={process.env.REACT_APP_GG_COIN_IMAGE} className='gg-coin-img' alt='gg coin'/>
              												</div>
              												:null}
              												<div className='icon-area'>
              												{!Auth.isLoggedIn()?
              													<Favorite product_id={product.product_id} show={false}/>
              													:
                                        product.wishlist!==undefined?
              													product.wishlist.length!==0 ?
              													<Favorite product_id={product.product_id} show={product.wishlist[0].in_wishlist===1?true:false}/>
              													:
              													<Favorite product_id={product.product_id} show={false}/>
                                          :
                													<Favorite product_id={product.product_id} show={false}/>
              												}
              												</div>
              											</div>
              											<div className="container">
              												<div className='row text-left'>
              													<div className="col-xl-12">
              														<Link to={'/product/'+product.slug}>
              														<p className="activity-product">{product.name}</p>
              														</Link>
              													</div>
              													<div className='col-xl-12 d-flex'>
              														<span className="review mr-2">{product.avg_rating}</span>
              														<StarRatings
              														rating={product.avg_rating}
              														starRatedColor="#F5A623"
              														starDimension='10px'
              														numberOfStars={5}
              														name='rating'
              														/>
              													</div>
              													<h5 className="text-muted col-xl-12 mt-2 mb-2">Brand: {product.brand_name!=='' && product.brand_name!==null?product.brand_name:' - '}</h5>
              													<div className='col-xl-12 d-flex justify-content-between'>
                                          {product.special_price!==null && Number(product.special_price)!==0 &&product.special_price_from!==null&&product.special_price_to!==null?
                                            (spclPriceFrom <= currentTime)&&  (currentTime <= spclPriceTo)?
                                            <p className="text-primary font-weight-bold text-nowrap mb-2">{Helper.formatMoney(product.special_price)}</p>
                                            :
                                            <p className="text-primary font-weight-bold text-nowrap mb-2">{Helper.formatMoney(product.price)}</p>
                                            :
              														  <p className="text-primary font-weight-bold text-nowrap mb-2">{Helper.formatMoney(product.price)}</p>
                                            }
                                            {product.special_price!==null && Number(product.special_price)!==0 &&product.special_price_from!==null&&product.special_price_to!==null?
                                              (spclPriceFrom <= currentTime)&&  (currentTime <= spclPriceTo)?
              														      <p className="text-nowrap mb-2 bp3-text-small"><strike>{Helper.formatMoney(product.product_price)}</strike></p>
                                              :null
                                              :null
                                            }
              													</div>

              												</div>
              											</div>
              										</div>
              									</div>
              								)
            								})}
            							</div>
                        </InfiniteScroll>
                      }
                      </div>
                    </div>
                  }
              </div>
            </Fragment>
          )
        }
    }
