import {connect} from 'react-redux';

import FavoriteVendor from '../components/favoriteVendor';
import { addVendorToWishlist } from '../../core/actions/action';

const mapStateToProps = state => {

    return{

    }
}

const mapDispatchToProps = dispatch => {

    return {
        addVendorToWishlist:(product_id) => {
            return addVendorToWishlist(product_id)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteVendor);
