import React, {Component} from 'react';
import {Classes, Dialog } from "@blueprintjs/core";
import { Button, FormGroup, Intent} from "@blueprintjs/core";
import {Async} from "react-select";
import _ from "lodash";
import LocationSearchInput from "./locationAutocomplete";

export default class BookServiceModal extends Component{
    constructor(props){
        super(props);
        this.state={
            street:"",
            hasStreetError:false,
            hasStreetErrorMsg:"",
            hasStreetBorderClass:"",
            pincode:"",
            hasPincodeError:false,
            hasPincodeErrorMsg:"",
            hasPincodeBorderClass:"",
            state:"",
            hasStateError:false,
            hasStateErrorMsg:"",
            hasStateBorderClass:"",
            area:"",
            hasAreaError:false,
            hasAreaErrorMsg:"",
            hasAreaBorderClass:"",
            landmark:"",
            hasLandmarkError:false,
            hasLandmarkErrorMsg:"",
            hasLandmarkBorderClass:"",
            city:"",
            hasCityError:false,
            hasCityErrorMsg:"",
            hasCityBorderClass:"",
            choice:"HOME",
            isDefault:"0",
            lat:"",
            lng:"",
            placeID:""
        }
        this.handleClose = this.handleClose.bind(this);
        this.onHandleChoice = this.onHandleChoice.bind(this);
        this.onSubmitAddress = this.onSubmitAddress.bind(this);
    }

    handleClose=()=>{
      this.setState({
        isOpen: false
      })
    }

    onHandleChoice(e){
        this.setState({
            choice : e.target.value,
        })
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextprops){

        if(nextprops.isOpen!==undefined){
            this.setState({
                isOpen: nextprops.isOpen
            });
        }

    }

    getPincode = (input, callback) => {
        if (input.length <= 2) {
            callback(null, {
                options: [],
                complete: true
            });
            return Promise.resolve({ options: [] });
        }

        this.props.getPincodeList(input).then((res) => {
          const sources = res.data
            if (sources.status) {
                let pincodeList = [];
                sources.data.map((resp,i) => {
                    pincodeList.push({
                        pincode:resp.pincode
                    })
                    return resp;
                });
                return pincodeList;
            }

            callback(null, {
                options: [],
                complete: true
            });
            return Promise.resolve({ options: [] });

        }).then((json) => {
            callback(null, {
                options: json,
                complete: true
            })
        }).catch((error) => {
            callback(null, {
                options: [],
                complete: true
            });
            return Promise.resolve({ options: [] });
        });
    }


    onSubmitAddress=(e)=>{
        if(!this.state.hasStreetError){
            this.setState({
                hasStreetErrorMsg:"Street Field cannot be empty",
                hasStreetBorderClass:"border-danger"
            })
        }

        if(!this.state.hasPincodeError){
            this.setState({
                hasPincodeErrorMsg:"Pincode Field cannot be empty",
                hasPincodeBorderClass:"border-danger"
            })
        }
        if(!this.state.hasStateError){
            this.setState({
                hasStateErrorMsg:"State Field cannot be empty",
                hasStateBorderClass:"border-danger"
            })
        }
        if(!this.state.hasAreaError){
            this.setState({
                hasAreaErrorMsg:"Location Field cannot be empty",
                hasAreaBorderClass:"border-danger"
            })
        }
        if(!this.state.hasLandmarkError){
            this.setState({
                hasLandmarkErrorMsg:"Landmark Field cannot be empty",
                hasLandmarkBorderClass:"border-danger"
            })
        }
        if(!this.state.hasCityError){
            this.setState({
                hasCityErrorMsg:"City Field cannot be empty",
                hasCityBorderClass:'border-danger'
            })
        }
        if( this.state.hasStreetError &&
            this.state.hasPincodeError &&
            this.state.hasStateError &&
            this.state.hasAreaError &&
            this.state.hasLandmarkError &&
            this.state.hasCityError){

            let obj = {
                "street":this.state.street,
                "landmark":this.state.landmark,
                "area":this.state.area,
                "latitude":this.state.lat,
                "longitude":this.state.lng,
                "places_id":this.state.placeID,
                "city":this.state.city,
                "state":this.state.state,
                "address_type":this.state.choice,
                "is_default":this.state.isDefault,
                "pincode":Number(this.state.pincode.pincode)
            }
            this.props.postAddressDetails(obj);
            setTimeout(()=>{
              this.setState({isOpen:false});
            },200)
            this.props.getUserAddress();
        }else{
            this.setState({
                hasNameBorderClass:'border-danger',
                hasMobileBorderClass:'border-danger',
                hasPincodeBorderClass:'border-danger',
                hasStateBorderClass:'border-danger',
                hasAreaBorderClass:'border-danger',
                hasLandmarkBorderClass:'border-danger',
                hasCityBorderClass:'border-danger'
            });
            console.error("Resolve all errors before submit the form.");
        }

        e.preventDefault();

    }


    render(){
        const {choice} = this.state;
        let pincodeSearch = _.debounce((input, callback) => { this.getPincode(input, callback) }, 100);
        return(
            <Dialog
                isOpen={this.state.isOpen}
      			title={this.props.title}
      			className={this.props.className?this.props.className+" dialogWrapper bp3-dialog-large":''}
            canOutsideClickClose={false}
            canEscapeKeyClose={true}
                onClose={this.handleClose}>
                <div className={Classes.DIALOG_BODY+' add-address'} >
                    <div className="">
                        <form>
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <div className="bp3-form-group .modifier">
                                        <div className="bp3-form-content">
                                            <div className="bp3-input-group">
                                                <input type="text" placeholder="Street" value={this.state.street}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            street: e.target.value,
                                                            hasStreetError:true,
                                                            hasStreetBorderClass:""
                                                        },() => {
                                                            if(this.state.hasStreetError){
                                                                this.setState({
                                                                    hasStreetErrorMsg:""
                                                                });
                                                            }
                                                        });
                                                    }}
                                                    className={this.state.hasStreetBorderClass ? 'bp3-input border-danger':'bp3-input'}
                                                />
                                            </div>
                                            <span className="text-danger pull-left bp3-form-helper-text">{this.state.hasStreetErrorMsg}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-3">
                                    <FormGroup
                                        helperText={this.state.hasPincodeErrorMsg}>
                                        <Async
                                            clearable={false}
                                            multi={false}
                                            value={this.state.pincode}
                                            isLoading={true}
                                            className={this.state.hasPincodeBorderClass ? 'border-danger':''}
                                            onChange={(value) => {
                                                this.setState({
                                                    pincode:value,
                                                    hasPincodeError:true,
                                                    hasPincodeBorderClass:""
                                                },() => {
                                                    if(this.state.hasPincodeError){
                                                        this.setState({
                                                            hasPincodeErrorMsg:""
                                                        });
                                                    }
                                                });
                                            }}
                                            valueKey="pincode"
                                            labelKey="pincode"
                                            Key="pincode"
                                            loadOptions={(input, callback) => pincodeSearch(input, callback)}
                                            placeholder="Search Pincode"
                                            searchPromptText={"Please enter 3 or More characters"}
                                            backspaceRemoves={true}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-3">
                                    <div className="bp3-form-group .modifier">
                                        <div className="bp3-form-content">
                                            <div className="bp3-input-group">
                                                <input type="text" placeholder="State" value={this.state.state}
                                                       onChange={(e) => {
                                                           this.setState({
                                                               state: e.target.value,
                                                               hasStateError:true,
                                                               hasStateBorderClass:""
                                                           },() => {
                                                               if(this.state.hasStateError){
                                                                   this.setState({
                                                                       hasStateErrorMsg:""
                                                                   });
                                                               }
                                                           });
                                                       }}
                                                       className={this.state.hasStateBorderClass ? 'bp3-input border-danger':'bp3-input'}
                                                />
                                            </div>
                                            <span className="text-danger pull-left bp3-form-helper-text">{this.state.hasStateErrorMsg}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mb-4">
                                    <FormGroup
                                        helperText={this.state.hasAreaErrorMsg}>
                                        <LocationSearchInput hasClass={this.state.hasAreaBorderClass ? 'bp3-input border-danger' : 'bp3-input'}
                                             onAddress={(res) =>{
                                                 this.setState({
                                                     area:res,
                                                     hasAreaError:true,
                                                     hasAreaBorderClass:""
                                                 },() => {
                                                     if(this.state.hasAreaError){
                                                         this.setState({
                                                             hasAreaErrorMsg:""
                                                         });
                                                     }
                                                 });
                                             }}
                                             onLat={(lat) => {
                                                 this.setState({
                                                     lat:lat,
                                                     hasAreaError:true,
                                                     hasAreaBorderClass:""
                                                 },() => {
                                                     if(this.state.hasAreaError){
                                                         this.setState({
                                                             hasAreaErrorMsg:""
                                                         });
                                                     }
                                                 });
                                             }}
                                             onLng={(lng) => {
                                                 this.setState({
                                                     lng:lng,
                                                     hasAreaError:true,
                                                     hasAreaBorderClass:""
                                                 },() => {
                                                     if(this.state.hasAreaError){
                                                         this.setState({
                                                             hasAreaErrorMsg:""
                                                         });
                                                     }
                                                 });
                                             }}
                                             onPlaceID={(placeID) => {
                                                 this.setState({
                                                     placeID:placeID,
                                                     hasAreaError:true,
                                                     hasAreaBorderClass:""
                                                 },() => {
                                                     if(this.state.hasAreaError){
                                                         this.setState({
                                                             hasAreaErrorMsg:""
                                                         });
                                                     }
                                                 });
                                             }}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-3">
                                    <div className="bp3-form-group .modifier">
                                        <div className="bp3-form-content">
                                            <div className="bp3-input-group">
                                                <input type="text" placeholder="Landmark" value={this.state.landmark}
                                                       onChange={(e) => {
                                                           this.setState({
                                                               landmark: e.target.value,
                                                               hasLandmarkError:true,
                                                               hasLandmarkBorderClass:""
                                                           },() => {
                                                               if(this.state.hasLandmarkError){
                                                                   this.setState({
                                                                       hasLandmarkErrorMsg:""
                                                                   });
                                                               }
                                                           });
                                                       }}
                                                       className={this.state.hasLandmarkBorderClass ? 'bp3-input border-danger' : 'bp3-input'}
                                                />
                                            </div>
                                            <span className="text-danger pull-left bp3-form-helper-text">{this.state.hasLandmarkErrorMsg}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-3">
                                    <div className="bp3-form-group .modifier">
                                        <div className="bp3-form-content">
                                            <div className="bp3-input-group">
                                                <input type="text" placeholder="City/District" value={this.state.city}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            city: e.target.value,
                                                            hasCityError:true,
                                                            hasCityBorderClass:""
                                                        },() => {
                                                            if(this.state.hasCityError){
                                                                this.setState({
                                                                    hasCityErrorMsg:""
                                                                });
                                                            }
                                                        });
                                                    }}
                                                    className={this.state.hasCityBorderClass ? 'bp3-input border-danger' : 'bp3-input'}
                                                />
                                            </div>
                                            <span className="text-danger pull-left bp3-form-helper-text">{this.state.hasCityErrorMsg}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mt-1">
                                    <div className="bp3-form-group .modifier">
                                        <div className="bp3-form-content">
                                            <h3 className="mt-0 mb-2">Location</h3>
                                            <div className="bp3-input-group" style={{'display':'flex'}}>
                                                <label className="bp3-control bp3-radio">
                                                    <input type="radio" name="choice" value={"HOME"}
                                                           checked={(choice==="HOME") ? "checked" :""}
                                                           onChange={this.onHandleChoice}/>
                                                    <span className="bp3-control-indicator"> </span>Home
                                                </label>
                                                <label className="bp3-control bp3-radio ml-5">
                                                    <input type="radio" name="choice" value={"WORK"}
                                                           checked={(choice==="WORK") ? "checked" :""}
                                                           onChange={this.onHandleChoice}/>
                                                    <span className="bp3-control-indicator"> </span>Office
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={Classes.DIALOG_FOOTER+' mb-4'}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button intent={Intent.PRIMARY} className="mr-2"
                            onClick={this.onSubmitAddress}>Save Address</Button>
                        <Button intent={Intent.NONE} className="bp3-intent- bp3-active"
                            onClick={this.handleClose}>Close</Button>
                    </div>
                </div>
            </Dialog>
        );
    }

}
