import React, {Component} from 'react';
// import { Card } from "@blueprintjs/core";

export default class Description extends Component{

    constructor(props){
        super(props);

        this.state={

        }

    }



    render(){
        return(
  			<div className='border p-4'>
  				{this.props.description!=='' ?
  				<p className='description-detail mt-4 mb-4'>
  					{this.props.description}
  				</p>
  				:
  				<p className='text-center mt-4 mb-4'>
  					-
  				</p>
  				}
  			</div>
        );

    }

}
