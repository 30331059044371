import React, {Component, Fragment} from 'react';
import { Button,Dialog, Classes} from "@blueprintjs/core";
import {Link} from 'react-router-dom';
import {Loader, Helper} from 'gg-react-utilities';
import InfiniteScroll from "react-infinite-scroll-component";
import NoService from "../../../core/components/emptyStates/noService";

export default class Service extends Component{

    constructor(props){
        super(props);

        this.state={
          limit:8,
          isOpen:false,
          terms:''
        }
        this.fetchMoreData = this.fetchMoreData.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount(){
      this.props.getVendorServiceList(this.props.vendorSlug,this.state.limit)
    }

    componentWillReceiveProps(nextProps){

    }

    handleClose=()=>{
      this.setState({
        isOpen: false
      })
    }


    fetchMoreData(){
      if(this.props.vendorServiceList!==null){
        if(this.props.vendorServiceList.totalRecords>=this.props.vendorServiceList.data.length+1){
          this.setState({limit:this.state.limit+8}, () => {
            this.props.getVendorServiceList(this.props.vendorSlug,this.state.limit)
          })
        }
      }
    }

    render(){
      const {vendorServiceList} = this.props;
        return(
          <Fragment>
            {vendorServiceList===null?
              <div className='mt-5 mb-5'><Loader/></div>
              :
              vendorServiceList.data.length === 0 ?
                <div className='col-xl-12 col-12 mb-5'>
                  <NoService/>
                </div>
                :
              <InfiniteScroll
                dataLength={vendorServiceList.data.length}
                next={this.fetchMoreData}
                hasMore={true}
                loader={vendorServiceList.totalRecords>=vendorServiceList.data.length+1?<div className='mt-1'><Loader/></div>:null}
                >
              <div className='vendor-detail-list mb-5 mr-0'>
                <Dialog isOpen={this.state.isOpen}
                   className="bp3-dialog" title={'Terms and conditions'}
                   onClose={this.handleClose}
                   >
                    <div className={Classes.DIALOG_BODY}>
                       <div className="col-12 mb-5">
                          {this.state.terms}
                       </div>
                       <div className="row">
                          <div className="col-6"/>
                           <div className="col-md-6 col-12 pull-right p-s-0 text-right px-0">
                             <Button
                                 onClick={this.handleClose}
                                 className="bp3-button bp3-intent-danger bp3-button bp3-small px-4 rounded"
                                 text="Close"
                             />
                         </div>
                     </div>
                 </div>
                </Dialog>
        				<div className="row scroll-container">
        					{
        					  vendorServiceList.data.map((res,i)=>{
                      let spclPriceFrom = new Date(res.special_price_from).getTime();
                      let currentTime= new Date().getTime();
                      let spclPriceTo= new Date(res.special_price_to).getTime();
        						return(
        						  <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12' key={res.id}>
          							<div className="polaroid">
          							  <Link to={'/service/'+res.slug}>
          								<img src={process.env.REACT_APP_SERVICE_IMAGE} alt="5 Terre" className="service-image img-fluid"
          								  onError={(ev)=>{
          									ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
          								  }}/>
          							  </Link>
          							  <div className="topleft">
          								<div className='' onClick={()=>{this.setState({isOpen:true,terms:res.terms_and_conditions})}}>
          								  <img src={process.env.REACT_APP_INFO_IMAGE} className='gg-coin-img' alt='gg coin'/>
          								</div>
          							  </div>
          							  <div className="container">
          								<div className="service">
          									<Link to={'/service/'+res.slug}>
          									  <h4 className='text-left text-title'><b>{res.title}</b></h4>
          									</Link>
          									<p className='text-left text-muted description'>{res.description}</p>
                            <div className='row no-gutters d-flex align-items-center'>
                              <div className='col-xl-6 col-lg-6 col-sm-6 col-md-6 col-6 mt-2'>
                                {res.special_price!==null && Number(res.special_price)!==0 &&res.special_price_from!==null&&res.special_price_to!==null?
                                (spclPriceFrom <= currentTime)&&  (currentTime <= spclPriceTo)?
                                <p className="text-primary mb-0 text-nowrap bold text-left">{Helper.formatMoney(res.special_price)}</p>
                                :
                                <p className="text-primary mb-0 text-nowrap bold text-left"> {Helper.formatMoney(res.price)}</p>
                                :
                                <p className="text-primary mb-0 text-nowrap bold text-left"> {Helper.formatMoney(res.price)}</p>
                                }
                              </div>
                              <div className='col-xl-6 col-lg-6 col-sm-6 col-md-6 col-6 mt-2'>
                                {res.special_price!==null && Number(res.special_price)!==0 &&res.special_price_from!==null&&res.special_price_to!==null?
                                (spclPriceFrom <= currentTime)&&  (currentTime <= spclPriceTo)?
                                <p className="text-muted mb-0 bp3-text-small text-nowrap text-right"><strike> {Helper.formatMoney(res.price)}</strike></p>
                                :null
                                :null}
                              </div>

                              </div>
          								</div>
          							  </div>
          							</div>
        						  </div>
        						);
        					  })
        					}
        				</div>
              </div>
            </InfiniteScroll>
            }
          </Fragment>

        );
    }
}
