import React, {Component} from 'react';
import {getString} from "../../../core/utils/helper";

export default class OtherInformation extends Component{

    constructor(props){
        super(props);

        this.state={

        }

    }

    componentDidMount(){

    }

    weekDay(dayCount){
      switch (dayCount) {
          case 1:
              return 'Sunday'
          case 2:
              return 'Monday'
          case 3:
              return 'Tuesday'
          case 4:
              return 'Wednesday'
          case 5:
              return 'Thursday'
          case 6:
              return 'Friday'
          case 7:
              return 'Saturday'
          default:
              return ''
      }
    }

    render(){

      const {userData,categories} = this.props;
        if(userData.vendor_contact_person.length===0){
          userData.name='';
          userData.mobile='';
          userData.landline=''
        }else{
          userData.name=userData.vendor_contact_person[0].name;
          userData.mobile=userData.vendor_contact_person[0].mobile;
          userData.landline=userData.vendor_contact_person[0].landline;
        }
        return(
          <div className='other-info-container border mt-5 mb-5 p-3 px-s-2'>
            <div className='info-container'>
              <div className="col-xl-12">

                <p className='text-title mt-2 mb-2'>Product Categories</p>

                <div className="row Detail">
                  <ul>
                    {categories.map((elem)=>{
                      return (
                        <li className="ml-2">{elem.category_name}</li>
                      ) ;
                    })}
                  </ul>
                </div>

              </div>
              <br/>
            </div>
            <div className='border-bottom' />
              <div className='info-container'>
                <div className="col-xl-12">
                  <p className='text-title mt-2 mb-2'>Contact Detail</p>
                  <div className="row Detail">
                    <div className="col-xl-2 col-5">
                      <p className="text-muted text-nowrap">Contact person</p>
                    </div>
                    <div className="col-xl-6 col-7">
                      <p className="title col-xl-6 px-s-0">{userData.name===''?'-':userData.name}</p>
                    </div>
                  </div>
                  <div className="row Detail">
                    <div className="col-xl-2 col-5">
                      <p className="text-muted text-nowrap">Mobile No.</p>
                    </div>
                    <div className="col-xl-6 col-7">
                        <a href={'tel:'+userData.mobile} data-rel="external"
                          className="title col-xl-6 px-s-0">{userData.mobile===''?'-':userData.mobile}</a>
                    </div>
                  </div>
                  <div className="row Detail">
                    <div className="col-xl-2 col-5">
                      <p className="text-muted text-nowrap">Landline</p>
                    </div>
                    <div className="col-xl-6 col-7">
                      <a href={'tel:'+userData.landline} data-rel="external"
                        className="title col-xl-6 px-s-0">{userData.landline===''?'-':userData.landline}</a>
                    </div>
                  </div>
                  <div className="row Detail">
                    <div className="col-xl-2 col-5">
                      <p className="text-muted text-nowrap">E-Mail</p>
                    </div>
                    <div className="col-xl-6 col-7">
                      <a href={'mailto:'+userData.email} data-rel="external" className="title col-xl-6 px-s-0">{userData.email===''?'-':userData.email}</a>
                    </div>
                  </div>
                  <div className="row Detail mt-s-2">
                    <div className="col-xl-2 col-5">
                      <p className="text-muted text-nowrap">Address</p>
                    </div>
                    <div className="col-xl-10 col-7">
                      <p className="title col-xl-12 px-s-0">{getString([userData.street1,userData.street2,userData.landmark,
                        userData.area, userData.state],userData.pincode)}</p>
                    </div>
                  </div>
                </div>
                <br/>
              </div>
              <div className='border-bottom' />
                <div className='info-container'>
                  <div className="col-xl-12">
                    <p className='text-title mt-2 mb-2'>Hours of Operation</p>
                      {this.props.serviceHours.map((hour)=>{
                        if(hour.is_working===1){
                          return(
                            <div className="row Detail ml-0" key={hour.week_day}>
                              <div className="col-xl-2 col-5 m-0 p-0">
                                <p className="text-muted text-nowrap">{this.weekDay(hour.week_day)}</p>
                            </div>
                            <div className="col-xl-6 col-7 m-0 p-0">
                              <p className="title col-xl-6 px-s-0">{hour.open_time} AM - {hour.close_time} PM</p>
                            </div>
                          </div>
                        );
                        }
                      })}
                  </div>
                  <br/>
                </div>
                <div className='border-bottom' />
                  <div className='info-container'>
                    <div className="col-xl-12">
                      <p className='text-title mt-2 mb-2'>Modes of Payments Available at store</p>
                      <ul className='mt-5'>
                        {this.props.payment.length===0?
                          <li>No payment method available</li>
                          :this.props.payment.map((pay,i) => {
                          return(
                            <li key={i}>
                              {pay.payment_mode}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <br/>
                  </div>
			</div>
        );

    }

}
