import React, {Component} from 'react';
import { Card } from "@blueprintjs/core";
import {PageTitle, Auth, Helper} from 'gg-react-utilities';
import { LocationOnRounded, PhoneAndroidRounded, EmailRounded } from '@material-ui/icons';

import { getString} from "../../core/utils/helper";

import Loading from "./loading";
import TopBar from "../../core/components/topBar";
import NoVendor from "../../core/components/emptyStates/noVendor";
import Favorite from "../../core/containers/vendorFavouriteContainer";


export default class ProductComponent extends Component {

    constructor(props){
        super(props);
        this.state={
          viewOrder:false,
          data:null,
          url:null
        }
    }

    componentDidMount(){
      let setURL = Helper.getAllUrlParams();

      if(setURL.lat || setURL.lng){
        this.props.getUposVendorList(Number(setURL.lat),Number(setURL.lng));
      }else if(this.props.locationData!==null){
        this.props.getUposVendorList(this.props.locationData.lat,this.props.locationData.lng);
      }else{
        this.props.getUposVendorList(0,0);
      }
    }

    componentWillReceiveProps(nextProps){

    }

    render() {
      let metaData = document.getElementsByTagName("meta");
      metaData['description'].content = 'Grow your business easily with UPOS and get enormous earning benefits';
      metaData['keywords'].content = "Business, profitable business, profit, occupation , earnings, benefits, revenue , entrepreneur, entrepreneurship, entrepreneur life,  Best Earnings Opportunity, how to earn ";
        return (
            <div className='container-fluid billHistory-container'>
              <div className="container mb-1">
                  <TopBar title={"NearbyUpos"} backClick={this.props.back}
                    breadcrumbs={[
                      {label:'Nearby UPOS Vendors',to:'/nearByUpos'},
                    ]}/>
                  <PageTitle title={'UPOS Vendor | AbSabkaBusinesHaiApnaBusiness | Great Earning Opportunity @ Global Garner'} />
                  <br/>

                  {this.props.upos_vendor_list===null ?
                      <Loading/>
                    :
                        <ul className="vendor-list mb-5 scroll-container">
                          {this.props.upos_vendor_list.data.length===0?
                            <div className='mt-4'>
              								<NoVendor/>
              							</div>
                            :
                            this.props.upos_vendor_list.data.map((vendor)=>{
                            return(
              								<li key={vendor.agent_id}>
              									<Card className='border rounded-0 vendor-box bp3-elevation-1'>
              										<div className="row">
              											<div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-12 text-center-xs">
              												<img src={vendor.avatar===null||vendor.avatar===''?process.env.REACT_APP_DEFAULT_IMAGE:vendor.avatar}
              												alt="place"
              												className="image border"
              												onError={(ev)=>{
              													ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
              												}}/>
              											</div>
              											<div className="col-xl-10 col-lg-10 col-md-9 col-sm-8 col-12 mt-2 px-s-4 px-sm-4 px-md-3 px-lg-3 px-xl-3">
                                      <div className='pull-right'>
                                        {!Auth.isLoggedIn()?
                                          <Favorite show={false} vendor_slug={{'agent_id':vendor.agent_id}}/>
                                          :
                                          <Favorite show={vendor.is_fav===0?false:true} vendor_slug={{'agent_id':vendor.agent_id}}/>
                                        }
        																</div>
              												<p className='title'>{vendor.display_name}</p>
              												<p className='text-primary bp3-text-medium'>({vendor.distance!==undefined?vendor.distance.toFixed(2):'-'} km)</p>
                                      <div className="vendor-text">
                												<a href={'mailto:'+vendor.email} data-rel="external" className="d-flex mb-1"><EmailRounded className="mr-2" />{vendor.email}</a>
                											</div>
                                      <div className="vendor-text">
              													<a href={'tel:'+vendor.mobile} data-rel="external" className="d-flex mb-1"><PhoneAndroidRounded className="mr-2" />{vendor.mobile}</a>
              												</div>
              												<div className="vendor-address mb-s-2">
              													<LocationOnRounded className="mr-2" />
                                      {getString([vendor.street,vendor.landmark ,
                                        vendor.area , vendor.state],vendor.pincode)}

              												</div>
              											</div>
              										</div>
              									</Card>
              								</li>
                            )
                          })}
                        </ul>
                      }

                  <br/>
              </div>
            </div>
          )
        }
    }
