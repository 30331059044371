import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {GgUtils, Helper} from 'gg-react-utilities';
import { Button, Toaster, Intent, Position} from "@blueprintjs/core";
import { ExpandMoreRounded, ExpandLessRounded } from '@material-ui/icons';
import CancelModal from './cancelModal';

export default class CartList extends Component {

    constructor(props){
        super(props);
        this.state={
          priceShowState:false,
          redeemStatus:false,
          isRemove:false,
          gg_coin:props.data.gg_coin_used
        }
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextProps){

    }

    ggCoinRedeem(gg_coin){
      this.setState({redeemStatus:true})
      let obj = {
        "cart_product_id" : this.props.data.cart_product_id,
        "gg_coin" : gg_coin
      }
      this.props.getReddemCoin(obj).then((res)=>{
          const sources = res.data;
          if(sources.status===true){
              this.setState({redeemStatus:false})
              Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
              setTimeout(()=>{
                this.props.getCartList();
              },500)
            }else{
              Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
            }
      }).catch((error)=>{
          const sources = error.response;
            this.setState({redeemStatus:false})
            Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});

      })

    }

    updateCart(obj){
      this.props.getUpdateCart(obj).then((res)=>{
          const sources = res.data;
          if(sources.status===true){
              Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
              GgUtils.cartRefresh()
              setTimeout(()=>{
                this.props.getCartList();
              },500)
            }else{
              Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
            }
      }).catch((error)=>{
          const sources = error.response;

            Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});


      })
    }
    updateItem(type){
      if(type==='decrease'){
        if(this.props.data.quantity<2){
            this.setState({isRemove:true})
        }else{
          let obj = {
            "cart_product_id": this.props.data.cart_product_id,
            "quantity": this.props.data.quantity - 1,
          }
          this.updateCart(obj)

        }
      }else{
        let obj = {
          "cart_product_id": this.props.data.cart_product_id,
          "quantity": this.props.data.quantity + 1,
        }
        this.updateCart(obj)

      }

    }

    render() {
      const {data} =  this.props;
        return (
          <div className='col-xl-12 col-12 mb-4 border p-s-2 p-sm-3 p-md-4 p-lg-4 p-xl-4'>
            <CancelModal

              isOpen={this.state.isRemove}
              title={'Remove Product'}
              handleAction={()=>{
                this.props.productDeleteFromCart(data.cart_product_id).then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                          Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                          setTimeout(()=>{
                            this.props.getCartList();
                          },500)
                      }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                      }
                }).catch((error)=>{
                    const sources = error.response;
                    if(sources.status!==401){
                      sources.data.error.map((res)=>{
                        Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                        return res;
                      })
                    }else{
                      Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                    }
                })

              }}
              className="bp3-dialog bp3-dialog-large p-0 rounded"
            />
            <div className={data.status===0 ? 'disable row': ' row'}>
              <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-center-xs pl-s-2 pr-s-0'>
                <Link to={'/product/'+data.catalog_product.slug}>
                <img src={data.images!==null?data.images.image:process.env.REACT_APP_DEFAULT_IMAGE}
                  alt="place"
                  className="image img-fluid"
                  onError={(ev)=>{
                  ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                  }}/>
                </Link>
              </div>
              <div className='col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 mt-4 m-xl-0 m-lg-0 m-sm-0 m-md-0 pl-s-2 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0 mt-s-2'>
                <div className='row'>
                  <Link className="col-xl-6 col-lg-6 col-md-8 col-sm-8 col-8" to={'/product/'+data.catalog_product.slug}>
                    <p className='title'>{data.name}</p>
                  </Link>
                  <p className='total-price text-primary col-xl-6 col-lg-6 col-md-4 col-sm-4 col-4 text-nowrap text-right pl-s-0'>{Helper.formatMoney(data.quantity_total)}</p>
                </div>
                <div className='row'>
                  <div className='col-xl-4 col-4 mt-2 align-items-center align-self-center'>
                    <p className='price '>{Helper.formatMoney(data.price)}</p>
                  </div>
                  <div className='col-xl-8 col-8 mt-2'>
                    <div className="bp3-control-group mb-2 d-flex align-items-center">
                    <Button className="bp3-button bp3-decrease-small bp3-decrease"
                      icon='minus'
                      onClick={()=>{
                        this.updateItem('decrease')

                      }}>

                    </Button>
                    <div className="bp3-input-group bp3-input-counter-group">
                      <input type="text" className="bp3-input bp3-input-counter bp3-input-counter-small text-center" value={data.quantity}
                      onChange={()=>{}}/>
                    </div>
                    <Button className="bp3-button bp3-increase-small bp3-increase" icon='plus'
                      onClick={()=>{
                        this.updateItem('increase')

                      }}>
                    </Button>
                    </div>
                  </div>
                </div>
                {data.is_coin_applicable ?
                <div className='col-xl-8 col-12 p-0 mt-2'>
                  <div className="bp3-control-group bg-coin-color d-flex align-items-center">
                    <p className={"gg-coins-titles mb-0 mt-2 m-auto"}>GG-Coin</p>
                    <div className="bp3-input-group bp3-input-counter-group m-auto px-0">
                      {data.gg_coin_used===0?
                        <input type="number" className="bp3-input bp3-input-counter bp3-input-counter-small text-center" value={this.state.gg_coin}
                        onChange={(e)=>{

                          if (e.target.value > data.max_coin_applicable) {
                             let message = "You can redeem only " + data.max_coin_applicable+' coins';
                             Toaster.create({
                                 position: Position.TOP,
                             }).show({message: message, intent: Intent.DANGER});
                             e.target.value = 0;
                             return false;

                         }
                          this.setState({gg_coin:e.target.value})
                        }}/>
                      :<input type="number" disabled className="bp3-input bp3-input-counter bp3-input-counter-small text-center"
                        value={this.state.gg_coin} />}
                    </div>
                    {data.gg_coin_used===0?
                      <Button text='Redeem' className="bp3-button bp3-intent-success"
                        loading={this.state.redeemStatus}
                      onClick={()=>{
                        this.ggCoinRedeem(this.state.gg_coin)
                      }}/>
                    :<Button text='Remove' className="bp3-button bp3-intent-danger"
                    loading={this.state.redeemStatus}
                      onClick={()=>{

                      this.ggCoinRedeem(0)
                    }}/>
                  }
                  </div>
                </div>
                :null}
                <h5 className="mt-2 mb-2">Applicable GG Coins: <b>{data.max_coin_applicable.toFixed(2)}</b></h5>
                <div className='price-section'>
                <div className='border-bottom' />
                <div className='row mt-3 align-items-stretch' onClick={() => {
                  this.setState({priceShowState:!this.state.priceShowState})
                }}>
                  <p className='title col-xl-10 col-lg-10 col-md-10 col-sm-9 col-9 text-left'><b>Price Detail</b></p>
                  <p className='title col-xl-2 col-lg-2 col-md-2 col-sm-3 col-3 text-right' >{this.state.priceShowState ? <ExpandLessRounded/> : <ExpandMoreRounded/>}</p>
                </div>
                {this.state.priceShowState ?
                  <Fragment>
                    <div className='row'>
                      <p className='title col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 text-left'>{data.name}</p>
                      <p className='title col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-right text-nowrap'> {Helper.formatMoney(data.quantity_total)}</p>
                    </div>
                    <div className='row mt-1'>
                      <p className='title col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 text-left'>Redeemed GG-Coins</p>
                      <p className='title col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-right text-nowrap'> {Helper.formatMoney(data.gg_coin_used)}</p>
                    </div>
                    <div className='border-bottom' />
                    <div className='row mt-3 mb-2'>
                      <p className='title col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 text-left'><b>Total</b></p>
                    <p className='title col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-right text-nowrap'><b>{Helper.formatMoney(data.total)}</b></p>
                    </div>
                  </Fragment>
                  :
                  null
                }
                </div>
              </div>
            </div>
            <div className='border-bottom' />
            <div className='border-bottom' />
            <div className='row no-gutters mt-3'>
              <div className='col-xl-6'>
                {data.status===0 ?
                  <p className='text-danger bp3-text-medium'>This Product is not available right now.</p>
                :
                  null
                }
              </div>
              <div className='col-xl-6'>
                <Button className='text-danger bp3-minimal bp3-intent-danger pull-right cart-remove-button pull-right bp3-text-small'
                  icon='trash'
                  onClick={()=>{
                  this.setState({isRemove:true})
                }}><b>REMOVE</b></Button>
              </div>
            </div>
          </div>
          )
        }
    }
