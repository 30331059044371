import React, {Component, Fragment} from 'react';
import StarRatings from 'react-star-ratings';
import {Link} from 'react-router-dom';
import {Auth, Loader, Helper} from 'gg-react-utilities';
import InfiniteScroll from "react-infinite-scroll-component";
import Favorite from "../../../core/containers/productWishlistContainer";
import NoProduct from "../../../core/components/emptyStates/noProduct";

export default class Product extends Component{
    constructor(props){
        super(props);
        this.state={
          limit:8
        }
        this.fetchMoreData = this.fetchMoreData.bind(this);
    }

    componentDidMount(){
      this.props.getvendorProductList(this.props.vendorSlug,this.state.limit)
    }

    componentWillReceiveProps(nextProps){


    }

    fetchMoreData(){
      if(this.props.vendorProductList!==null){
        if(this.props.vendorProductList.totalRecords>=this.props.vendorProductList.data.length+1){
          this.setState({limit:this.state.limit+8}, () => {
            this.props.getvendorProductList(this.props.vendorSlug,this.state.limit)
          })
        }
      }
    }

    render(){
      const {vendorProductList} = this.props;
        return(
          <Fragment>
            {vendorProductList===null?
              <div className='mt-5 mb-5'><Loader/></div>
              :
              vendorProductList.data.length===0?
                <div className='col-12 col-xl-12 mb-5'>
                  <NoProduct/>
                </div>
              :
              <InfiniteScroll
                dataLength={vendorProductList.data.length}
                next={this.fetchMoreData}
                hasMore={true}
                loader={vendorProductList.totalRecords>=vendorProductList.data.length+1?<div className='mt-1'><Loader/></div>:null}
                >
                <div className='vendor-detail-list row no-gutters mb-5 mr-0 scroll-container'>
                  {
                vendorProductList.data.map((res)=>{
                  let spclPriceFrom = new Date(res.special_price_from).getTime();
                  let currentTime= new Date().getTime();
                  let spclPriceTo= new Date(res.special_price_to).getTime();

                  return(
                    <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12' key={res.product_id}>
                      <div className="polaroid">
                        <Link to={'/product/'+res.slug}>
                        {res.images.length===0?
                          <img src={process.env.REACT_APP_DEFAULT_IMAGE} alt="product" className="img-fluid product-img"
                            />
                            :
                            <img src={res.images[0].image} alt="product" className="img-fluid product-img"
                              onError={(ev)=>{
                                ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                              }}/>
                            }
                          </Link>
                          <div className="topleft">
                            {res.gg_coin_accept === 1 ?
                              <div className=''>
                                <img src={process.env.REACT_APP_GG_COIN_IMAGE} alt='gg_coins' className='gg-coin-img'/>
                            </div>
                            :null}
                            <div className='icon-area'>
                              {!Auth.isLoggedIn() ?
                                <Favorite product_id={res.product_id} show={false}/>
                                :
                                res.wishlist!==undefined?
                                res.wishlist.length!==0 ?
                                <Favorite product_id={res.product_id} show={res.wishlist[0].in_wishlist===1?true:false}/>
                                :
                                <Favorite product_id={res.product_id} show={false}/>
                                :
                                <Favorite product_id={res.product_id} show={false}/>
                              }

                            </div>
                          </div>
                          <div className="container">
                            <div className='row'>
                              <div className="col-xl-12 text-left">
                                <Link to={'/product/'+res.slug}>
                                  <p className="activity-product">{res.name}</p>
                                </Link>
                              </div>
                              <div className='col-xl-12 d-flex align-items-center'>
                                <span className="review mr-2">{res.avg_rating}</span>
                                <StarRatings
                                  rating={res.avg_rating}
                                  starRatedColor="#F5A623"
                                  starDimension='10px'
                                  numberOfStars={5}
                                  name='rating'
                                  />
                              </div>
                              <div className="col-xl-12 text-left">
                                <sub className="text-muted bp3-text-small">Brand: {res.brand_name!=='' && res.brand_name!==null?res.brand_name:' - '}</sub>
                              </div>
                              <div className="col-xl-12 text-left">
                                <sub className="text-muted bp3-text-small">Category: {res.category_name!==''&& res.category_name!==null?res.category_name:' - '}</sub>
                              </div>
                              <div className="col-sm-6 col-6 text-left mt-3">
                                {res.special_price!==null && Number(res.special_price)!==0 &&res.special_price_from!==null&&res.special_price_to!==null?
                                  (spclPriceFrom <= currentTime)&&  (currentTime <= spclPriceTo)?
                                  <p className="text-primary font-weight-bold text-nowrap mb-1">{Helper.formatMoney(res.special_price)}</p>
                                  :
                                  <p className="text-primary font-weight-bold text-nowrap mb-1">{Helper.formatMoney(res.price)}</p>
                                  :
                                  <p className="text-primary font-weight-bold text-nowrap mb-1">{Helper.formatMoney(res.price)}</p>
                                }
                              </div>
                              <div className="col-sm-6 col-6 text-right mt-3">
                                {res.special_price!==null && Number(res.special_price)!==0 &&res.special_price_from!==null&&res.special_price_to!==null?
                                  (spclPriceFrom <= currentTime)&&  (currentTime <= spclPriceTo)?
                                  <p className="text-muted bp3-text-small text-nowrap"><strike>{Helper.formatMoney(res.price)}</strike></p>
                                  :null
                                  :null
                                }
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </InfiniteScroll>
          }
          </Fragment>
        );

    }

}
