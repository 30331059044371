import React,{ Component } from 'react';
import ProductDetailContainer from './container/ProductDetailContainer';
import {Error} from 'gg-react-utilities';

export default class ProductDetail extends Component{

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1000)
    }

    nextPage(slug){
      this.props.history.push('/product/'+slug);
    }

    render(){
      try {
        return(
          <ProductDetailContainer
            nextPage={(slug)=>{
              this.nextPage(slug);
            }}
            productSlug = {this.props.match.params.product_slug}
            back={this.props.history}
          />
        )
      } catch (e) {
        return(
          <Error />
        )
      }
    }
}
