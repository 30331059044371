import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";
import CartComponent from '../component/CartComponent';
import { getCartList, productDeleteFromCart, getReddemCoin, getUpdateCart } from '../../core/actions/action';
import { cartListAction } from '../../core/actions';

const mapStateToProps = state => {
    return{
        cartList:state.Cart.cartList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
      getCartList:() => {
        dispatch(cartListAction(null));
          return getCartList()
          .then((res)=>{
              const sources = res.data;
              if(sources.status===true){
                    dispatch(cartListAction(sources.data));
                    // dispatch(cartListAction(null));
                }else{
                  let obj = {
                    products:[],
                    gg_coin_balance:0
                  }
                  dispatch(cartListAction(obj));
                  Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                }
          }).catch((error)=>{
              const sources = error.response;
            
              if(sources.status!==401){
                sources.data.error.map((res)=>{
                  Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                  return res;
                })
              }else{
                Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
              }
          })
      },

      productDeleteFromCart:(product_id) => {
          return productDeleteFromCart(product_id)

      },


      getReddemCoin:(obj) => {
          return getReddemCoin(obj)

      },

      getUpdateCart:(obj) => {
          return getUpdateCart(obj)

      },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartComponent);
