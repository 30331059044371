import React, {Component} from 'react';
import { RadioGroup, Radio ,Card ,Tabs, Tab} from "@blueprintjs/core";
import {PageTitle, Auth, NoLogin} from 'gg-react-utilities';

import TrashBills from "./trashBills";
import UploadedBills from "./uploadedBills";
import Loading from './loading';
import TopBar from "../../core/components/topBar";
import Config from '../../Config';

export default class ProductComponent extends Component {

    constructor(props){
        super(props);
        this.state={
          billType:1,
          activeTab:'tab2',
          isLoggedIn:null
        }
    }

    componentWillMount(){
      this.checkAuth();
    }

    componentWillReceiveProps(nextProps){

    }

    changeBillType(e){
      this.setState({billType:Number(e.target.value)})
    }

    handleTabChange = (TabId) => {
        this.setState({activeTab: TabId},()=>{
          if(this.state.activeTab==='tab1'){
            window.location.href = Config.BSW_URL+'bills'
          };
        });
    };

    async checkAuth(){
      setTimeout(()=>{
        Auth.isLoggedInAsync().then((res) => {
          this.setState({isLoggedIn:res})
        });
      },1000)
    }

    render() {
        return (
            <div className='billHistory-container'>
              <div className="container mb-5 px-s-0">
                  <TopBar title={"Bill History"} backClick={this.props.back}
                    breadcrumbs={[
                      {label:'Bill History',to:'/bill-history'},
                    ]}
                  />
                  <PageTitle title={'Bill History | Global Garner'} />
                  <br/>

                  <Card className='border bp3-elevation-1'>
                    <div className='bill-tabs'>
                      <Tabs animate={false} id="bill-tabs" renderActiveTabPanelOnly={true} onChange={this.handleTabChange} selectedTabId={this.state.activeTab}>
                        <Tab className="bd-tab" id="tab1" title="Best Shopping Bill History" panel={''} />
                        <Tab className="bd-tab" id="tab2" title="Store Bill History" panel={
                          <div className="border-type p-md-0 p-sm-0 px-s-3 cb-tab-data">
                            {this.state.isLoggedIn===null?
                              <Loading/>
                              :this.state.isLoggedIn ===true?
                              <div className="bill-history-list mb-5 container px-xl-3 px-lg-3 px-md-3 px-sm-0 px-s-0">
                                <RadioGroup
                                    className="d-inline option-type"
                                    onChange={(e) => this.changeBillType(e)}
                                    name="type"
                                    selectedValue={this.state.billType}
                                >
                                  <Radio label="Uploaded Bill" value={1} />
                                  <Radio label="Trash Bill" value={2} />
                                </RadioGroup>
                                {this.state.billType===1 ?
                                  <UploadedBills data={[]} {...this.props}/>
                                  :
                                  <TrashBills data={[]} {...this.props}/>
                                }

                              </div>
                              :
                              <div className='mt-5 mb-5'>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <NoLogin imageShow={true} homeLink={false} />
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                              </div>
                            }
                          </div>
                          }/>
                      </Tabs>
                    </div>
                  </Card>


                  <br/>
              </div>

            </div>
          )
        }
    }
