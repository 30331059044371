let initialState = {
    food_list:null,
    food_voucher_list:null,
    voucher_category_list:[]
};

const Food = (state = initialState, action) => {

    switch (action.type) {

        case 'FOOD_LIST':
            return {...state, food_list: action.sources}
        case 'FOOD_VOUCHER_LIST':
            return {...state, food_voucher_list: action.sources}
        case 'VOUCHER_CATEGORY_LIST':
          return {...state, voucher_category_list: action.sources}
        default:
            return state
    }
}

export default Food;
