import React, {Component, Fragment} from 'react';
import { Button, Intent, Position, Toaster } from "@blueprintjs/core";
import { FavoriteRounded, FavoriteBorderRounded } from '@material-ui/icons';
import {Auth} from 'gg-react-utilities';

export default class ProductWishlist extends Component{

    constructor(props){
        super(props);

        this.state={
            show: this.props.show
        }
        this.handleToggleClick =  this.handleToggleClick.bind(this);
    }

    handleToggleClick(){
      if(Auth.isLoggedIn()){
        this.props.addProductToWishlist(this.props.product_id).then((res)=>{
            const sources = res.data;
            if(sources.status===true){
                this.setState({
                  show: !this.state.show
                });
                Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
              }else{
                Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});

              }
        }).catch((error)=>{
            const sources = error.response;
            
          Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});

        });

      }else{
        Auth.login(true)
      }
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextProps){

    }

    render(){
        return(
          <Fragment>
            { this.props.type===undefined ?
              this.state.show ?
              <FavoriteRounded className="favorite" onClick={this.handleToggleClick}/>
              :
              <FavoriteBorderRounded style={{'color':'#727272'}} onClick={this.handleToggleClick}/>
              :this.props.type==='button'?
                <Button text={this.state.show?'Remove from wishlist':'Add to wishlist'} className='bp3-button bp3-secondary bp3-large f-16'
                  onClick={this.handleToggleClick}/>
                :null
            }
          </Fragment>
        );

    }

}
