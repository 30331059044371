import React, {Component, Fragment} from 'react';
import {Button, Tab, Tabs} from "@blueprintjs/core";
import {PageTitle, Auth, ssoInstance} from 'gg-react-utilities';

import Product from "./vendorDetailTab/Product";
import Services from "./vendorDetailTab/Services";
import Review from "./vendorDetailTab/Review";
import OtherInformation from "./vendorDetailTab/OtherInformation";
import Gallary from "./vendorDetailTab/Gallary";
import {getString} from "../../core/utils/helper";
import Loading from "./loading";
import ReviewModal from "../../core/components/ReviewModal";

import TopBar from "../../core/components/topBar";
import GoogleMap from "../../core/components/GoogleMap";

import StarRatings from 'react-star-ratings';
import Favorite from "../../core/containers/vendorFavouriteContainer";
import NoVendor from "../../core/components/emptyStates/noVendor";
import { LocationOnRounded, PhoneAndroidRounded, ChatRounded, StarsRounded } from '@material-ui/icons';
import gotoStore from "../images/gotoStore.png";


export default class VendorDetailComponent extends Component {

    constructor(props){
        super(props);
        this.state={
          vendorDetails:null,
          ReviewModal:false,
          mapStatus:false,

        }
    }

    componentDidMount(){
      this.props.getVendorDetail(this.props.vendorSlug)
    }

    componentWillReceiveProps(nextProps){

    }

    magentoStore = () =>{
        this.props.getMagentoStoreUrl(this.props.vendorSlug)
    }

    render() {
      const {vendorDetails} = this.props;
      if(vendorDetails!==null){
        let metaData = document.getElementsByTagName("meta");
        metaData['description'].content = vendorDetails.meta_description;
        metaData['keywords'].content = vendorDetails.meta_keyword;
      };
        return (
            <div className="container">
                {vendorDetails===null ?
                  <Loading/>
                :
                vendorDetails.status===false?
                <div className='col-xl-12 col-12 mb-5 mt-5'>
                  <NoVendor/>
                </div>
                :
                <Fragment>
                  <PageTitle title={vendorDetails.meta_title} />
                  <TopBar title={vendorDetails.company_name} backClick={this.props.back}
                    breadcrumbs={[
                      {label:'Vendor',to:'/vendor'},
                      {label:vendorDetails.company_name,to:'/vendor/'+vendorDetails.vendor_slug},
                    ]}/>
                  <ReviewModal
                    title=''
                    isOpen={this.state.ReviewModal}
                    image={vendorDetails.display_pic}
                    name={vendorDetails.company_name}
                    vendorId={vendorDetails.vendor_id}
                    type='vendor'
                    className="bp3-dialog p-0 rounded review-modal-container"
                    {...this.props}
                  />
                    <div className="row px-s-3 px-sm-4">
                      <div className='p-4 bg-basic col-xl-12 border p-s-2'>
                        <div className="row vendor-detail">
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-12 text-center-xs">
								             <img src={vendorDetails.display_pic!==null?vendorDetails.display_pic:process.env.REACT_APP_DEFAULT_IMAGE} alt="place" className="image"
                                onError={(ev)=>{
                                  ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                                }}/>
                          </div>
                          <div className="col-xl-9 col-lg-9 col-md-9 col-sm-8 col-12 mt-s-3">
                            <div className='pull-right'>
                              {!Auth.isLoggedIn()?
                                <Favorite show={false} vendor_slug={{"vendor_slug":vendorDetails.vendor_slug}}/>
                                :
                                <Favorite show={vendorDetails.is_favorite===0?false:true} vendor_slug={{"vendor_slug":vendorDetails.vendor_slug}}/>
                              }
                            </div>
                            <h2 className='text-title mt-0 pt-0 mb-2'>{vendorDetails.company_name}</h2>
                            <div className='row'>
                              <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 d-flex align-items-center text-nowrap'>
                                <span className="review mr-3">{vendorDetails.avg_rating===null? '0':vendorDetails.avg_rating }</span>
                                <StarRatings
                                  rating={vendorDetails.avg_rating !==null ? vendorDetails.avg_rating:0}
                                  starRatedColor="#F5A623"
                                  starDimension='10px'
                                  numberOfStars={5}
                                  name='rating'
                                />
                              </div>
                              {vendorDetails.gg_coin_accept === 1 ?
                                <div className='col-xl-9 col-lg-9 col-md-8 col-sm-6 col-12 d-flex align-items-center'>
                                  <p className="text-secondary f-flex d-flex align-items-center pl-0 ml-0 f-18 mt-s-2">
                                  <img src={process.env.REACT_APP_GG_COIN_IMAGE} alt="yes" className="mr-2" />
                                  GG Coins Applicable</p>
                                </div>
                              :null}
                            </div>
                            <div className="vendor-detail-text">
                              <p className='vendor-address mt-2'>{vendorDetails.vendor_description}</p>
                              <p className='vendor-address mb-3'>
                                {getString([vendorDetails.street1,vendorDetails.street2,vendorDetails.landmark,
                                  vendorDetails.area, vendorDetails.state],vendorDetails.pincode)}
                              </p>
                                {
                                    vendorDetails.is_store_upgrade == "1" ?
                                        <div className='col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 d-flex pl-0 mb-3'>
                                            <a href="#"
                                               onClick={()=>{
                                                   if (!Auth.isLoggedIn()){
                                                       Auth.login(true);
                                                   }else{
                                                       this.magentoStore();
                                                   }
                                               }}>
                                                <img src={gotoStore} />
                                            </a>
                                        </div>
                                        :''
                                }
                            </div>
                            <div className='row mt-2 vendor-contact'>
		                            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 d-flex' onClick={()=>{
                                  this.setState({mapStatus:!this.state.mapStatus})
                                }}>
                                <LocationOnRounded className='title'/>
                                <p className="text-secondary vendor-find mt-0 text-nowrap">Find on Map</p>
                              </div>

                              <div className='col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 d-flex' >
                                <PhoneAndroidRounded className='title'/>
                                  {vendorDetails.vendor_contact_person.length!==0?
                                    <a href={'tel:'+vendorDetails.vendor_contact_person[0].mobile} data-rel="external"
                                    className="text-secondary vendor-find mt-0">{vendorDetails.vendor_contact_person[0].mobile}</a>:
                                  <p className="text-secondary vendor-find mt-0">-</p>}
                              </div>
                              <div className='col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 d-flex' >
                                <ChatRounded className='title'/>
                                  {vendorDetails.vendor_contact_person.length!==0?
                                    <a href={'https://api.whatsapp.com/send?phone='+vendorDetails.vendor_contact_person[0].mobile+'&text=Hello%20Global%20Garner%20Team'} data-rel="external" target='_blank' rel="noopener noreferrer"
                                      className="text-secondary vendor-find mt-0">Chat now</a>:
                                    <p className="text-secondary vendor-find mt-0">Chat now</p>}
                              </div>
                              <div className='col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 d-flex' onClick={() => {
                                  if (!Auth.isLoggedIn()) {
                                      Auth.login(true)
                                  } else {
                                      this.setState({ReviewModal: true})
                                  }
                              }}>
                                  <StarsRounded className='title'/>
                                  <p className="text-secondary vendor-find mt-0">Add review</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='clearfix'></div>

                      {this.state.mapStatus ?
                        <div className='col-12 ' style={{height:'400px'}}>
                          <div className='row'>
                                <GoogleMap
                                  mapCenter={{lat:Number(vendorDetails.latitude),lng:Number(vendorDetails.longitude)}}/>
                          </div>
                        </div>
                        :
                        null
                      }
                      <div className='clearfix'></div>
                    <div className='vendor-detail-tab mt-2'>
                        <Tabs animate={false} id="vendor-detail-tabs" renderActiveTabPanelOnly={true}>
                        <Tab className="bd-tab" id="tab1" title="Products" panel={<Product {...this.props}/>}/>
                        <Tab className="bd-tab" id="tab2" title="Services" panel={<Services {...this.props}/>}/>
                        <Tab className="bd-tab" id="tab3" title="Reviews & Ratings" panel={<Review review={vendorDetails.vendor_review_ratings}
                            display_pic={vendorDetails.display_pic}
                            company_name={vendorDetails.company_name}
                            vendor_id={vendorDetails.vendor_id}
                            />}/>
                          <Tab className="bd-tab" id="tab4" title="Other Information"
                            panel={<OtherInformation payment={vendorDetails.vendor_offline_payment_methods}
                            serviceHours={vendorDetails.vendor_service_hours}
                            userData={this.props.vendorDetails}
                            categories={vendorDetails.vendor_categories}/>} />
                          <Tab className="bd-tab" id="tab5" title="Gallery" panel={<Gallary gallry={vendorDetails.vendor_gallery}/>}/>
                        </Tabs>
                      </div>
                    </div>
                    </Fragment>
                    }
              </div>
          )
        }
    }
