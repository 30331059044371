let initialState = {
    cartList:null,
    reddemGGCoin:null,
    cartSummary:null,
    shippingAddress:null,
    paymentMethod:null,
    address:null
};

const home = (state = initialState, action) => {

    switch (action.type) {

        case 'CART_LIST':
            return {...state, cartList: action.sources}
        case 'REDDEM_GG_COIN':
            return {...state, reddemGGCoin: action.sources}
        case 'CART_SUMMARY':
            return {...state, cartSummary: action.sources}
        case 'SHIPPING_ADDRESS':
            return {...state, shippingAddress: action.sources}
        case 'PAYMENT_METHOD':
            return {...state, paymentMethod: action.sources}
        case 'POST_ADDRESS':
            return {...state, address: action.sources}
        default:
            return state
    }
}

export default home;
