let initialState = {
    orderList:null,
    cancelOrder:null

};

const orderHistory = (state = initialState, action) => {

    switch (action.type) {

        case 'ORDER_LIST':
            return {...state, orderList: action.sources}
        case 'CANCEL_ORDER':
            return {...state, cancelOrder: action.sources}
        default:
            return state
    }
}

export default orderHistory;
