import React, {Component} from 'react';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';

const style = {
  width: '100%',
  height: '100%'
}

export class MapContainer extends Component {

  render() {
    return (
      <Map
        style={style}
        google={this.props.google}
        initialCenter={this.props.mapCenter}
         zoom={13}>
          <Marker
            position={this.props.mapCenter} />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyAPDJ53fvwujHOA8stIsINFb5wPMyklCjo')
})(MapContainer)
