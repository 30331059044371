import React,{ Component } from 'react';
import Cart from './container/CartContainer';
import {Error} from 'gg-react-utilities';

export default class CartComponent extends Component{

    nextPage(response){
      this.props.history.push('/checkout',{
        query:response
      });
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1000)
    }

    render(){
      try {
        return(
          <Cart
            nextPage={(response)=>{
              this.nextPage(response);
            }}
            back={this.props.history}
          />
        )
      } catch (e) {
        return(
          <Error />
        )
      }
    }
}
