import React, {Component, Fragment} from 'react';
import { Toaster, Intent, Position } from "@blueprintjs/core";
import { FavoriteRounded, FavoriteBorderRounded} from '@material-ui/icons';

export default class Voucher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFavourite: false,
        };


    }

    componentDidMount(){
      if(this.props.brand.is_favorite===1){
        this.setState({isFavourite:true})
      };
    }

    componentWillReceiveProps(nextProps) {

    }

    handleFavoriteToggle(e, brand_id){
      this.props.getFavouritVoucher(brand_id)
            .then((res) => {
                let sources = res.data;
                let message = res.data.message;
                let alertColor = Intent.DANGER;

                if (sources.status === true) {
                    alertColor = Intent.SUCCESS;
                    this.setState({isFavourite: !this.state.isFavourite});
                }

                Toaster.create({
                    position: Position.TOP,
                }).show({message: message, intent: alertColor})

            })
            .catch((errors) => {
                if (errors.response.status !== 401) {
                    let message = "";
                    if (errors.response) {
                        message = errors.response.message ? errors.response.message : "Something went wrong!";
                    } else {
                        message = "Something went wrong!";
                    }
                    Toaster.create({
                        position: Position.TOP,
                    }).show({message: message, intent: Intent.DANGER});
                }
            });
    }

    render() {

      return (
        <Fragment>
        {this.state.isFavourite?
          <FavoriteRounded style={{color: 'white'}} onClick={(e) => {
            this.handleFavoriteToggle(e,this.props.brand.brand_id)
          }}/>
          :
          <FavoriteBorderRounded style={{color: 'white'}} onClick={(e) => {
            this.handleFavoriteToggle(e,this.props.brand.brand_id)
          }}/>
        }
        </Fragment>
      );
    }
}
