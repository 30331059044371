import React, {Component} from 'react';
import { Button } from "@blueprintjs/core";
import StarRatings from 'react-star-ratings';
import {Auth} from 'gg-react-utilities';
import ReviewModal from "../../../core/components/ReviewModal";
import NoReview from "../../../core/components/emptyStates/noReview";


export default class Review extends Component{

    constructor(props){
        super(props);

        this.state={
          ReviewModal:false
        }

    }



    render(){
      return(
        <div className='review-container mb-5 row'>
            <div className='col-12 col-xl-12'>
              <Button text='+ Add Review' className='bp3-button btn-link pull-right mb-3' onClick={()=>{
                  if(!Auth.isLoggedIn()){
                    Auth.login(true)
                  }else{
                    this.setState({ReviewModal:true})
                  }
                }}/>
                <ReviewModal
                  title=''
                  isOpen={this.state.ReviewModal}
                  image={this.props.display_pic}
                  name={this.props.company_name}
                  vendorId={this.props.productId}
                  type='product'
                  className="bp3-dialog p-0 rounded review-modal-container"
                  {...this.props}
                />
            </div>
              {this.props.review.length===0 ?
                <div className='col-12 col-xl-12'>
                  <NoReview/>
                        </div>
                        :
                        this.props.review.map((res,i)=>{
                          return(
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12' key={i}>
                            <div className='card border p-4 p-s-3'>
                              <div className='row'>
                                <div className='col-md-7 col-sm-7 col-7'>
                                  <h4 className='text-title m-0'>{res.user.sso_username!==null?res.user.sso_username:' - '}</h4>
                                </div>
                                <div className='col-md-5 col-sm-5 col-5 text-nowrap d-flex justify-content-end align-items-center'>
                                  <span className="review mr-2">{res.rating}</span>
                                  <StarRatings
                                  rating={Number(res.rating)}
                                  starRatedColor="#F5A623"
                                  starDimension='10px'
                                  numberOfStars={5}
                                  name='rating'
                                  />
                                </div>
                              </div>
                              <p className='text-secondary bp3-text-small mt-2 review-text'>{res.review}</p>
                              <p className='text-secondary bp3-text-small mb-0'>{res.review_date}</p>
                            </div>
                    </div>
                  );
                })
              }

          </div>
      );

    }

}
