import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Helper} from 'gg-react-utilities';
import NoService from "../../core/components/emptyStates/noService";
import ServiceTerms from '../../core/components/serviceTermsModal';

export default class ServiceList extends Component{

    constructor(props){
        super(props);

        this.state={
          isVisible:false,
          terms:''
        }

    }

    discount(spclPrice,price){
      let discount = 0;
      discount = (100-(spclPrice*100)/price);
      return discount.toFixed(2)
    }

    render(){
        const{serviceList} = this.props;
        return(
          <div className='product-list row'>
            <ServiceTerms
              size="lg"
              isOpen={this.state.isVisible}
              terms={this.state.terms}
              title={'Terms & Conditions'}
              className="bp3-dialog bp3-dialog-large p-0 rounded"
            />
            {serviceList.length===0?
              <div className='col-xl-12 col-12 mt-4'>
                <NoService/>
              </div>
              :
              serviceList.map((service) => {
              let spclPriceFrom = new Date(service.special_price_from).getTime();
              let currentTime= new Date().getTime();
              let spclPriceTo= new Date(service.special_price_to).getTime();
              return(
                <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2' key={service.id}>
                  <div className="product-card">
                    <div className="image">
                      <Link to={'/service/'+service.slug}>
                        <img src={process.env.REACT_APP_SERVICE_IMAGE} alt="5 Terre" className="img-fluid" />
                      </Link>
                    </div>
                    <div className="product-sticker">
                      <img src={process.env.REACT_APP_INFO_IMAGE} className='gg-coin-img' alt='info'
                        onClick={()=>{this.setState({isVisible:true,terms:service.terms_and_conditions})}}/>
                    </div>
                    <div className="products">
                      <div className='row'>
                        <div className="col-xl-12 col-12 text-left">
                          <Link to={'/service/'+service.slug}>
                            <h3 className="product-name">{service.title}</h3>
                          </Link>
                          <p className="product-description">{service.description}</p>
                            <div className='row'>
                              <div className='col-xl-6 col-lg-6 col-sm-6 col-md-6 col-6'>
                                {service.special_price!==null && Number(service.special_price)!==0 &&service.special_price_from!==null&&service.special_price_to!==null?
                                  (spclPriceFrom <= currentTime)&&  (currentTime <= spclPriceTo)?
                                  <p className="product-service-price">{Helper.formatMoney(service.special_price)}</p>
                                  :
                                  <p className="product-service-price">{Helper.formatMoney(service.price)}</p>
                                  :
                                  <p className="product-service-price">{Helper.formatMoney(service.price)}</p>
                                }
                              </div>
                              <div className='col-xl-6 col-lg-6 col-sm-6 col-md-6 col-6'>
                                {service.special_price!==null && Number(service.special_price)!==0 &&service.special_price_from!==null&&service.special_price_to!==null?
                                  (spclPriceFrom <= currentTime)&&  (currentTime <= spclPriceTo)?
                                  <p className="product-service-price text-right text-muted"><strike>{Helper.formatMoney(service.price)}</strike></p>
                                  :null
                                :null}
                              </div>

                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );

    }

}
