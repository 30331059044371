import React, {Component} from 'react';
// import { Card,  } from "@blueprintjs/core";


export default class NoLocation extends Component{

    render(){

        return(
  			<div className='border p-4'>
  				<center className='mb-5 mt-5 '>

  				<img src={require("./images/address.svg")} alt=''/>
  				{this.props.content?
  					this.props.content
  					:
  					<p className=' text-center mt-5 mb-5'>
  					Please select location first to see this details!
  					</p>
  				}
  				</center>
  			</div>
        );

    }

}
