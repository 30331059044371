import React from 'react'
import { Route } from 'react-router-dom'
import {Header, Footer, ScrollTop} from 'gg-react-utilities';

const HomeRoute = ({ component: Component, ...rest }) => (

    <Route {...rest} render={(props) => (
        <div className="store">
            <Header headerType='dark' locationRequired/>
            <Component {...props} />
            <ScrollTop/>
            <Footer />
        </div>
    )}/>
);

export default HomeRoute;
