let initialState = {
    productDetails:null,
    productWishStatus:null,
    productAvailibility:null
};
const productDetail = (state = initialState, action) => {
    switch (action.type) {
        case 'PRODUCT_DETAIL':
            return {...state, productDetails: action.sources}
        case 'PRODUCT_WISH_STATUS':
            return {...state, productWishStatus: action.sources}
        case 'PRODUCT_AVAILABILTY':
            return {...state, productAvailibility: action.sources}
        default:
            return state
    }
}

export default productDetail
