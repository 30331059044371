import React, {Component} from 'react';
import { Card } from "@blueprintjs/core";


export default class LoadingComponent extends Component{

    constructor(props){
        super(props);

        this.state={

        }

    }



    render(){

        return(
          <div className="row mt-4">
            <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <center>
                    <div className="bp3-skeleton" style={{height: "200px"}} />
                    <br/>
                    <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                    <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                    <br/>
                  </center>

              </Card>
            </div>
            <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <center>
                    <div className="bp3-skeleton" style={{height: "200px"}} />
                    <br/>
                    <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                    <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                    <br/>
                  </center>

              </Card>
            </div>
            <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <center>
                    <div className="bp3-skeleton" style={{height: "200px"}} />
                    <br/>
                    <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                    <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                    <br/>
                  </center>

              </Card>
            </div>
            <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <center>
                    <div className="bp3-skeleton" style={{height: "200px"}} />
                    <br/>
                    <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                    <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                    <br/>
                  </center>

              </Card>
            </div>
            <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <center>
                    <div className="bp3-skeleton" style={{height: "200px"}} />
                    <br/>
                    <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                    <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                    <br/>
                  </center>

              </Card>
            </div>
            <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <center>
                    <div className="bp3-skeleton" style={{height: "200px"}} />
                    <br/>
                    <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                    <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                    <br/>
                  </center>

              </Card>
            </div>
            <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <center>
                    <div className="bp3-skeleton" style={{height: "200px"}} />
                    <br/>
                    <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                    <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                    <br/>
                  </center>

              </Card>
            </div>
            <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <center>
                    <div className="bp3-skeleton" style={{height: "200px"}} />
                    <br/>
                    <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                    <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                    <br/>
                  </center>

              </Card>
            </div>
        </div>
        );

    }

}
