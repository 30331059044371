import React, {Component, Fragment} from 'react';
import { Button,Tab, Tabs, Toaster, Intent, Position  } from "@blueprintjs/core";
import {PageTitle, Auth, GgUtils, Helper} from 'gg-react-utilities';
import StarRatings from 'react-star-ratings';

import Description from "./productDetailTab/Description";
import Features from "./productDetailTab/Features";
import Review from "./productDetailTab/Review";
import Brochures from "./productDetailTab/Brochures";

import Loading from "./loading";
import SliderComponent from "../../core/components/SliderComponent";
import NoProduct from "../../core/components/emptyStates/noProduct";
import TopBar from "../../core/components/topBar";
import Favorite from "../../core/containers/productWishlistContainer";

export default class ProductDetailComponent extends Component {
    constructor(props){
        super(props);
        this.state={
          features:null,
          pincode:'',
          spclPriceFrom:'',
          currentTime:new Date().getTime(),
          spclPriceTo:'',
          productOptionIndex:0,
          productAddStatus:false
        }
    }

    componentDidMount(){
      this.props.getProductDetail(this.props.productSlug)
    }

    componentWillReceiveProps(nextProps){
      let obj = {}
      if(nextProps.productDetails!==null){
        this.setState({
          spclPriceFrom:new Date(nextProps.productDetails.special_price_from).getTime(),
          spclPriceTo:new Date(nextProps.productDetails.special_price_to).getTime()
        })
         obj = {
           height:nextProps.productDetails.height,
          type:nextProps.productDetails.type,
          brand:nextProps.productDetails.brand_name,
          model:nextProps.productDetails.name,
          length:nextProps.productDetails.length,
          width: nextProps.productDetails.width,
          weight:nextProps.productDetails.weight,
          attribute:nextProps.productDetails.product_attribute,
          function:nextProps.productDetails.description
        }
      }else{
         obj = {
          height:'',
          type:'',
          brand:'',
          model:'',
          length:'',
          width: '',
          weight:'',
          attribute:[],
          function:''
        }
      }
      this.setState({features:obj})

    }

    addtoCart(){
      if(!Auth.isLoggedIn()){
        Auth.login(true)
      }else{
        this.setState({productAddStatus:true})
        let obj = {
        "product_id": this.props.productDetails.product_id,
        "quantity": 1,
        // "option": 47
        }
        this.props.productAddToCart(obj).then((res)=>{
            const sources = res.data;

            if(sources.status===true){
                GgUtils.cartRefresh();
                let action = {
                    href: "/cart",
                    text: "View Cart"
                }
                this.setState({productAddStatus:false})
                Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS,action});
              }else{
                Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});

              }
        }).catch((error)=>{
            const sources = error.response;
            this.setState({productAddStatus:false})
            Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});

        })
      }
    }


    render() {
        const {productDetails} =  this.props;
        if(productDetails!==null){
          console.log(productDetails);
          let metaData = document.getElementsByTagName("meta");
          metaData['description'].content = productDetails.meta_description;
          metaData['keywords'].content = productDetails.meta_keyword;
        };
        return (
              <div className="container">
                  <br/>
                  {productDetails===null?
                    <Loading />
                  :
                  !productDetails.status?
                  <div className='col-xl-12 col-12 mb-5 mt-5'>
                    <NoProduct/>
                  </div>
                  :
                  <Fragment>
                    <PageTitle title={productDetails.meta_title} />
                    <TopBar title={productDetails.name} backClick={this.props.back}
                      breadcrumbs={[
                        {label:'Product',to:'/product'},
                        {label:productDetails.name,to:'/product/'+productDetails.slug},
                      ]}/>
                    <div className="row productDetail mb-5">
                      <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                        <SliderComponent dataSource={productDetails.images}
                          sliderChangeTimeSecond={5000}/>
                      </div>
                     <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-s-4">
                        <h2 className='title'>{productDetails.name}</h2>
                        <div className='row'>
                          <div className='col-sm-6 col-5 d-flex align-items-center'>
                            <span className="review mr-3">{productDetails.avg_rating}</span>
                            <StarRatings
                              rating={productDetails.avg_rating}
                              starRatedColor="#F5A623"
                              starDimension='20px'
                              numberOfStars={5}
                              name='rating'
                            />
                          </div>
                          <div className='col-xl-6 col-7 row d-flex align-items-center pr-s-0'>
                            {productDetails.gg_coin_accept ?
                              <Fragment>
							  <img src={process.env.REACT_APP_GG_COIN_IMAGE} alt="yes" className="mr-2" />
								<p className="text-secondary available-text mb-0">GG Coins Applicable</p>
                              </Fragment>
                            :
                            null
                          }
                          </div>
                          <div className='col-xl-4 col-lg-4 col-sm-4 col-md-4 col-4 mt-2 '>
                            {productDetails.special_price!==null && Number(productDetails.special_price)!==0 &&productDetails.special_price_from!==null&&productDetails.special_price_to!==null?
                              (this.state.spclPriceFrom <= this.state.currentTime)&&  (this.state.currentTime <= this.state.spclPriceTo)?
                              <p className="product-price">{Helper.formatMoney(productDetails.special_price)}</p>
                              :
                              <p className="product-price">{Helper.formatMoney(productDetails.price)}</p>
                              :
                              <p className="product-price">{Helper.formatMoney(productDetails.price)}</p>
                            }
                          </div>
                          <div className='col-xl-4 col-lg-4 col-sm-4 col-md-4 col-4 mt-2 '>
                            {productDetails.special_price!==null && Number(productDetails.special_price)!==0 &&productDetails.special_price_from!==null&&productDetails.special_price_to!==null?
                              (this.state.spclPriceFrom <= this.state.currentTime)&&  (this.state.currentTime <= this.state.spclPriceTo)?
                                <p className="product-price-strike bp3-text-medium text-right"><strike>{Helper.formatMoney(productDetails.price)}</strike></p>
                                :null
                              :null
                            }
                          </div>

                        </div>
                        {productDetails.in_stock===1?
                          <p className='text-success bp3-text-xlarge'><b>In stock</b></p>
                          :
                          <p className='text-danger mb-3'><b>Out of stock</b></p>
                        }

                        <div className="row Detail">
                          <div className="col-xl-4 col-5">
                            <p className="status">Sold by</p>
                          </div>
                          <div className="col-xl-8 col-7">
                            <p className="value col-xl-12">{productDetails.vendor.company_name===''?'-':productDetails.vendor.company_name}</p>
                          </div>
                        </div>
                        <div className="row Detail">
                          <div className="col-xl-4 col-5 mb-2">
                            <p className="status">Manufactured by</p>
                          </div>
                          <div className="col-xl-8 col-7 mb-2">
                            <p className="value col-xl-12">{productDetails.brand_name===''?'-':productDetails.brand_name}</p>
                          </div>
                        </div>
                        <div className="row Detail">
                          <div className="col-xl-4 col-5">
                            <p className="label">Category</p>
                          </div>
                          <div className="col-xl-8 col-7">
                            <p className="value col-xl-12">{productDetails.category.category_name===''?'-':productDetails.category.category_name}</p>
                          </div>
                        </div>
                        {productDetails.product_options.length>0?
                          <p className='mt-2'>Product Options:</p>
                        :null}
                        <div className="product-options mb-3">
                            {productDetails.product_options.map((res)=>{
                              return(
                                <a className='product-list' key={res.product_id} href={'/product/'+res.slug}>
                                  <img src={res.images.length>0?res.images[0].image:process.env.REACT_APP_DEFAULT_IMAGE} alt='product-option'
                                    className='img-fluid'
                                    onError={(ev)=>{
                                      ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                                    }}/>
                                </a>
                              )
                            })}
                        </div>

						<div className="row">
							<div className="col-sm-6 col-12">
								{!Auth.isLoggedIn()?
									<Favorite product_id={productDetails.product_id} show={false} type='button' className="bp3-fill" />
									:
									productDetails.wishlist!==undefined?
									productDetails.wishlist.length!==0 ?
									<Favorite product_id={productDetails.product_id} show={productDetails.wishlist[0].in_wishlist===1?true:false} type='button' className="bp3-fill" />
									:
									<Favorite product_id={productDetails.product_id} show={false} type='button' className="bp3-fill" />
									:
									<Favorite product_id={productDetails.product_id} show={false} type='button' className="bp3-fill" />
								}
							</div>
							<div className="col-sm-6 col-12">
								{productDetails.in_stock===1?
									<Button text='Add to cart' className='bp3-intent-primary bp3-fill bp3-large f-16 mt-s-4 bp3-fill'
                    loading={this.state.productAddStatus} onClick={()=>{
										this.addtoCart();
								    }}/>
									:
									<Button text='Add to cart' className='bp3-intent-primary bp3-fill bp3-large mt-s-4 f-16 bp3-fill' disabled/>
								}
							</div>
						</div>
                      </div>
                      <div className='product-tab mt-5 col-12'>
                        <Tabs animate={false} id="vendor-detail-tabs" key="horizontal" renderActiveTabPanelOnly={true} vertical={false} className="bd-tabs" >
                          <Tab className="bd-tab" id="tab1" title="Description" panel={<Description description={productDetails.long_description}/>} />
                          <Tab className="bd-tab" id="tab2" title="Features" panel={<Features features={this.state.features}/>}/>
                          <Tab className="bd-tab" id="tab3" title="Review & Ratings" panel={<Review review={productDetails.reviews}
                            image={productDetails.images.length>0?productDetails.images[0].image:process.env.REACT_APP_DEFAULT_IMAGE}
                            name={productDetails.name}
                            productId={productDetails.product_id}/>}/>
                          <Tab className="bd-tab" id="tab4" title="Brochures" panel={<Brochures brochures={productDetails.product_brochure}/>}/>
                        </Tabs>
                      </div>
                    </div>
                  </Fragment>
                  }
                  <br/>
              </div>
          )
        }
    }
