import React, {Component} from 'react';
import {Alert, Intent} from "@blueprintjs/core";

export default class CancelModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isOpen: props.isOpen,
        }
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {

    }

    handleClose=()=>{
      this.setState({
        isOpen: false
      })
    }

    componentWillReceiveProps(nextprops){
      if(nextprops.isOpen!==undefined){
          this.setState({
              isOpen: nextprops.isOpen
          });
      }
    }


    render() {
        return (
          <Alert
            icon={"trash"}
            intent={Intent.DANGER}
            isOpen={this.state.isOpen}
            cancelButtonText="No"
            onCancel={this.handleClose}
            confirmButtonText="Yes"
            onConfirm={this.props.handleAction}
            canOutsideClickClose={false}
            canEscapeKeyClose={true}
            >
          <strong>Are you sure you want to remove this item from cart?</strong>
        </Alert>

        )
      }
}
