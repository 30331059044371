import React,{ Component, Fragment } from 'react';
import { KeyboardArrowUpRounded, KeyboardArrowDownRounded } from '@material-ui/icons';

export default class SliderComponent extends Component{

    constructor(props){
        super(props);

        this.state={
            activeSliderIndex:0
        };

    }

    componentDidMount(){
        // setInterval(()=>{
        //
        //     if(this.props.dataSource.length-1 == this.state.activeSliderIndex){
        //         this.setState({
        //             activeSliderIndex:0
        //         });
        //     }else{
        //         this.setState({
        //             activeSliderIndex:this.state.activeSliderIndex+1
        //         });
        //     }
        // },this.props.sliderChangeTimeSecond)

    }

    currentSliderActive(index){
        this.setState({
            activeSliderIndex:index
        });
    }

    previousSlider(){

        if(this.state.activeSliderIndex === 0){
            this.setState({
                activeSliderIndex:this.props.dataSource.length-1
            });
        }else{
            this.setState({
                activeSliderIndex:this.state.activeSliderIndex-1
            });
        }
    }

    nextSlider(){
        if(this.props.dataSource.length-1 === this.state.activeSliderIndex){
            this.setState({
                activeSliderIndex:0
            });
        }else{
            this.setState({
                activeSliderIndex:this.state.activeSliderIndex+1
            });
        }
    }
    render(){
        return(
            <div className="col-xl-12 p-0">
                <div id="demo" className="carousel slide" data-ride="carousel">
                    <Fragment>
                        <ul className="carousel-indicators">
                            {
                                this.props.dataSource.map((slider,index)=>{

                                    return (
                                        <li key={index} data-target="#demo" onClick={()=>this.currentSliderActive(index)} data-slide-to={index} className={this.state.activeSliderIndex===index?'active':''}></li>
                                    );
                                })
                            }
                        </ul>

                        <div className="carousel-inner">
							<div className="slide-left">
                              <div className="carousel-control-prev" onClick={()=>this.previousSlider()} data-slide="prev">
                                  <span className="carousel-control-prev-icon">
                                    <KeyboardArrowUpRounded className='icon'/>
                                  </span>
                              </div>
                                {
                                    this.props.dataSource.map((slider,index)=>{

                                        return (
                                            <div key={slider.product_image_id} className={this.state.activeSliderIndex===index?'carousel-item active':'carousel-item'}>
                                                <img src={slider.image} alt="sliderImg" className="img-fluid" onClick={()=>{
                                                    this.setState({activeSliderIndex:index})
                                                  }}
                                                  onError={(ev)=>{
                                                    ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                                                  }}/>
                                            </div>
                                        );
                                    })
                                }
                                <div className="carousel-control-next" onClick={()=>this.nextSlider()} data-slide="next">
                                  <span className="carousel-control-next-icon">
                                    <KeyboardArrowDownRounded className='icon'/>
                                  </span>
                                </div>
                            </div>
                            <div className="display-slide-image">
                                <img src={this.props.dataSource.length>0?this.props.dataSource[this.state.activeSliderIndex].image:process.env.REACT_APP_DEFAULT_IMAGE} alt="sliderImg" className='img-fluid'
                                onError={(ev)=>{
                                  ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                                }}/>
                            </div>
                        </div>
                    </Fragment>
                </div>
            </div>
        )
    }
}
