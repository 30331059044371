import React, {Component, Fragment} from 'react';
import { Card, Button} from "@blueprintjs/core";
import {PageTitle, NoLogin, Auth, Helper} from 'gg-react-utilities';

import Loading from "./loading";
import CartList from "./cartList";
import NoCart from "../../core/components/emptyStates/noCart";
import TopBar from "../../core/components/topBar";


export default class CartComponent extends Component {

    constructor(props){
        super(props);
        this.state={
          payProcess:false,
          isLoggedIn:null,

        }
    }

    componentWillMount(){
      this.checkAuth();
    }

    componentWillReceiveProps(nextProps){
      if(nextProps.cartList!==null&&this.state.isLoggedIn===true){
          this.setState({isLoggedIn:true})
      }

    }

    async checkAuth(){
      setTimeout(()=>{
        Auth.isLoggedInAsync().then((res) => {
          this.setState({isLoggedIn:res},()=>{
            if(this.state.isLoggedIn===true){
              this.props.getCartList();
            }
          })
        });
      },1)
    }

    render() {
        const {cartList} = this.props;
        const {isLoggedIn} = this.state;
        return (
            <Fragment>
              <div className="container">
                  <PageTitle title={'Cart | Global Garner'} />
                  {
                    this.state.isLoggedIn ===false?
                    <Card className='bp3-elevation-1 border mt-5 mb-5'>
                      <div className='mt-5 mb-5'>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <NoLogin imageShow={true} homeLink={false} />
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                      </div>
                    </Card>
                    :
                    cartList!==null && this.state.isLoggedIn ===true ?
                    <Fragment>
          						<TopBar title={"Cart"} backClick={this.props.back} rightMenu={
          							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right">
          								<ul className="headerNav">
          									<li className='text-primary'><h2>GG Coins: {Number(cartList.gg_coin_balance).toFixed(2)}</h2></li>
          								</ul>
          							</div>
          						}
                      breadcrumbs={[
                        {label:'Cart',to:'/cart'},
                      ]}/>
          						<div className="row cart-list mb-5">
          							<div className="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-12">
                          {cartList.products.length===0?
                            <div className='col-12 col-xl-12'>
                              <NoCart/>
                            </div>
                            :
                            cartList !== null ?
                            cartList.products.map((res)=>{
                              return(
                                <CartList data={res} key={res.product_id} {...this.props}/>
                              )
                            })
                            :""
                          }
          							</div>
                        {cartList.products.length>0?
            							<div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12">
            								<div className='border bg-basic mb-cart-total'>
            									<div className="col-xl-12 mb-5">
            									<h2 className="title mb-4">Payable Amount Details</h2>
                              {cartList.total_data.map((res,i)=>{
            										if(res.code!=='grand_total'){
            										return(
            											<div className="row align-items-center" key={i}>
            												<div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
            													<h4 className="mt-2 mb-2">{res.title}</h4>
            												</div>
            												<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
            													<h4 className="mt-2 mb-2 pull-right text-nowrap">{res.type} {Helper.formatMoney(res.value)}</h4>
            												</div>
            											</div>
            										)
            										}else {
            										return(
            											<Fragment key={i}>
            											<div className='border-bottom mt-2'/>
            											<div className="row mb-4">
            												<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-5">
            													<h4 className="text-nowrap"><b>{res.title}</b></h4>
            												</div>
            												<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-7 text-right">
            													<h4 className=""><b>{res.type} {Helper.formatMoney(res.value)}</b></h4>
            												</div>
            											</div>
            											</Fragment>
            										)
            										}
            									})}
            									<Button text='Proceed to pay' className='bp3-button bp3-intent-primary pull-right mb-4'
                                loading={this.state.payProcess}
            										onClick={()=>{
                                  this.setState({payProcess:true},()=>{
                                    this.props.nextPage()
                                  })
            										}}/>
            									</div>
            								</div>
            							</div>
                        :null}
          						</div>
                    </Fragment>
                    :
                    <Loading/>
                  }

              </div>
            </Fragment>
          )
        }
    }
