import React, {Component} from 'react';
import { Tab, Tabs} from "@blueprintjs/core";
import {PageTitle} from 'gg-react-utilities';
import TopBar from "../../core/components/topBar";
import FoodVendor from "./tabSection/foodVendor";
import FoodVoucher from "./tabSection/foodVoucher";

export default class FoodComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
          <div className='food-container'>
            <PageTitle title={'GG Food - Global Garner'}/>
              <div className="container mb-5 mt-3">
                <TopBar title={"GG Food"} backClick={this.props.back}
                  breadcrumbs={[
                    {label:'Food',to:'/food'},
                  ]}/>
                    <div className='food-tabs'>
                      <Tabs animate={false} id="food-tabs" renderActiveTabPanelOnly={true} >
                        <Tab className="bd-tab" id="tab1" title="Food Vendor" panel={<FoodVendor {...this.props}/> }/>
                        <Tab className="bd-tab" id="tab2" title="Food Voucher" panel={<FoodVoucher {...this.props} />}/>
                      </Tabs>
                    </div>
                  <br/>
                </div>
              </div>
        )
    }
}
