import {connect} from 'react-redux';

import ProductWishlist from '../components/productWishlist';
import { addProductToWishlist } from '../../core/actions/action';

const mapStateToProps = state => {

    return{

    }
}

const mapDispatchToProps = dispatch => {

    return {
        addProductToWishlist:(product_id) => {
            return addProductToWishlist(product_id)

        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductWishlist);
