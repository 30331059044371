import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";

import ServiceComponent from '../component/ServiceComponent';
import { getServiceList, getCategoryList } from '../../core/actions/action';
import { serviceListAction } from '../../core/actions';

const mapStateToProps = state => {
    return{
        locationData:JSON.parse(localStorage.getItem('locationData')),
        serviceList:state.Home.serviceList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getServiceList:(limit,lat,lng,radius,category,filter) => {
            // dispatch(serviceListAction(null));
            return getServiceList(limit,lat,lng,radius,category,filter)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    // dispatch(serviceListAction(sources.data));
                  }else{
                    dispatch(serviceListAction(null));
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                  }
            }).catch((error)=>{
                const sources = error.response;
                if(sources===undefined){
                  Toaster.create({position: Position.TOP}).show({message:'Something went wrong!',intent: Intent.DANGER});
                }
                else if(sources.status!==401){
                  sources.data.error.map((res)=>{
                    Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                    return res;
                  })
                }else{
                  Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                }
            })
        },
        getCategoryList:(name) => {
            return getCategoryList(name)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceComponent);
