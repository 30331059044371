import React, {Component, Fragment} from 'react';
import { Card } from "@blueprintjs/core";

export default class Features extends Component{

    constructor(props){
        super(props);

        this.state={

        }

    }



    render(){
      const {brochures} = this.props;
        return(
          <Fragment>
            {brochures.length===0 ?
              <div className='col-xl-12 col-12 border'>
                <p className='text-center mt-5 mb-5'>No brochures found!</p>
              </div>
              :
              <div className='row review-container mt-2 mb-2'>
                {brochures.map((res,i)=>{
                  return(
                    <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 pdf-detail' key={res.id}>
                      <Card className='bp3-elevation-1 border'>
                        <center>
                          <img src={require('../../images/pdf.svg')} alt='pdf'
                             className="img-fluid mt-4"/>
                           <p className='name bp3-text-small mb-2 mt-2'>{res.title}</p>
                          <a href={res.brochure_url} target='_blank' rel="noopener noreferrer" className='click'>Click to Download</a>
                        </center>
                      </Card>
                    </div>
                  );
                })}
            </div>
          }
            <br/>
            <br/>
          </Fragment>
        );

    }

}
