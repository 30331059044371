import React,{ Component } from 'react';
import HomeContainer from './container/HomeContainer';
// import {Error} from 'gg-react-utilities';

export default class Home extends Component{

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },500)
    }

    render(){
        return(
          <HomeContainer
            back={this.props.history}
          />
        )
    }
}
