import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";
import ProductDetailComponent from '../component/ProductDetailComponent';
import { getProductDetail,productAddToCart } from '../../core/actions/action';
import { productDetailAction } from '../../core/actions';

const mapStateToProps = state => {

    return{
        locationData:state.Home.locationData,
        productDetails:state.ProductDetail.productDetails,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getProductDetail:(slug) => {
          dispatch(productDetailAction(null));
            return getProductDetail(slug)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    dispatch(productDetailAction(sources.data));
                  }else{
                    dispatch(productDetailAction(null));
                  }
            }).catch((error)=>{
                const sources = error.response;

                if(sources.status!==401){

                  dispatch(productDetailAction(sources.data));
                  sources.data.error.map((res)=>{
                    Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                    return res;
                  })
                }else{
                  Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                }
            })
        },

        productAddToCart:(obj) => {
            return productAddToCart(obj)

        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailComponent);
