let initialState = {
    favourite_list:[]

};

const Favourite = (state = initialState, action) => {

    switch (action.type) {

        case 'FAVOURITE_LIST':
            return {...state, favourite_list: action.sources}
        default:
            return state
    }
}

export default Favourite;
