import React,{ Component } from 'react';
import VendorDetailContainer from './container/VendorDetailContainer';
import {Error} from 'gg-react-utilities';

export default class VendorDetail extends Component{

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1000)
    }

    render(){
      try {
        return(
          <VendorDetailContainer
            vendorSlug={this.props.match.params.vendor_slug}
            back={this.props.history}
          />
        )
      } catch (e) {
        return(
          <Error />
        )
      }
    }
}
