import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import {Auth} from 'gg-react-utilities';
import Favorite from "../../core/containers/vendorFavouriteContainer";
import NoVendor from "../../core/components/emptyStates/noVendor";

export default class VendorList extends Component{

    constructor(props){
        super(props);

        this.state={

        }

    }

    render(){

        return(
          <Fragment>
            <div className='product-list row'>
              {this.props.vendorList.length===0?
                <div className='col-xl-12 col-12 mt-4'>
                  <NoVendor/>
                </div>
                :
                this.props.vendorList.map((vendor) => {
                return(
                  <div className={'col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2 '+vendor.vendor_id} key={vendor.vendor_id}>
                    <div className="product-card">
                      <div className="image">
                        <Link to={'/vendor/'+vendor.vendor_slug}>
                          <img src={vendor.display_pic===null||vendor.display_pic===''?process.env.REACT_APP_DEFAULT_IMAGE:vendor.display_pic} alt="vendor" className="img-fluid"
                            onError={(ev)=>{
                              ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                            }}/>
                          </Link>
                        </div>
                        <div className="product-sticker">
                          {vendor.gg_coin_accept === 1 ?
                            <img src={process.env.REACT_APP_GG_COIN_IMAGE} className='gg-coin-img' alt='gg coin'/>
                          :null}
                          <div className='icon-area'>
                            {!Auth.isLoggedIn()?
                              <Favorite show={false} vendor_slug={{"vendor_slug":vendor.vendor_slug}}/>
                              :
                              <Favorite show={vendor.is_favorite===0?false:true} vendor_slug={{"vendor_slug":vendor.vendor_slug}}/>
                            }
                          </div>
                        </div>
                        <div className="products">
                          <div className='row'>
                            <div className="col-xl-8 col-8 text-left">
                              <Link to={'/vendor/'+vendor.vendor_slug}>
                                <h3 className="product-name">{vendor.company_name}</h3>
                              </Link>
                            </div>
                            <div className="col-xl-4 col-4 text-right">
                              <h4 className="product-distance">({vendor.distance} km)</h4>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-xl-4 col-4">
                              <StarRatings
                                rating={vendor.avg_rating===null?0:vendor.avg_rating}
                                starRatedColor="#F5A623"
                                starDimension='10px'
                                numberOfStars={5}
                                name='rating'
                                />
                            </div>
                            <div className="col-xl-8 col-lg-8 col-xl-8 col-8 text-right">
                              <p className="product-address">{vendor.area}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                );
              })}
            </div>

          </Fragment>
        );
    }
}
