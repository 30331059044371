import React, {Component, Fragment} from 'react';
// import { Card } from "@blueprintjs/core";


export default class Features extends Component{

    constructor(props){
        super(props);

        this.state={

        }

    }



    render(){
      const {features} = this.props;
        return(
          <div className='other-info-container border p-4'>
            {features.type!==''?
              <div className='info-container'>
                <div className="col-xl-12 p-0">
                  {features.attribute.map((res)=>{
                    return(
                      <div className="row Detail" key={res.attr_id}>
                        <div className="col-xl-2 col-5">
                          <p className="label">{res.attribute_set.attribute_set_name!==null?res.attribute_set.attribute_set_name:' - '}</p>
                        </div>
                        <div className="col-xl-6 col-7">
                          <p className="value col-xl-6">{res.text===''?'-':res.text}</p>
                        </div>
                      </div>
                    );
                  })}
                  {features.type!=='virtual'?
                    <Fragment>
                      <div className="row Detail">
                        <div className="col-xl-2 col-5">
                          <p className="label">Height</p>
                        </div>
                        <div className="col-xl-6 col-7">
                          <p className="value col-xl-6">{features.height}</p>
                        </div>
                      </div>
                      <div className="row Detail">
                        <div className="col-xl-2 col-5">
                          <p className="label">Width</p>
                        </div>
                        <div className="col-xl-6 col-7">
                          <p className="value col-xl-6">{features.width}</p>
                        </div>
                      </div>
                      <div className="row Detail">
                        <div className="col-xl-2 col-5">
                          <p className="label">Length</p>
                        </div>
                        <div className="col-xl-6 col-7">
                          <p className="value col-xl-6">{features.length}</p>
                        </div>
                      </div>
                      <div className="row Detail">
                        <div className="col-xl-2 col-5">
                          <p className="label">Weight</p>
                        </div>
                        <div className="col-xl-6 col-7">
                          <p className="value col-xl-6">{features.weight}</p>
                        </div>
                      </div>
                    </Fragment>
                    :
                    null
                  }

                </div>
                <br/>
              </div>
              :
              null
            }
          </div>
        );

    }

}
