// import React from 'react';
import {combineReducers} from 'redux';
import Home from './Home';
import userInfo from './userDetails';
import VendorDetail from './vendorDetail';
import ProductDetail from './productDetail';
import ServiceDetail from './serviceDetail';
import BillHistory from './billHistory';
import Cart from './cart';
import Favourite from './favourite';
import Wishlist from './wishlist';
import OrderHistory from './orderHistory';
import Food from './food';
import UposVendor from './uposVendor';

const rootReducers = combineReducers({
    Home,
    userInfo,
    VendorDetail,
    ProductDetail,
    ServiceDetail,
    BillHistory,
    Cart,
    Favourite,
    Wishlist,
    OrderHistory,
    Food,
    UposVendor,
});

export default rootReducers;
