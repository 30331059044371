import React, {Component, Fragment} from 'react';
import { FavoriteRounded, FavoriteBorderRounded } from '@material-ui/icons';
import { Intent, Position, Toaster } from "@blueprintjs/core";
import {Auth} from 'gg-react-utilities';


export default class FavoriteVendor extends Component{

    constructor(props){
        super(props);

        this.state={
            show: this.props.show
        }
        this.handleToggleClick =  this.handleToggleClick.bind(this);
    }

    handleToggleClick=()=>{
      if(Auth.isLoggedIn()){
        this.props.addVendorToWishlist(this.props.vendor_slug).then((res)=>{
            const sources = res.data;
            if(sources.status===true){
                this.setState({
                  show: !this.state.show
                });
                Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
              }else{
                Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});

              }
        }).catch((error)=>{
            const sources = error.response;
            Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});


        });
      }else{
        Auth.login(true)
      }

    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextProps){

    }

    render(){
        return(
          <Fragment>
            {this.state.show ?
              <FavoriteRounded className="favorite" onClick={this.handleToggleClick}/>
              :
              <FavoriteBorderRounded style={{'color':'#727272'}} onClick={this.handleToggleClick}/>
            }
          </Fragment>
        );

    }

}
