let initialState = {
    vendorDetails:null,
    vendorWishStatus:null,
    vendorProductList:null,
    vendorServiceList:null
};

const vendorDetail = (state = initialState, action) => {
    switch (action.type) {
        case 'VENDOR_DETAIL':
            return {...state, vendorDetails: action.sources}
        case 'VENDOR_WISH_STATUS':
            return {...state, vendorWishStatus: action.sources}
        case 'VENDOR_PRODUCT_LIST':
            return {...state, vendorProductList: action.sources}
        case 'VENDOR_SERVICE_LIST':
            return {...state, vendorServiceList: action.sources}
        default:
            return state
    }
}

export default vendorDetail
