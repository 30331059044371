import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";

import HomeComponent from '../component/HomeComponent';
import { getVendorList, getProductList, getServiceList } from '../../core/actions/action';
import { vendorListAction, productListAction, serviceListAction } from '../../core/actions';

const mapStateToProps = state => {

    return{
        locationData:JSON.parse(localStorage.getItem('locationData')),
        vendorList:state.Home.vendorList,
        productList:state.Home.productList,
        serviceList:state.Home.serviceList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getVendorList:(limit,lat,lng,radius,ggCoin,category,filter) => {
          dispatch(vendorListAction(null));
            return getVendorList(limit,lat,lng,radius,ggCoin,category,filter)
            .then((res)=>{
                const sources = res.data;

                if(sources.status===true){
                    dispatch(vendorListAction(sources.data));
                  }else{
                    dispatch(vendorListAction(null));
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                  }
            }).catch((error)=>{
                const sources = error.response;
                if(sources.status!==401){
                  sources.data.error.map((res)=>{
                    Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                    return res;
                  })
                }else{
                  Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                }
            })
        },

        getProductList:(limit,lat,lng,radius,ggCoin,category,filter) => {
          dispatch(productListAction(null));
            return getProductList(limit,lat,lng,radius,ggCoin,category,filter)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    dispatch(productListAction(sources.data));
                  }else{
                    dispatch(productListAction(null));
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                  }
            }).catch((error)=>{
                const sources = error.response;

                if(sources.status!==401){
                  sources.data.error.map((res)=>{
                    Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                    return res;
                  })
                }else{
                  Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                }
            })
        },

        getServiceList:(limit,lat,lng,radius,category,filter) => {
          dispatch(serviceListAction(null));
            return getServiceList(limit,lat,lng,radius,category,filter)
            .then((res)=>{
                const sources = res.data;

                if(sources.status===true){
                    dispatch(serviceListAction(sources.data));
                  }else{
                    dispatch(serviceListAction(null));
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                  }
            }).catch((error)=>{
                const sources = error.response;

                if(sources.status!==401){
                  sources.data.error.map((res)=>{
                    Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                    return res;
                  })
                }else{
                  Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                }
            })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
