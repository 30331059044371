import React, {Component} from 'react';
import { Card } from "@blueprintjs/core";

export default class LoadingComponent extends Component{

    render(){

        return(
          <div className="row no-gutters mt-5">
            <div className="col-md-8 col-xl-8 col-lg-8 col-12">
              <div className="col-md-12 col-xl-12 col-lg-12 col-sm-12 col-12 mb-4">
                <Card className='bp3-elevation-1'>
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 mt-s-2">
                      <center>
                        <div className="bp3-skeleton" style={{height: "210px"}} />
                      </center>
                    </div>
                    <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12 pl-xl-4 pl-lg-4 pl-md-3 pl-sm-2 pl-3 mt-s-3 py-3">
                      <div className="bp3-skeleton" style={{height: "20px",width:'70%'}} />
                      <br/>
                      <div className="bp3-skeleton" style={{height: "20px",width:'80%'}} />
                      <br/>
                      <div className="bp3-skeleton" style={{height: "20px",width:'45%'}} />
                      <br/>
                      <div className="bp3-skeleton" style={{height: "20px",width:'30%'}} />
                      <br/>
                      <div className="bp3-skeleton" style={{height: "20px",width:'40%'}} />
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col-md-12 col-xl-12 col-lg-12 col-sm-12 col-12 mb-4">
                <Card className='bp3-elevation-1'>
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 mt-s-2">
                      <center>
                        <div className="bp3-skeleton" style={{height: "210px"}} />
                      </center>
                    </div>
                    <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12 pl-xl-4 pl-lg-4 pl-md-3 pl-sm-2 pl-3 mt-s-3 py-3">
                      <div className="bp3-skeleton" style={{height: "20px",width:'70%'}} />
                      <br/>
                      <div className="bp3-skeleton" style={{height: "20px",width:'80%'}} />
                      <br/>
                      <div className="bp3-skeleton" style={{height: "20px",width:'45%'}} />
                      <br/>
                      <div className="bp3-skeleton" style={{height: "20px",width:'30%'}} />
                      <br/>
                      <div className="bp3-skeleton" style={{height: "20px",width:'40%'}} />
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col-md-12 col-xl-12 col-lg-12 col-sm-12 col-12 mb-4">
                <Card className='bp3-elevation-1'>
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 mt-s-2">
                      <center>
                        <div className="bp3-skeleton" style={{height: "210px"}} />
                      </center>
                    </div>
                    <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12 pl-xl-4 pl-lg-4 pl-md-3 pl-sm-2 pl-3 mt-s-3 py-3">
                      <div className="bp3-skeleton" style={{height: "20px",width:'70%'}} />
                      <br/>
                      <div className="bp3-skeleton" style={{height: "20px",width:'80%'}} />
                      <br/>
                      <div className="bp3-skeleton" style={{height: "20px",width:'45%'}} />
                      <br/>
                      <div className="bp3-skeleton" style={{height: "20px",width:'30%'}} />
                      <br/>
                      <div className="bp3-skeleton" style={{height: "20px",width:'40%'}} />
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                <br/>
              <br/>
                <div className="bp3-skeleton" style={{height: "20px",width:'60%'}} />
                <br/>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-s-2">
                    <div className="bp3-skeleton" style={{height: "25px"}} />
                    <br/>
                  <div className="bp3-skeleton" style={{height: "25px"}} />
                    <br/>
                  <div className="bp3-skeleton" style={{height: "25px"}} />
                    <br/>
                  <div className="bp3-skeleton" style={{height: "25px"}} />
                    <br/>
                  <div className="bp3-skeleton" style={{height: "25px"}} />
                    <br/>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="bp3-skeleton" style={{height: "25px",width:'70%'}} />
                    <br/>
                  <div className="bp3-skeleton" style={{height: "25px",width:'80%'}} />
                    <br/>
                  <div className="bp3-skeleton" style={{height: "25px",width:'45%'}} />
                    <br/>
                  <div className="bp3-skeleton" style={{height: "25px",width:'30%'}} />
                    <br/>
                  <div className="bp3-skeleton" style={{height: "25px",width:'40%'}} />
                  </div>
                </div>
                <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
              </Card>
            </div>
        </div>
        );

    }

}
