import React, {Component} from 'react';
// import { Card } from "@blueprintjs/core";
import NoGallary from "../../../core/components/emptyStates/noGallary";

export default class Gallary extends Component{

    constructor(props){
        super(props);

        this.state={

        }

    }



    render(){
        return(
          <div className='row vendor-detail-gallary-list mb-5'>
            {this.props.gallry.length===0 ?
              <div className='col-xl-12 col-12'>
                <NoGallary />
              </div>
              :
              this.props.gallry.map((img,i)=>{
                return(
                  <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mb-1 mt-4 gallary text-center' key={i}>
                    <img src={img.url} alt="vendor" className="img-fluid"
                      onError={(ev)=>{
                        ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                      }}/>
                    </div>
                  );
                })}
              </div>
        );

    }

}
