import React, {Component} from 'react';
import {Classes, Dialog, Toaster, Position, Intent } from "@blueprintjs/core";
import { Button, TextArea } from "@blueprintjs/core";
import {ssoInstance, Auth} from 'gg-react-utilities';
import StarRatings from 'react-star-ratings';
import Config from '../../Config';

export default class ReviewModal extends Component{
    constructor(props){
        super(props);
        this.state={
          review: '',
          rating:null,
          isOpen: props.isOpen,
          ratingError:'',
          ratingErrorClass:'',
        }
        this.handleClose = this.handleClose.bind(this);
        this.changeRating = this.changeRating.bind(this);
    }

    handleClose=()=>{
      this.setState({
        isOpen: false
      })
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextprops){
        if(nextprops.isOpen!==undefined){
            this.setState({
                isOpen: nextprops.isOpen
            });
        }

    }

    changeRating( newRating, name ) {
      this.setState({
        rating: newRating
      });
    }

    submitReview(){
      let api = Config.API_URL;
      let obj = {
        "rating":this.state.rating,
         "review":this.state.review
      };
      if(this.props.type==='vendor'){
        obj.vendorId = this.props.vendorId
        api = api +'user/'+Auth.user().user_id+'/review_ratings'
      }else{
        obj.product_id = this.props.productId;
        api = api +'product/reviews'
      }

      ssoInstance.post(api,obj).then((res) => {

       const sources = res.data;

       if (sources.status === true) {
         Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
         this.setState({ratingErrorClass:'',isOpen:false})
         setTimeout(()=>{
           window.location.reload();
         },500)
       }

     }).then((json) => {

     }).catch((error) => {
       const sources = error.response;

       this.setState({ratingErrorClass:'error',ratingError:sources.data.error})
     });
    }

    render(){

        return(
      				<Dialog
      					isOpen={this.state.isOpen}
      					title={this.props.title}
                className={this.props.className?this.props.className:" "}
                onClose={this.handleClose}
                canOutsideClickClose={false}
                canEscapeKeyClose={true}
                >
                <div className="bp3-dialog-body mg-brand-payments">
                  <div className="paymentWrapper">
                      <div className="upperPaymentWrapper">
                      </div>
                      <div className="middlePaymentWrapper">
                          <div className="circleWrapper">
                              <div className="iconWrapper">
                                  <img src={this.props.image!==null?this.props.image:process.env.REACT_APP_DEFAULT_IMAGE} className="img-fluid" alt=""
                                    onError={(ev)=>{
                                      ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                                    }}/>
                              </div>
                          </div>
                      </div>
                      <div className='mt-4 row lowerWrapper'>
                        <div className='col-xl-12 text-center'>
                          <h4><b>{this.props.name}</b></h4>
                        </div>
                        <div className='col-xl-12 text-center'>
                          <div className={"bp3-form-content "+this.state.ratingErrorClass}>
                            <div className="bp3-input-group">
                              <StarRatings
                                rating={this.state.rating!==null?this.state.rating:0}
                                starRatedColor="#F5A623"
                                starHoverColor="#F5A623"
                                changeRating={this.changeRating}
                                starDimension='40px'
                                numberOfStars={5}
                                name='rating'
                              />
                            <span className='text-left error-text'>{this.state.ratingError}</span>
                            </div>
                          </div>
                        </div>
                        <div className='col-xl-12 mt-4'>
                          <TextArea
                            placeholder='Write your review here...'
                            rows={5}
                            value={this.state.review}
                            onChange={(e)=>{
                              this.setState({review:e.target.value})
                            }}/>
                        </div>
                      </div>
                      <div className={Classes.DIALOG_FOOTER}>
            						<div className={Classes.DIALOG_FOOTER_ACTIONS}>
                          <Button text='Not Now' className='bp3-button bp3-button bp3-secondary mb-3 mt-4' onClick={this.handleClose}/> &nbsp; &nbsp;
                          <Button text='Submit' className='bp3-button bp3-intent-primary bp3-button bp3-intent-primary mb-3 mt-4'
                            onClick={()=>{
                              this.submitReview()
                            }}/>
            						</div>
            					</div>
                  </div>

                </div>
    				</Dialog>
        );
    }

}
