let initialState = {
    upos_vendor_list:null

};

const UposVendor = (state = initialState, action) => {

    switch (action.type) {

        case 'UPOS_VENDOR_LIST':
            return {...state, upos_vendor_list: action.sources}
        default:
            return state
    }
}

export default UposVendor;
