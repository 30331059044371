import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";

import NearbyUposComponent from '../component/NearbyUposComponent';
import { getUposVendorList } from '../../core/actions/action';
import { uposVendorListAction } from '../../core/actions';

const mapStateToProps = state => {

    return{
        locationData:JSON.parse(localStorage.getItem('locationData')),
        upos_vendor_list:state.UposVendor.upos_vendor_list,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getUposVendorList:(lat,lng) => {
          dispatch(uposVendorListAction(null));
            return getUposVendorList(lat,lng)
            .then((res)=>{
                const sources = res.data;
                if(sources.status){
                  dispatch(uposVendorListAction(sources));
                }else{
                  dispatch(uposVendorListAction(null));
                  Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                }
                // dispatch(uposVendorListAction([]));

            }).catch((error)=>{
                const sources = error.response;
                if(sources.status!==401){
                  sources.data.error.map((res)=>{
                    Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                    return res;
                  })
                }else{
                  Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                }
            })
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NearbyUposComponent);
