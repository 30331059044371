import React, {Component} from 'react';
import { Checkbox, Label} from "@blueprintjs/core";
import InfiniteScroll from "react-infinite-scroll-component";
import _ from 'lodash';
import {Loader} from 'gg-react-utilities';
import Loading from "../loading";
import Voucher from '../Voucher';
import Config from '../../../Config';
import NoVendor from '../../../core/components/emptyStates/noVendor';

export default class FoodVoucher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 1,
            brand_name: '',
            data: [],
            isEnabled: false,
            tat: [],
            type: [],
            food_voucher_data: [],
            voucher_gg_coin:''
        };

        this.fetchMoreData = this.fetchMoreData.bind(this);
    }

    componentDidMount(){
      this.props.getFoodVoucherList(this.state.count);
    }

    componentWillReceiveProps(nextProps) {
      if(nextProps.food_voucher_list!==null){
        if(nextProps.food_voucher_list.last_page>=this.state.count){
          this.setState({
              data: this.state.data.concat(nextProps.food_voucher_list.data),
          })
      }}
    }

    fetchMoreData() {
      if(this.props.food_voucher_list!==null){
        if(this.props.food_voucher_list.last_page>=this.state.count){
          this.setState({count:this.state.count+1},()=>{
            this.props.getFoodVoucherList(this.state.count);
          })
        }
      }
    }

    handleChkRef = (event, type) => {
        let value = event.target.value;
        let isChecked = event.target.checked;
        let checked = '';
        if (type === "coin") {
            if (isChecked === false) {
                this.setState({voucher_gg_coin: ""})
            } else {
                this.setState({voucher_gg_coin: value})
            }
        }

        if (type === "voucher") {
            checked = this.state.type;
            if (isChecked === false) {
                _.remove(checked, function (v) {
                    return v === value;
                });
            } else {
                checked.push(value)
            }

            this.setState({type: checked})
        }

        if (type === "delivery") {
            checked = this.state.tat;
            if (isChecked === false) {
                _.remove(checked, function (v) {
                    return v === value;
                });
            } else {
                checked.push(value)
            }

            this.setState({tat: checked})
        }
        this.setState({data:[],count:1},()=>{
          this.props.getFoodVoucherList(this.state.count,this.state.brand_name,this.state.type,this.state.tat,this.state.voucher_gg_coin);
        })
    };

    render() {
      const {food_voucher_list} = this.props;
        return (
          <div className='row'>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-12">
              <div className="rounded-0 border p-3 food-sidebar">
                  <article>
                      <div className="ar-header">
                          <Label className="sb-title">By GG-Coin</Label>
                      </div>
                      <div className={"ar-body"}>
                          <Checkbox value={"1"}
                                    onChange={(e) => this.handleChkRef(e, "coin")}
                                    label={"GG-Coin Applicable"}
                                    className="sb-chk-text"/>
                      </div>
                  </article>
                  <hr/>
                  <article>
                      <div className="ar-header">
                          <Label className="sb-title">Voucher Type</Label>
                      </div>
                      <div className={"ar-body"}>
                          <Checkbox value={"Physical"}
                                    onChange={(e) => this.handleChkRef(e, "voucher")}
                                    label={"Physical Voucher"}
                                    className="sb-chk-text"/>
                          <Checkbox value={"Digital"}
                                    onChange={(e) => this.handleChkRef(e, "voucher")}
                                    label={"Digital Voucher"}
                                    className="sb-chk-text"/>
                      </div>
                  </article>
                  <hr/>
                  <article>
                      <div className="ar-header">
                          <Label className="sb-title">Delivery</Label>
                      </div>
                      <div className={"ar-body"}>
                          <Checkbox value={"Instant"}
                                    onChange={(e) => this.handleChkRef(e, "delivery")}
                                    label={"Instant Delivery"}
                                    className="sb-chk-text"/>
                          <Checkbox value={"Normal"}
                                    onChange={(e) => this.handleChkRef(e, "delivery")}
                                    label={"Normal Delivery"}
                                    className="sb-chk-text"/>
                      </div>
                  </article>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
              </div>
            </div>
            <div className="col-xl-9 col-lg-9 col-md-8 col-sm-7 col-12 mt-s-3 pl-sm-0 pl-md-3 pl-lg-3 pl-xl-3 scroll-container">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 px-0 mb-2">
                    <div className="bp3-input-group ">
                        <input type="text" className="bp3-input" placeholder="Search your food voucher"
                               value={this.state.brand_name}
                               onChange={(e) => {
                                   this.setState({brand_name: e.target.value,data:[]},()=>{
                                     this.props.getFoodVoucherList(this.state.count,this.state.brand_name)
                                   })
                               }}/>
                    </div>
                </div>
                {food_voucher_list===null?
                  <Loading type='voucher'/>
                  :
                  this.state.data.length===0?
                    <div className='col-xl-12 col-12'>
                      <NoVendor/>
                    </div>
                    :
                        <InfiniteScroll
                            dataLength={this.state.data.length}
                            next={this.fetchMoreData}
                            hasMore={true}
                            loader={food_voucher_list.next_page_url!==null?<div className='mt-1'><Loader/></div>:null}
                        >
                            <div className="row mt-4 justify-content-center mr-0">

                                {
                                  this.state.data.map((brand, key) => {
                                    let ggCoin = "";
                                    if (1 === brand.coin_applicable) ggCoin = <img src={process.env.REACT_APP_GG_COIN_IMAGE} alt="GG Coins Applicable"
                                         className="img-fluid coin-img mt-2 mr-3"/>;
                                    let tat = "";
                                    if (brand.tat === 0) {
                                        tat = "Instant";
                                    } else {
                                        tat = brand.tat + " Days"
                                    }

                                    return (
                                        <aside className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-s-0 mb-4 brand-col"
                                               key={key}>
                                               <a href={Config.MEGABRAND_URL+'brand/'+brand.slug}>
                                                 <div className={"mega-brand-card "} data-brandid={brand.brand_id}>
                                                   <div className="brand-card d-flex justify-content-between item-align-center"
                                                     style={{backgroundImage: 'linear-gradient(135deg, ' + brand.color_code[0] + ' 0%, ' + brand.color_code[1] + ' 100%)'}}
                                                     >
                                                       <p className="brand-name">{brand.brand_name}</p>
                                                     <p className="mt-minus"><Voucher brand={brand} {...this.props}/></p>
                                                 </div>
                                                 <div className="text-center">
                                                   <img
                                                     src={brand.image}
                                                     className="img-fluid brand-img"
                                                     alt={brand.brand_name}
                                                     onError={(ev)=>{
                                                       ev.target.src = process.env.REACT_APP_MEGABRAND_DEFAULT_IMAGE;
                                                     }}
                                                   />
                                                   {ggCoin}
                                                 </div>
                                                 <div className="d-flex justify-content-between item-align-center pl-3 pr-3 pt-3 pb-2">
                                                   <p className="brand-delivery text-center m-auto">Delivery: {tat}</p>
                                                 <p className="brand-product-type text-center m-auto">{brand.product_type} Voucher</p>
                                             </div>
                                           </div>
                                               </a>
                                        </aside>
                                    );
                                })
                                }
                            </div>
                        </InfiniteScroll>
                }
              </div>
            </div>

        );
    }
}
