import React,{ Component } from 'react';
import VendorContainer from './container/VendorContainer';
import {Error} from 'gg-react-utilities';

export default class Vendor extends Component{

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1000)
    }

    render(){

      try {
        return(
          <VendorContainer
            back={this.props.history}
          />
        )
      } catch (e) {
        return(
          <Error />
        )
      }
    }
}
