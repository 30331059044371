import React, {Component, Fragment} from 'react';
import { Card, Button, Radio} from "@blueprintjs/core";
import {PageTitle, Auth, NoLogin, Helper} from 'gg-react-utilities';
import {getString} from "../../core/utils/helper";
import Loading from "./loading";
import TopBar from "../../core/components/topBar";
import NoLocation from '../../core/components/emptyStates/noLocation';
import AddAddressModal from './addAddressModal';

export default class CheckoutComponent extends Component {

    constructor(props){
        super(props);
        this.state={
            viewOrder:false,
            addressModal:false,
            address:null,
            selectedIndex:null,
            data:null,
            addressStatus:false,
            payStatus:false,
            checkoutStatus:true,
            paymentIndex:null,
            isLoggedIn:null
        }
    }

    componentDidMount(){
      if(Auth.isLoggedIn()){
        this.props.getCartList();
        this.props.getUserAddress();
      }
      this.checkAuth();
    }

    componentWillReceiveProps(nextProps){

    }

    async checkAuth(){
      setTimeout(()=>{
        Auth.isLoggedInAsync().then((res) => {
          this.setState({isLoggedIn:res})
        });
      },1000)
    }

    doPlaceOrder(){
      this.setState({payStatus:true})
        this.props.getPlaceOrder().then((res) => {
            const sources = res.data;
            if (sources.status === true) {
                if(sources.data.open_pg===true){
                    const paywithpayubiz = sources.data.pg;
                    let form = document.createElement("form");
                    form.setAttribute("method", 'POST');
                    form.setAttribute("action", paywithpayubiz.url);
                    for (let key in paywithpayubiz) {
                        let hiddenField = document.createElement("input");
                        hiddenField.setAttribute("type", "hidden");
                        hiddenField.setAttribute("name", key);
                        hiddenField.setAttribute("value", paywithpayubiz[key]);
                        form.appendChild(hiddenField);
                    }
                    //
                    document.body.appendChild(form);
                    form.submit();
                }else{
                    let
                        status = ''

                    if(sources.status===true){
                        status = 'success/?msg=Order%20placed%20successfully'
                        this.props.nextPage(status)
                    }else {
                        status = 'failure/?msg=Order%20payment%20failed'
                        this.props.nextPage(status)
                    }
                }

            }else{

            }
        }).catch((error) => {

            const sources = error.response;
            if(sources.data.message==="Validation Error."){

            }else{

            }


        });

    }

    render() {

        return (
            <div className='container-fluid'>

                <div className="container mb-1">
                  <PageTitle title={'Checkout | Global Garner'} />
                  <br/>
                {this.props.cartList === null || this.state.isLoggedIn===null?
                  <Loading />
                  :
                  !this.state.isLoggedIn ?
                  <Card className='bp3-elevation-1 border mt-5 mb-5'>
                    <div className='mt-5 mb-5'>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <NoLogin imageShow={true} homeLink={false} />
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                    </div>
                  </Card>
                  :
                  <Fragment>
                    <TopBar title={"Select address"} backClick={this.props.back} rightMenu={
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right">
                        <ul className="headerNav">
                          <li className='text-primary'><h2>GG Coins: {Number(this.props.cartList.gg_coin_balance).toFixed(2)}</h2></li>
                        </ul>
                      </div>
                    }
                    breadcrumbs={[
                      {label:'Checkout',to:'/checkout'},
                    ]}/>
                    <div className="row checkout-container">
                      <div className="col-xl-8 col-8">

                          {this.state.checkoutStatus ?
                              <Fragment>
                                  <AddAddressModal
                                      title='Edit Details'
                                      isOpen={this.state.addressModal}
                                      className="bp3-dialog p-0 rounded"
                                      {...this.props}/>
                                      <div className='row'>
                                          <div className="col-xl-7 col-xl-7 col-sm-7 col-8">
                                              <h2 className="title">Choose Address</h2>
                                          </div>
                                          <div className="col-xl-5 col-xl-5 col-sm-5 col-4">
                                          <Button text='+ Add New Address' className='mt-3  pull-right btn-link bp3-small'
                                                  onClick={()=>{
                                                      this.setState({addressModal:true})
                                                  }}/>
                                          </div>
                                      </div>
                                  { this.props.userAddress.length===0?
                                      <NoLocation/>
                                      :
                                      this.props.userAddress.map((res,i)=>{
                                          let addressClass = 'bp3-elevation-2 border col-12 col-xl-12 mb-3 address'
                                          if(this.state.selectedIndex===i){
                                              addressClass = addressClass+' bg-basic'
                                          }
                                          return (
                                              <Card className={addressClass} key={res.add_id}>
                                                  <span className='bg-greyis pull-right'>{res.address_type}</span>
                                                  <p><b>{Auth.user().fname+' '+Auth.user().lname}</b></p>
                                                <p>  {getString([res.street,res.landmark ,
                                                      res.area ,res.state],res.pincode)}</p>
                                                  <Radio label="Make this as my default address" value={i}  name='address'
                                                         onChange={(e)=>{
                                                             this.setState({selectedIndex:Number(e.target.value),address:this.props.userAddress[Number(e.target.value)]})
                                                         }}/>
                                              </Card>
                                          );
                                      })}
                              </Fragment>
                        :
                              <Fragment>
                                  <div className='row mt-5'>
                                      {this.props.shippingAddress!==null?
                                          this.props.shippingAddress.payement_methods.map((res,i)=>{

                                          return(
                                              <div className="col-xl-3 col-3 payment-section mb-3" key={res.sort_order} onClick={()=>{
                                                  this.setState({paymentIndex:i})
                                                  let obj = {
                                                      "payment_code" : res.code
                                                  }
                                                  this.props.getPaymentMethod(obj)
                                              }}>
                                                  {this.state.paymentIndex===i?
                                                      <img src={require('../images/yes.png')} alt='' className='pull-right payment-select'/>
                                                      :null}
                                                  <Card className={'bp3-elevation-3 '+this.state.paymentIndex===i?'bg-basic':''}>
                                                      <center>
                                                          <img src={require('../images/svg/'+res.code+'.svg')} alt='' className='img-fluid'/>
                                                          <p className='mt-2 bp3-text-medium'>{res.title}</p>
                                                      </center>
                                                  </Card>
                                              </div>
                                          )
                                      })
                                      :null}
                                  </div>
                              </Fragment>
                          }

                      </div>
                      <div className="col-xl-4 col-4">
                        <Card className='border bg-basic bp3-elevation-2'>
                          <div className="col-xl-12 mb-5">
                          <h2 className="title">Payable Amount Details </h2>
                            {this.props.cartList.total_data.map((res,i)=>{
                              if(res.code!=='grand_total'){
                                return(
                                  <div className="row" key={i}>
                                    <div className="col-xl-8 col-5">
                                      <h4 className="">{res.title}</h4>
                                    </div>
                                    <div className="col-xl-4 col-7">
                                      <h4 className="">{res.type} {Helper.formatMoney(res.value)}</h4>
                                    </div>
                                  </div>
                                )
                              }else {
                                return(
                                  <Fragment key={i}>
                                    <div className='border-bottom'/>
                                    <div className="row">
                                      <div className="col-xl-8 col-5">
                                        <h4 className=""><b>{res.title}</b></h4>
                                      </div>
                                      <div className="col-xl-4 col-7">
                                        <h4 className=""><b>{res.type} {Helper.formatMoney(res.value)}</b></h4>
                                      </div>
                                    </div>
                                  </Fragment>
                                )
                              }
                            })}
                              {this.state.checkoutStatus ?
                                  this.state.selectedIndex !== null ?
                                      <Button text='Continue' className='bp3-button bp3-intent-primary pull-right'
                                        loading={this.state.addressStatus}
                                              onClick={() => {
                                                this.setState({
                                                    addressStatus: true
                                                })
                                                  let obj = {
                                                      "address_firstname": Auth.user().fname,
                                                      "address_lastname": Auth.user().lname,
                                                      "address_street": this.state.address.street,
                                                      "address_landmark": this.state.address.landmark,
                                                      "address_area": this.state.address.area,
                                                      "address_city": this.state.address.city,
                                                      "address_pincode": this.state.address.pincode,
                                                      "address_state": this.state.address.state,
                                                      "address_country": "India",
                                                      "address_country_id": 14
                                                  }
                                                  this.props.getShippingAddress(obj)
                                                  this.setState({
                                                      checkoutStatus: false
                                                  })
                                              }}/>
                                      :
                                      <Button text='Continue' className='bp3-button bp3-intent-primary pull-right'
                                              disabled/>
                                  :
                                  this.state.paymentIndex!==null ?
                                      <Button text='Place Order' className='bp3-button bp3-intent-primary pull-right'
                                        loading={this.state.payStatus}
                                              onClick={()=>{
                                                  this.doPlaceOrder();
                                              }}/>
                                  :
                                      <Button text='Place Order' className='bp3-button bp3-intent-primary pull-right'
                                       disabled />
                              }
                          </div>
                        </Card>
                      </div>
                    </div>
                  </Fragment>

                }
                <br/>
              </div>

            </div>
          )
        }
    }
