
export const userDetails = (sources)=>{

    return {
        type: 'USER_SESSION',
        sources:sources
    }
}

export const userAddressDetails = (sources)=>{

    return {
        type: 'USER_ADDRESS_LIST',
        sources:sources
    }
}


export const userData = (sources)=>{
    return {
        type: 'USER_DATA',
        sources:sources
    }
}

export const vendorProductListAction = (sources) => {

    return {
        type: 'VENDOR_PRODUCT_LIST',
        sources: sources
    }
}

export const vendorServiceListAction = (sources) => {

    return {
        type: 'VENDOR_SERVICE_LIST',
        sources: sources
    }
}

export const vendorListAction = (sources) => {

    return {
        type: 'VENDOR_LIST',
        sources: sources
    }
}

export const productListAction = (sources) => {

    return {
        type: 'PRODUCT_LIST',
        sources: sources
    }
}

export const serviceListAction = (sources) => {

    return {
        type: 'SERVICE_LIST',
        sources: sources
    }
}

export const categoryListAction = (sources) => {

    return {
        type: 'CATEGORY_LIST',
        sources: sources
    }
}

export const voucherCategoryListAction = (sources) => {

    return {
        type: 'VOUCHER_CATEGORY_LIST',
        sources: sources
    }
}

export const vendorWishAction = (sources) => {

    return {
        type: 'VENDOR_WISH_STATUS',
        sources: sources
    }
}

export const productWishAction = (sources) => {

    return {
        type: 'PRODUCT_WISH_STATUS',
        sources: sources
    }
}

export const vendorDetailAction = (sources) => {

    return {
        type: 'VENDOR_DETAIL',
        sources: sources
    }
}

export const productDetailAction = (sources) => {

    return {
        type: 'PRODUCT_DETAIL',
        sources: sources
    }
}

export const serviceDetailAction = (sources) => {

    return {
        type: 'SERVICE_DETAIL',
        sources: sources
    }
}

export const serviceBookAction = (sources) => {

    return {
        type: 'SERVICE_BOOK',
        sources: sources
    }
}

export const wishlistAction = (sources) => {

    return {
        type: 'WISH_LIST',
        sources: sources
    }
}

export const favouriteListAction = (sources) => {

    return {
        type: 'FAVOURITE_LIST',
        sources: sources
    }
}

export const trashBillAction = (sources) => {

    return {
        type: 'TRASH_BILL',
        sources: sources
    }
}

export const restoreListAction = (sources) => {

    return {
        type: 'RESTORE_BILL',
        sources: sources
    }
}

export const uploadBillListAction = (sources) => {

    return {
        type: 'UPLOAD_BILL_LIST',
        sources: sources
    }
}

export const trashBillListAction = (sources) => {

    return {
        type: 'TRASH_BILL_LIST',
        sources: sources
    }
}

export const addtoCartAction = (sources) => {

    return {
        type: 'ADD_PRODUCT_TO_CART',
        sources: sources
    }
}

export const removeFromCartAction = (sources) => {

    return {
        type: 'REMOVE_PRODUCT_FROM_CART',
        sources: sources
    }
}

export const productAvailableAction = (sources) => {

    return {
        type: 'PRODUCT_AVAILABILTY',
        sources: sources
    }
}


export const redeemGGCoinAction = (sources) => {

    return {
        type: 'REDDEM_GG_COIN',
        sources: sources
    }
}

export const shippingAddressAction = (sources) => {

    return {
        type: 'SHIPPING_ADDRESS',
        sources: sources
    }
}

export const paymentMethodAction = (sources) => {

    return {
        type: 'PAYMENT_METHOD',
        sources: sources
    }
}

export const cartSummaryAction = (sources) => {

    return {
        type: 'CART_SUMMARY',
        sources: sources
    }
}

export const cartListAction = (sources) => {

    return {
        type: 'CART_LIST',
        sources: sources
    }
}

export const cancelOrderListAction = (sources) => {

    return {
        type: 'CANCEL_ORDER',
        sources: sources
    }
}

export const orderListAction = (sources) => {

    return {
        type: 'ORDER_LIST',
        sources: sources
    }
}

export const uposVendorListAction = (sources) => {

    return {
        type: 'UPOS_VENDOR_LIST',
        sources: sources
    }
}

export const foodListAction = (sources) => {

    return {
        type: 'FOOD_LIST',
        sources: sources
    }
}

export const foodVoucherListAction = (sources) => {

    return {
        type: 'FOOD_VOUCHER_LIST',
        sources: sources
    }
}

export const postAddressDetailsAction = (sources) => {

    return {
        type: 'POST_ADDRESS',
        sources: sources
    }
}
