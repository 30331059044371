let initialState = {
    serviceDetails:null,
    bookService:null
};
const serviceDetail = (state = initialState, action) => {
    switch (action.type) {
        case 'SERVICE_DETAIL':
            return {...state, serviceDetails: action.sources}
        case 'SERVICE_BOOK':
            return {...state, bookService: action.sources}
        default:
            return state
    }
}

export default serviceDetail
