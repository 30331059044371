import {ssoInstance} from 'gg-react-utilities';
import Config from "../../Config";
import {Auth} from 'gg-react-utilities';

export const userSession = () => {

    return ssoInstance.get(Config.ACCOUNT_API_URL + 'user/profile');
}

export const getUserData = (userId) => {

    return ssoInstance.get(Config.ACCOUNT_API_URL + 'users/'+userId);
}

export const getRequestOtp = (mobileNo) => {
    let obj = {
      "username":mobileNo
    }
    return ssoInstance.post(Config.ACCOUNT_API_URL + 'register/request-mobile-otp',obj);
}

export const addProductToWishlist = (product_id) => {
    let obj = {
      "product_id":product_id
    }
    return ssoInstance.post(Config.API_URL + 'wishlist/toggle/product',obj);
}

export const addVendorToWishlist = (obj) => {
    let endpoint = Config.API_URL +'vendor/toggle/favorites'
    if(obj.agent_id){
      endpoint = Config.UPOS_API_URL + 'toggle-favourite'
    }
    return ssoInstance.post(endpoint,obj);
}

export const getvendorProductList = (slug,limit) => {

    return ssoInstance.get(Config.API_URL +'product/vendor/'+slug+'?start=0&length='+limit);

}

export const getVendorServiceList = (slug,limit) => {

    return ssoInstance.get(Config.API_URL +'vendor/'+slug+'/services?start=0&length='+limit);

}

export const getMagentoStoreUrl = () => {

    return ssoInstance.get(Config.API_URL +'user/get-magento-store-url');

}

export const getVendorList = (limit,lat,lng,radius,ggCoin,category,filter) => {
    ggCoin = ggCoin ? '&gg_coin_accept=1' :'';
    filter = filter!==''?'&columnSearch[company_name]='+filter :'';
    category = category.length>0?'&categories='+category:'';
    return ssoInstance.get(Config.API_URL +'vendor?start=0&length='+limit+'&latitude='+lat+'&longitude='+lng+'&page=1&radius='+radius+ggCoin+category+filter);

}

export const getProductList = (limit,lat,lng,radius,ggCoin,category,filter) => {
    ggCoin = ggCoin ? '&gg_coin_accept=1' :'';
    filter = filter!==''?'&columnSearch[name]='+filter :'';
    category = category.length>0?'&categories='+category:'';
    return ssoInstance.get(Config.API_URL +'product?start=0&length='+limit+'&latitude='+lat+'&longitude='+lng+'&page=1&radius='+radius+ggCoin+category+filter);

}

export const getServiceList = (limit,lat,lng,radius,category,filter) => {
    filter = filter!==''?'&columnSearch[title]='+filter :'';
    category = category.length>0?'&categories='+category:'';
    return ssoInstance.get(Config.API_URL +'service/near_by?start=0&length='+limit+'&latitude='+lat+'&longitude='+lng+'&page=1&radius='+radius+category+filter);

}

export const getCategoryList = (name) => {

    return ssoInstance.get(Config.API_URL +'category/search?category_name='+name);

}

export const getVendorDetail = (slug) => {

    return ssoInstance.get(Config.API_URL +'vendor/'+slug);

}

export const postReviewToProduct = (obj) =>{

  return ssoInstance.post(Config.API_URL+'product/reviews',obj)
}

export const getProductDetail = (slug) => {

    return ssoInstance.get(Config.API_URL +'product/'+slug);
}

export const getServiceDetail = (slug) => {

    return ssoInstance.get(Config.API_URL +'service/'+slug);

}

export const getBookService = (slug, obj) => {

    return ssoInstance.post(Config.API_URL +'booking/service/new/'+slug, obj);

}

export const getWishlist = () => {

    return ssoInstance.get(Config.API_URL +'wishlist');

}

export const getTrashBill = (bill_id) => {

    return ssoInstance.patch(Config.API_URL +'bills/'+bill_id+'/trash');
}

export const getDeleteBill = (bill_id) => {

    return ssoInstance.delete(Config.API_URL +'bills/'+bill_id+'/delete');
}

export const getRestoreBill = (bill_id) => {

    return ssoInstance.patch(Config.API_URL +'bills/'+bill_id+'/restore');

}

export const getBillList = (limit) => {
    return ssoInstance.get(Config.API_URL +'user_store_bills/'+Auth.user().user_id+'/0?start=0&length='+limit);

}

export const getTrashBillList = (limit) => {

    return ssoInstance.get(Config.API_URL +'user_store_bills/'+Auth.user().user_id+'/DELETED?start=0&length='+limit);

}

export const checkProductAvailibility = (slug,pincode) => {
    let obj =    {
    	"pincode": pincode
    }
    return ssoInstance.post(Config.API_URL +'product/check-availability/'+slug,obj);

}

export const productAddToCart = (obj) => {

    return ssoInstance.post(Config.API_URL +'cart/add-product',obj);

}

export const productDeleteFromCart = (product_id) => {

    return ssoInstance.delete(Config.API_URL +'cart/remove-product?cart_product_id='+product_id);

}

export const getReddemCoin = (obj) => {

    return ssoInstance.post(Config.API_URL +'cart/redeem-ggcoin',obj);

}

export const getShippingAddress = (obj) => {

    return ssoInstance.post(Config.API_URL +'cart/shipping',obj);

}

export const getPaymentMethod = (obj) => {
    return ssoInstance.post(Config.API_URL +'cart/payment',obj);

}

export const getPlaceOrder = () => {
    let obj = {
      "platform":"WEB"
    }
    return ssoInstance.post(Config.API_URL +'order/place-order',obj);

}

export const getCartSummary = () => {

    return ssoInstance.get(Config.API_URL +'cart/summary');

}

export const getUserAddress = () => {

    return ssoInstance.get(Config.ACCOUNT_API_URL +'api/v1.0.2/user/' + Auth.user().user_id + '/address');

}

export const getUpdateCart = (obj) => {

    return ssoInstance.put(Config.API_URL +'cart/update-product',obj);

}

export const getCartList = () => {

    return ssoInstance.get(Config.API_URL +'cart');

}

export const getCancelOrder = (id) => {

    return ssoInstance.get(Config.API_URL +'order/cancel/'+id);

}

export const getOrderList = (limit) => {

    return ssoInstance.get(Config.API_URL +'order/all-order?start=0&length='+limit);

}

export const getUposVendorList = (lat, lng, limit) => {

    return ssoInstance.get(Config.UPOS_API_URL + 'nearby-upos-vendors?latitude='+lat+'&longitude='+lng);

}

export const getVendorFoodList = (limit, lat, lng, radius, ggCoin, filter) => {
    ggCoin = ggCoin ? '&gg_coin_accept=1' :'';
    filter = filter!==''?'&columnSearch[company_name]='+filter :'';
    return ssoInstance.get(Config.API_URL +'vendor?categories=53,8438&radius=1-10&start=0&length='+limit+'&latitude='+lat+'&longitude='+lng+'&radius='+radius+ggCoin+filter);

}

export const getPincodeList = (pin) => {
    return ssoInstance.get(Config.ACCOUNT_API_URL+'api/v1.0.2/pincodes/'+pin);

}

export const postAddressDetails = (obj) => {
    return ssoInstance.post(Config.ACCOUNT_API_URL+'api/v1.0.2/user/'+ Auth.user().user_id +'/address', obj);
}

export const getFoodVoucherList = (count, brand_name, type, tat, coin_applicable) => {
    let obj = {
      "category" : ["Food and Beverages"],
    }
    if(brand_name && brand_name!=='') obj.brand_name = brand_name;
    if (type && type.length !== 0) obj.type = type;
    if (tat && tat.length !== 0) obj.tat = tat;
    if (coin_applicable && coin_applicable !== 0) obj.coin_applicable = coin_applicable;

    return ssoInstance.post(Config.MEGABRAND_API_URL +'all-brands?page='+count,obj);

}

export const getFavouritVoucher = (id) => {

    return ssoInstance.put(Config.MEGABRAND_API_URL +'favBrand/'+id);

}
