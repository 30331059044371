import React, {Component, Fragment} from 'react';
import { Card } from "@blueprintjs/core";

export default class LoadingComponent extends Component{

    render(){

        return(
          <Fragment>
            {this.props.type==='voucher'?
              <div className='row mt-4'>
                <div className="col-md-4 col-xl-4 col-lg-4 col-sm-4 col-4 mb-4">
                  <div className="bp3-skeleton" style={{width: "100%", height: "150px"}}>&nbsp;</div>
                </div>
                <div className="col-md-4 col-xl-4 col-lg-4 col-sm-4 col-4 mb-4">
                  <div className="bp3-skeleton" style={{width: "100%", height: "150px"}}>&nbsp;</div>
                </div>
                <div className="col-md-4 col-xl-4 col-lg-4 col-sm-4 col-4 mb-4">
                  <div className="bp3-skeleton" style={{width: "100%", height: "150px"}}>&nbsp;</div>
                </div>
                <div className="col-md-4 col-xl-4 col-lg-4 col-sm-4 col-4 mb-4">
                  <div className="bp3-skeleton" style={{width: "100%", height: "150px"}}>&nbsp;</div>
                </div>
                <div className="col-md-4 col-xl-4 col-lg-4 col-sm-4 col-4 mb-4">
                  <div className="bp3-skeleton" style={{width: "100%", height: "150px"}}>&nbsp;</div>
                </div>
                <div className="col-md-4 col-xl-4 col-lg-4 col-sm-4 col-4 mb-4">
                  <div className="bp3-skeleton" style={{width: "100%", height: "150px"}}>&nbsp;</div>
                </div>
              </div>
              :
              <Fragment>
                <div className="col-md-12 col-xl-12 col-lg-12 col-sm-12 col-12 mb-4">
                  <Card className='bp3-elevation-1'>
                    <div className="row">
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 mt-s-2">
                        <center>
                          <div className="bp3-skeleton" style={{height: "210px"}} />
                        </center>
                      </div>
                      <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12 pl-xl-4 pl-lg-4 pl-md-3 pl-sm-2 pl-3 mt-s-3 py-3">
                        <div className="bp3-skeleton" style={{height: "20px",width:'70%'}} />
                        <br/>
                        <div className="bp3-skeleton" style={{height: "20px",width:'80%'}} />
                        <br/>
                        <div className="bp3-skeleton" style={{height: "20px",width:'45%'}} />
                        <br/>
                        <div className="bp3-skeleton" style={{height: "20px",width:'30%'}} />
                        <br/>
                        <div className="bp3-skeleton" style={{height: "20px",width:'40%'}} />
                      </div>
                    </div>

                  </Card>
                </div>
                <div className="col-md-12 col-xl-12 col-lg-12 col-sm-12 col-12 mb-4">
                  <Card className='bp3-elevation-1'>
                    <div className="row">
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 mt-s-2">
                        <center>
                          <div className="bp3-skeleton" style={{height: "210px"}} />
                        </center>
                      </div>
                      <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12 pl-xl-4 pl-lg-4 pl-md-3 pl-sm-2 pl-3 mt-s-3 py-3">
                        <div className="bp3-skeleton" style={{height: "20px",width:'70%'}} />
                        <br/>
                        <div className="bp3-skeleton" style={{height: "20px",width:'80%'}} />
                        <br/>
                        <div className="bp3-skeleton" style={{height: "20px",width:'45%'}} />
                        <br/>
                        <div className="bp3-skeleton" style={{height: "20px",width:'30%'}} />
                        <br/>
                        <div className="bp3-skeleton" style={{height: "20px",width:'40%'}} />
                      </div>
                    </div>

                  </Card>
                </div>
                <div className="col-md-12 col-xl-12 col-lg-12 col-sm-12 col-12 mb-4">
                  <Card className='bp3-elevation-1'>
                    <div className="row">
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 mt-s-2">
                        <center>
                          <div className="bp3-skeleton" style={{height: "210px"}} />
                        </center>
                      </div>
                      <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12 pl-xl-4 pl-lg-4 pl-md-3 pl-sm-2 pl-3 mt-s-3 py-3">
                        <div className="bp3-skeleton" style={{height: "20px",width:'70%'}} />
                        <br/>
                        <div className="bp3-skeleton" style={{height: "20px",width:'80%'}} />
                        <br/>
                        <div className="bp3-skeleton" style={{height: "20px",width:'45%'}} />
                        <br/>
                        <div className="bp3-skeleton" style={{height: "20px",width:'30%'}} />
                        <br/>
                        <div className="bp3-skeleton" style={{height: "20px",width:'40%'}} />
                      </div>
                    </div>

                  </Card>
                </div>
                <div className="col-md-12 col-xl-12 col-lg-12 col-sm-12 col-12 mb-4">
                  <Card className='bp3-elevation-1'>
                    <div className="row">
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 mt-s-2">
                        <center>
                          <div className="bp3-skeleton" style={{height: "210px"}} />
                        </center>
                      </div>
                      <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12 pl-xl-4 pl-lg-4 pl-md-3 pl-sm-2 pl-3 mt-s-3 py-3">
                        <div className="bp3-skeleton" style={{height: "20px",width:'70%'}} />
                        <br/>
                        <div className="bp3-skeleton" style={{height: "20px",width:'80%'}} />
                        <br/>
                        <div className="bp3-skeleton" style={{height: "20px",width:'45%'}} />
                        <br/>
                        <div className="bp3-skeleton" style={{height: "20px",width:'30%'}} />
                        <br/>
                        <div className="bp3-skeleton" style={{height: "20px",width:'40%'}} />
                      </div>
                    </div>

                  </Card>
                </div>
              </Fragment>
            }
          </Fragment>

        );

    }

}
