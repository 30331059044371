import React,{ Component } from 'react';
import CheckoutContainer from './container/CheckoutContainer';
import {Error} from 'gg-react-utilities';

export default class Product extends Component{

    nextPage(route,response){
      this.props.history.push('/payment/'+route,{
        query:response
      });
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1000)
    }

    render(){
      try {
        return(
          <CheckoutContainer
            nextPage={(response)=>{
              this.nextPage(response);
            }}
            back={this.props.history}
          />
        )
      } catch (e) {
        return(
          <Error />
        )
      }
    }
}
